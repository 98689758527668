<template>
  <div>
    <div class="m_banner">
      <div class="m_banner_text">新闻中心</div>
      <div class="m_banner_i"></div>
      <div class="m_banner_text2">医管资讯一手掌握</div>
    </div>
    <div class="tabs_m">
      <div :class="['tabs_item', activeName == 1 ? 'is_tabs' : '']" @click="setActiveFun(1)">集团动态</div>
      <div :class="['tabs_item', activeName == 2 ? 'is_tabs' : '']" @click="setActiveFun(2)">咨询动态</div>
      <div :class="['tabs_item', activeName == 4 ? 'is_tabs' : '']" @click="setActiveFun(4)">行业资讯</div>
    </div>
    <div class="journalism_m_box" v-if="!isDetail">
      <div class="journalism_m_item" v-for="(item, index) in mobileData" :key="index">
        <img class="journalism_m_img" :src="item.header" />
        <div class="journalism_m_time">{{ item.sendTime }}</div>
        <div class="journalism_m_title">{{ item.title }}</div>
        <div class="journalism_m_subTitle">{{ item.subTitle }}</div>
        <div class="journalism_m_but" @click="getDetailFun(item.newsId)">了解更多</div>
      </div>
      <div class="index_more" @click="getmoreNew">查看更多新闻</div>
    </div>
    <div class="journalism_m_detail" v-else>
      <div class="journalism_m_detail_title">{{ detailInfo?.title }}</div>
      <div class="journalism_m_detail_time">{{ detailInfo?.sendTime }}</div>
      <div class="journalism_m_detail_info" v-html="detailInfo?.intro"></div>
    </div>
  </div>
</template>

<script>
import { getNewsDetail } from "@/api/index";
  export default {
    props: ['mobileData'],
    data() {
      return {
        activeName: 1,
        isDetail: false,
        detailInfo: {}
      }
    },
    created() {
      let query = this.$route.query;
      if (query.activeName) {
        this.activeName = query.activeName;
      }
    },
    methods: {
      setActiveFun(num) {
        this.activeName = num
        this.isDetail = false
        this.$router.push({
          path: "/journalism",
          query: { activeName: num },
        });
        this.$emit('getList', num)
      },
      getmoreNew() {
        this.$emit('getmoreNew', this.activeName)
      },
      getDetailFun(newsId) {
        this.isDetail = true
        getNewsDetail(newsId).then(res => {
          this.detailInfo = res.data
        })
      }
    }
  }
</script>

<style lang="scss">
  .journalism_m_detail_info {
    border-top: 1px solid #E6E6E6;
    margin-top: 32px;
    * {
      margin: 0;
      padding: 0;
    }
    p {
      font-size: 26px;
      font-weight: 400;
      color: #333333;
      line-height: 39px;
      margin: 20px 0;
      span {
        font-size: 26px !important;
        color: #333333 !important;
      }
    }
    img {
      width: 100%;
    }
  }
</style>
<style lang="scss" scoped>
  .m_banner {
    width: 100%;
    height: 515px;
    background-image: url("../assets/img/mobile_bg4.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .m_banner_text {
      font-size: 32px;
      color: #fff;
      text-align: center;
      padding-top: 132px;
    }
    .m_banner_i {
      width: 45px;
      height: 6px;
      background-color: #fff;
      margin: 20px auto 16px;
    }
    .m_banner_text2 {
      font-size: 40px;
      color: #fff;
      text-align: center;
    }
  }
  .tabs_m {
    display: flex;
    background-color: #F5F6F8;
    padding: 0 37px;
    flex-wrap: wrap;
    .tabs_item {
      width: 33.33%;
      height: 95px;
      line-height: 95px;
      font-size: 28px;
      font-weight: 400;
      text-align: center;
      color: #333333;
      border-bottom: 4px solid transparent;
    }
    .is_tabs {
      background: #fff;
      border-bottom: 4px solid #1E61FF;
    }
  }
  .index_more {
    width: 175px;
    height: 50px;
    line-height: 50px;
    border-radius: 4px;
    border: 2px solid #999999;
    font-size: 22px;
    color: #666;
    text-align: center;
    margin: 20px auto 0;
  }
  .journalism_m_box {
    padding: 44px;
    .journalism_m_item {
      border-bottom: 1px solid #E6E6E6;
      margin-bottom: 60px;
      padding-bottom: 40px;
      .journalism_m_img {
        width: 100%;
      }
      .journalism_m_time {
        font-size: 24px;
        font-weight: 400;
        color: #9E9E9E;
        padding: 12px 0 14px 0;
        display: flex;
        align-items: center;
        &::before {
          content: '';
          display: inline-block;
          width: 24px;
          height: 24px;
          background-image: url("../assets/img/naioz_icon.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-right: 20px;
        }
      }
      .journalism_m_title {
        font-size: 28px;
        font-weight: 600;
        color: #333333;
      }
      .journalism_m_subTitle {
        font-size: 24px;
        font-weight: 400;
        color: #666666;
        overflow: hidden; //超出的文本隐藏
        display: -webkit-box;
        -webkit-line-clamp: 2; // 设置从第几行后开始省略
        -webkit-box-orient: vertical;
        margin: 23px 0 33px 0;
      }
      .journalism_m_but {
        width: 221px;
        height: 78px;
        line-height: 78px;
        text-align: center;
        background: linear-gradient(268deg, #01AEEE 0%, #1782F0 100%);
        border-radius: 8px;
        font-size: 28px;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
  }
  .journalism_m_detail {
    padding: 42px;
    .journalism_m_detail_title {
      font-size: 42px;
      font-weight: bold;
      color: #333333;
      line-height: 53px;
      padding: 0 60px 14px 60px;
      text-align: center;
    }
    .journalism_m_detail_time {
      text-align: center;
      font-size: 24px;
      font-weight: 400;
      color: #9E9E9E;
    }
  }
</style>