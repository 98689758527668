<template>
  <div>
    <div class="userModTitle">软件介绍</div>
    <div class="userModCon">
      <div class="userModConList" v-for="(item, index) in textObj" :key="index">
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "userCon1",
  props: ["textObj"],
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss">
.userModTitle {
  width: 100%;
  padding: 20px 0;
  font-size: 18px;
  font-weight: bold;
  color: #666666;
  border-bottom: 2px solid #e5e5e5;
}
.userModCon {
    padding-top: 30px;
  .userModConList {
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    color: #666666;
    margin-bottom: 44px;
    text-indent: 2em;
  }
}
</style>