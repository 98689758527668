<template>
  <div>
    <div class="m_banner">
      <div class="m_banner_text">医院管理</div>
      <div class="m_banner_i"></div>
      <div class="m_banner_text2">成为医院值得信赖的<br />管理合作伙伴</div>
    </div>
    <div class="index_AboutUs">
      <div class="index_title">
        <span class="index_title_before"></span>
        <span class="index_title_span">关于易策医管</span>
        <span class="index_title_after"></span>
      </div>
      <div class="index_subhead">提升医院经营能力，惠泽群众健康生活</div>
      <div class="index_standings">
        <div class="index_standings_item" v-for="(item, index) in standingsList2" :key="index">
          <img class="index_standings_img" :src="item.icon" :alt="item.text">
          <div class="index_standings_data">{{ item.data }}<span v-if="item.type == 2">+</span></div>
          <div class="index_standings_text">{{ item.text }}</div>
        </div>
      </div>
    </div>
    <div class="index_introduce">
      <img class="index_introduce_img" src="../assets/img/m_gs.png" alt="">
      <div class="index_introduce_text">
        广州易策医管集团（简称“易策医管”）成立于2001年，旗下拥有4家医院管理咨询机构，专注于医院管理咨询、医管学术研究、医院内部管理与素质培训、医院托管与投资、医院精益运营信息技术与数据服务。
      </div>
      <div class="index_introduce_but" @click="toCollege">了解更多</div>
    </div>
    <div class="index_scheme">
      <div class="index_title">
        <span class="index_title_before"></span>
        <span class="index_title_span">解决方案</span>
        <span class="index_title_after"></span>
      </div>
      <div class="index_subhead">医院精细化管理5大模块</div>
      <div class="index_swiper_box">
        <swiper :options="swiperOption" ref="mySwiper1">
          <swiper-slide v-for="(item, index) in schemeArr" :key="index">
            <div class="index_swiper_item">
              <img class="index_swiper_img" :src="item.imgUrl" alt="">
              <div class="index_swiper_name">{{ item.deptName }}</div>
              <div class="index_swiper_p">
                <div v-for="(i, k) in item.content" :key="k">{{ i }}</div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="index_team">
      <div class="index_title">
        <span class="index_title_before"></span>
        <span class="index_title_span">专家团队介绍</span>
        <span class="index_title_after"></span>
      </div>
      <div class="index_subhead">汇聚医院管理专家 吸纳各领域精英人才</div>
      <div class="index_swiper_box">
        <swiper :options="swiperOption2" ref="mySwiper">
          <swiper-slide v-for="(item, index) in team" :key="index">
            <div class="index_swiper_item">
              <img class="index_swiper_img" :src="item.img" alt="">
              <div class="index_swiper_name">{{ item.name }}</div>
            </div>
          </swiper-slide>
        </swiper>
        <!-- <div class="swiper-pagination2"></div> -->
      </div>
      <div class="index_more" @click="toExpertItem">查看更多</div>
    </div>
    <div class="index_consult">
      <div class="index_title">
        <span class="index_title_before"></span>
        <span class="index_title_span">咨询方案</span>
        <span class="index_title_after"></span>
      </div>
      <div class="index_subhead">汇聚医院管理专家 吸纳各领域精英人才</div>
      <div class="index_consult_box">
        <div class="index_consult_item" v-for="(item, index) in list" :key="index" @click="toAggregateItem(item.more)">
          <div class="index_consult_img">
            <img :src="item.img" alt="" />
          </div>
          <div class="index_consult_name">{{ item.name }}</div>
          <div class="index_consult_introduce">{{ item.introduce }}</div>
        </div>
      </div>
      <div class="index_more" @click="toAggregate">查看更多</div>
    </div>
    <div class="index_journalism">
      <div class="index_title">
        <span class="index_title_before"></span>
        <span class="index_title_span">新闻中心</span>
        <span class="index_title_after"></span>
      </div>
      <div class="index_subhead">医管资讯一手掌握</div>
      <div class="index_journalism_box">
        <div class="index_journalism_item" v-for="(item, index) in informationData" :key="index" @click="topNewDetail(item)">
          <img class="index_journalism_img" v-if="index == 0" :src="item.header" alt="">
          <div class="index_journalism_title">{{ item.title }}</div>
          <div class="index_journalism_time">{{ item.sendTime }}</div>
          <div class="index_journalism_subtitle">{{ item.subTitle }}</div>
        </div>
      </div>
      <div class="index_more" @click="moreNew">更多咨讯</div>
    </div>
    <div class="index_video">
      <div class="index_title">
        <span class="index_title_before"></span>
        <span class="index_title_span">年度视频</span>
        <span class="index_title_after"></span>
      </div>
      <div class="index_subhead">易策医管集团2024年度视频</div>
      <div class="index_video_box">
        <video-player class="textVideo1" :options="playerOptions"></video-player>
      </div>
    </div>
  </div>
</template>

<script>
  import img1 from '@/assets/img/modernImg1.png'
  import img2 from '@/assets/img/modernImg2.png'
  import img3 from '@/assets/img/modernImg3.png'
  import img4 from '@/assets/img/modernImg4.png'
  export default {
    
    props: ['standingsList', 'team', 'informationData', 'playerOptions'],
    data() {
      let _this = this
      return {
        standingsList2: [
          { data: 300, text: "覆盖城市", type: 2, icon: img3 },
          { data: 800, text: "服务公立医院", type: 2, icon: img2 },
          { data: 23, text: "年医院管理经验", type: 1, icon: img1 },
          { data: 100, text: "医院长期管理顾问", type: 2, icon: img4 },
        ],
        schemeArr: [
          {
            imgUrl: require("../assets/img/scheme_m_icon4.png"),
            deptName: '内涵提升',
            content: ['患者就医体验提升','党建+品牌+文化','医院流程体系建设','医院等级评审辅导','医院质量管理体系建设']
          },
          {
            imgUrl: require("../assets/img/scheme_m_icon3.png"),
            deptName: '战略管理',
            content: ['医院中长期发展规划', '医院开业实施计划', '医院建设设备设施规划', '医院新建（扩建）可行性研究', '区域卫生发展规划']
          },
          {
            imgUrl: require("../assets/img/scheme_m_icon2.png"),
            deptName: '绩效国考',
            content: ['现代医院绩效体系设计','绩效“国考”能力提升辅导','组织结构治理与定岗定编规划','医院集团化管理设计与实施','医院成本管控体系建设']
          },
          {
            imgUrl: require("../assets/img/scheme_m_icon1.png"),
            deptName: '学科运营',
            content: ['DRG/DIP精细化管理','专科运营管理体系建设','医院门诊服务能力建设','医院运营管理提升辅导','医院及专科品牌建设及业务推广']
          },
          {
            imgUrl: require("../assets/img/scheme_m_icon.png"),
            deptName: '智慧管理',
            content: ['医院绩效管理系统','DRG/DIP医院管理系统','医保质控系统','国考指标管理系统','党建清廉管理系统']
          },
        ],
        swiperOption: {
          //可见图片张数
          slidesPerView: 1.2,
          // 默认选中中间一张
          centeredSlides: true,
          // spaceBetween: 30,
          //无限循环
          loop: true,
          //小圆点（我此处没使用，列出供参考）
          pagination:{
            el: '.swiper-pagination',
            clickable: true,
            clickableClass : 'my-pagination-clickable',
          },
          //自动轮播
          autoplay: true,
        },
        swiperOption2: {
          //可见图片张数
          slidesPerView: 2.7,
          // 默认选中中间一张
          centeredSlides: true,
          spaceBetween: 5,
          //无限循环
          loop: true,
          //小圆点（我此处没使用，列出供参考）
          pagination: {
            el: '.swiper-pagination2',
            clickable: true,
            clickableClass : 'my-pagination-clickable',
          },
          //自动轮播
          autoplay: true,
          on: {
            click: function (e) {
              let obj = {};
              // console.log(e.target.src)
              if (e.target.src) {
                obj = _this.team.find((item) => {
                  return item.img == e.target.src;
                });
              }
              _this.toTeamDetails(obj); // 实现传参
            },
          },
        },
        list: [
          {
            img: require("../assets/img/aggregateImg_m3.png"),
            name: "现代医院绩效管理体系建设",
            introduce:
              "以预算为基础，工作量（DRGs&DIP）为依据，以绩效管理为抓手-促进医院“提质增效”",
            more: "/modern",
          },
          {
            img: require("../assets/img/aggregateImg_m2.png"),
            name: "组织结构治理与定岗定编规划",
            introduce: "科学构建人力资源战略规划，搭建医疗人才梯队",
            more: "/government",
          },
          {
            img: require("../assets/img/aggregateImg_m1.png"),
            name: "易策EHP医院绩效软件系统",
            introduce: "以预算为基础，工作量（DRGs&DIP）为依据，以绩效管理为抓手-促进医院“提质增效”",
            more: "/ehp",
          }
        ]
      }
    },
    methods: {
      toCollege() {
        this.$emit('toCollege')
      },
      toExpertItem() {
        this.$emit('toExpertItem')
      },
      toAggregate() {
        this.$router.push({
          path: "/aggregate"
        });
      },
      toAggregateItem(route) {
        this.$router.push({
          path: route
        });
      },
      moreNew() {
        this.$emit('moreNew')
      },
      topNewDetail(item) {
        this.$emit('topNewDetail', item)
      },
      toTeamDetails(item) {
        this.$router.push({
          path: "/team",
          query: { userId: item.userId, activeName: item.activeName },
        });
      }
    }
  }
</script>

<!-- <style lang="scss">
  .index_swiper_p {
    width: 532px;
    height: 280px;
    margin: 33px auto;
    p {
      display: inline !important;
      font-size: 28px;
      font-weight: 400;
      color: #333333;
      line-height: 45px;
      &::after {
        content: '、';
      }
    }
  }
</style> -->
<style lang="scss" scoped>
  .m_banner {
    width: 100%;
    height: 515px;
    background-image: url("../assets/img/mobile_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    &::before {
      content: '';
      width: 100%;
      height: 515px;
      background-image: url("../assets/img/index_m_top.png");
      background-size: 393px;
      background-repeat: no-repeat;
      background-position: center;
      display: block;
      position: absolute;
      z-index: 2;
    }
    .m_banner_text {
      width: 100%;
      font-size: 32px;
      color: #fff;
      text-align: center;
      padding-top: 132px;
      position: absolute;
      z-index: 3;
    }
    .m_banner_i {
      width: 45px;
      height: 6px;
      background-color: #fff;
      top: 210px;
      left: calc(50% - 22px);
      position: absolute;
      z-index: 3;
    }
    .m_banner_text2 {
      font-size: 40px;
      color: #fff;
      text-align: center;
      position: absolute;
      width: 100%;
      top: 250px;
      z-index: 3;
    }
  }
  .index_title {
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    color: #000;
    margin: 0 auto;
    .index_title_span {
      margin: 0 20px;
    }
    .index_title_after {
      display: inline-block;
      width: 107px;
      height: 23px;
      background-image: url("../assets/img/titleIcon1.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transform: rotate(180deg);
    }
    .index_title_before {
      display: inline-block;
      width: 107px;
      height: 23px;
      background-image: url("../assets/img/titleIcon1.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  .index_subhead {
    text-align: center;
    font-size: 26px;
    font-weight: 400;
    color: #333;
    margin: 23px auto;
  }
  .index_AboutUs {
    margin-top: 33px;
    padding: 0 37px;
    .index_standings {
      display: flex;
      flex-wrap: wrap;
      .index_standings_item {
        width: 50%;
        text-align: center;
        margin-bottom: 49px;
        margin-top: 40px;
        .index_standings_img {
          height: 80px;
        }
        .index_standings_data {
          font-size: 40px;
          font-weight: 400;
          color: #4150C9;
          margin: 21px auto 12px;
        }
        .index_standings_text {
          font-size: 28px;
          font-weight: bold;
          color: #333333;
        }
      }
    }
  }
  .index_introduce {
    padding: 0 37px;
    .index_introduce_img {
      width: 100%;
    }
    .index_introduce_text {
      font-size: 26px;
      font-weight: 400;
      color: #333333;
      line-height: 45px;
      margin-top: 18px;
    }
    .index_introduce_but {
      width: 221px;
      height: 78px;
      line-height: 78px;
      text-align: center;
      background: linear-gradient(268deg, #01AEEE 0%, #1782F0 100%);
      color: #fff;
      font-size: 28px;
      font-weight: 400;
      margin-top: 34px;
      border-radius: 8px;
    }
  }
  .index_scheme {
    margin-top: 58px;
    padding-bottom: 20px;
    .index_swiper_box {
      .index_swiper_item {
        width: 618px;
        // border-radius: 10px;
        // background: #FFFFFF;
        // box-shadow: 0px 0px 26px 1px rgba(0,14,52,0.1);
        // overflow: hidden;
        .index_swiper_img {
          width: 142px;
          height: 142px;
          border-radius: 100%;
          display: block !important;
          margin: 30px auto 24px;
        }
        .index_swiper_name {
          font-size: 30px;
          font-weight: bold;
          color: #333333;
          text-align: center;
          margin: 17px auto 23px;
        }
        .index_swiper_p {
          background: #FFFFFF;
          box-shadow: 0px 0px 26px 1px rgba(0,14,52,0.1);
          border-radius: 10px;
          padding: 36px;
          z-index: 2;
          div {
            border-bottom: 2px dashed #C4C4C4;
            text-align: center;
            padding: 20px;
            font-size: 28px;
            font-weight: 400;
            color: #333333;
            display: flex;
            align-items: center;
            justify-content: center;
            &::before {
              content: '';
              width: 5px;
              height: 5px;
              margin-right: 6px;
              border-radius: 100%;
              display: inline-block;
              background-color: #333;
            }
            &:nth-last-child(1) {
              border: none;
            }
          }
        }
      }
      .swiper-container {
        padding-bottom: 30px;
      }
      .swiper-slide {
        transform: scale(0.9);
      }
      .swiper-slide-active, .swiper-slide-duplicate-active {
        transform: scale(1) !important;
      }
    }
    .swiper-pagination {
      width: 100%;
      ::v-deep(.swiper-pagination-bullet) {
        width: 22px !important;
        height: 22px !important;
        margin: 0 14px;
      }
      ::v-deep(.swiper-pagination-bullet-active) {
        background-color: #2D43F2 !important;
      }
    }
  }
  .index_more {
    width: 175px;
    height: 50px;
    line-height: 50px;
    border-radius: 4px;
    border: 2px solid #999999;
    font-size: 22px;
    color: #666;
    text-align: center;
    margin: 20px auto 0;
  }

  .index_team {
    margin-top: 50px;
    padding-bottom: 20px;
    .index_swiper_box {
      padding: 0 37px;
      height: 500px;
      .index_swiper_item {
        width: 210px;
        border-radius: 10px;
        overflow: hidden;
        .index_swiper_img {
          width: 100%;
          height: 310px;
          display: block;
        }
        .index_swiper_name {
          text-align: center;
          // height: 49px;
          line-height: 49px;
          background: linear-gradient(268deg, #0C54BF 0%, #5897DF 100%);
          color: #fff;
          font-size: 24px;
        }
      }
      .swiper-container {
        padding: 50px 0;
        .swiper-slide {
          text-align: -webkit-center;
        }
      }
      .swiper-slide-active, .swiper-slide-duplicate-active {
        transform: scale(1.2);
      }
    }
  }
  .index_consult {
    margin-top: 58px;
    .index_consult_box {
      padding: 0 37px;
      .index_consult_item {
        background: #FFFFFF;
        box-shadow: 0px 2px 10px 1px rgba(0,14,52,0.14);
        border-radius: 8px;
        width: 100%;
        padding-bottom: 33px;
        margin-bottom: 34px;
        .index_consult_img {
          width: 100%;
          img {
            width: 100%;
          }
        }
        .index_consult_name {
          font-size: 28px;
          font-weight: bold;
          color: #333333;
          text-align: center;
          margin: 26px 0 19px 0;
        }
        .index_consult_introduce {
          font-size: 26px;
          font-weight: 400;
          color: #333333;
          padding: 0 50px;
          text-align: center;
        }
      }
    }
    
  }
  .index_journalism {
    margin-top: 58px;
    padding-bottom: 50px;
    .index_journalism_box {
      margin: 0 37px;
      background: #FFFFFF;
      box-shadow: 0px 2px 10px 1px rgba(0,14,52,0.14);
      border-radius: 8pxs;
      padding-bottom: 40px;
      margin-bottom: 30px;
      .index_journalism_item {
        .index_journalism_img {
          width: 100%;
          display: block;
          margin-bottom: 22px;
        }
        .index_journalism_title {
          font-size: 28px;
          font-weight: 600;
          color: #333333;
          padding: 0px 28px;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap;
          letter-spacing: 2px;
          margin-bottom: 11px;
        }
        .index_journalism_time {
          padding: 0px 28px;
          font-size: 24px;
          font-weight: 400;
          color: #9E9E9E;
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          &::before {
            content: '';
            display: inline-block;
            width: 24px;
            height: 24px;
            background-image: url("../assets/img/naioz_icon.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin-right: 20px;
          }
        }
        .index_journalism_subtitle {
          padding: 0 28px;
          font-size: 24px;
          font-weight: 400;
          line-height: 38px;
          letter-spacing: 3px;
          color: #666666;
          overflow: hidden; //超出的文本隐藏
          display: -webkit-box;
          -webkit-line-clamp: 2; // 设置从第几行后开始省略
          -webkit-box-orient: vertical;
          margin-bottom: 28px;
          }
      }
    }
  }
  .index_video {
    padding-bottom: 100px;
    .index_video_box {
      padding: 0 37px;
      width: 100%;
      height: 381px;
      .textVideo1 {
        width: 100%;
        height: 100%;
        ::v-deep(.vjs-big-play-button) {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
</style>