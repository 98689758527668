<template>
  <div class="flex flexT do-userPrincipal">
    <div class="flex flexSB flexT doDiv">
      <div class="do-preview">
        <div class="flex flexS do-mes">
          <div class="do-details">
            <div class="do-name">{{ documentObj.name }}</div>
            <div class="flex flexS do-info">
              <div>{{ documentObj.time }}上传</div>
              <div>{{ documentObj.pageNum }}页</div>
              <div>{{ documentObj.size }}</div>
              <div>{{ documentObj.type }}</div>
            </div>
            <div class="flex flexS do-fun">
              <el-button type="danger" icon="el-icon-download"
                >文档下载</el-button
              >
              <el-button type="primary">
                <img
                  v-if="collect"
                  style="width: 14px; height: 14px"
                  src="../assets/img/collect2.png"
                />
                <img
                  v-else
                  style="width: 14px; height: 14px"
                  src="../assets/img/collect1.png"
                />
                收藏</el-button
              >
            </div>
          </div>
          <img class="cu thumbnail" src="../assets/img/do-thumbnail.png" />
        </div>
        <div class="documentText"></div>
      </div>
      <div class="do-more">
        <div
          class="flex flexS"
          style="
            font-size: 16px;
            font-weight: bold;
            padding-bottom: 10px;
            margin-bottom: 3px;
            border-bottom: 2px solid #efefef;
          "
        >
          为您推荐
        </div>
        <div class="hidScrollbar do-menu">
          <div
            class="cu hidLin1 do-list"
            v-for="(item, index) in list"
            :key="index"
            @click="playList(item)"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo: {
    title:"广州易策医管集团成立于2001年，旗下拥有4家医院管理咨询机构，专注于医院管理咨询、医管学术研究、医院内部管理与素质培训、医院托管与投资、医院精益运营信息技术与大数据服务。",
    meta: [
      {
        name: "keywords",
        content:"易策,医管,易策咨询,致和咨询,医院管理,易策健康,绩效管理，高质量发展",
      },
      {
        name: "description",
        content:"广州易策医管集团成立于2001年，旗下拥有4家医院管理咨询机构，专注于医院管理咨询、医管学术研究、医院内部管理与素质培训、医院托管与投资、医院精益运营信息技术与大数据服务。已服务全国600多家医院，凭借专业的理论知识、管理经验、信息技术及学科构建等，实现与医院之间的管理知识技术转移，推动中国医院的职业化管理进程，提升医院的职业化管理水平。",
      },
    ],
  },
  data() {
    return {
      documentObj: {
        name: "今日广州番禺市桥中医院关于管理系统大看点.DOCX",
        time: "2021-12-6",
        pageNum: 3,
        size: "18K",
        type: "DOCX",
      },
      list: [
        "今日广州番禺市桥中医院关于管理系统文件.doc",
        "今日广州番禺市桥中医院关于管理系统文件.doc",
        "今日广州番禺市桥中医院关于管理系统文件.doc",
        "今日广州番禺市桥中医院关于管理系统文件.doc",
        "今日广州番禺市桥中医院关于管理系统文件.doc",
        "今日广州番禺市桥中医院关于管理系统文件.doc",
        "今日广州番禺市桥中医院关于管理系统文件.doc",
        "今日广州番禺市桥中医院关于管理系统文件.doc",
        "今日广州番禺市桥中医院关于管理系统文件.doc",
        "今日广州番禺市桥中医院关于管理系统文件.doc",
        "今日广州番禺市桥中医院关于管理系统文件.doc",
        "今日广州番禺市桥中医院关于管理系统文件.doc",
      ],
      collect: true,
    };
  },
  created() {},
  methods: {
    playList(item) {},
  },
};
</script>
<style lang="scss">
.do-userPrincipal {
  width: 100%;
  background-color: #f9fcff;
  padding: 28px 0;
  .doDiv {
    width: 1200px;
    min-height: 100px;
    .do-preview {
      width: 870px;
      min-height: 200px;
      .do-mes {
        width: 870px;
        height: 204px;
        padding: 20px 30px;
        background: #ffffff;
        box-shadow: 0px 3px 6px #f3f3f3;
        margin-bottom: 20px;
        .do-details {
          width: 614px;
          height: 100%;
          .do-name {
            font-size: 26px;
            font-weight: 400;
            color: #333333;
          }
          .do-info {
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            padding: 5px 0 30px 0;
            border-bottom: 2px solid #efefef;
            margin-bottom: 20px;
          }
          .do-fun {
            button {
              width: 124px;
              height: 40px;
            }
          }
        }
        .thumbnail {
          margin-left: 64px;
        }
      }
      .documentText {
        width: 870px;
        height: 1230px;
        background-color: #fff;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
      }
    }
    .do-more {
      width: 310px;
      min-height: 200px;
      padding: 10px 30px;
      background: #ffffff;
      color: #666;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
      .do-menu {
        width: 100%;
        height: 450px;
        overflow-y: auto;
        .do-list {
          width: 100%;
          padding: 9px 0;
          font-size: 14px;
          font-weight: 400;
        }
        .do-list:hover {
          color: #4a86f7;
        }
      }
    }
  }
}
</style>