import request from '@/util/request'

// 获取首页各个公司简介
export function getModularList(data) {
  // return request({
  //   url: '/api/DeptModular/getModularList/' + userId,
  //   method: 'get'
  // })
  return request({
    url: '/api/DeptModular/getModularList',
    method: 'post',
    data: data
  })
}

// 专家团队-查询当前teamId下的专家列表
export function getTeamList(query) {
  return request({
    url: '/api/userDetail/getUserDetail',
    method: 'get',
    params: query
  })
}

// 服务网络-查询服务的医院的列表
export function getServeList(query) {
  return request({
    url: '/api/service/getServiceList',
    method: 'get',
    params: query
  })
}

// 关于我们 - 企业概括文案
export function getGeneralize(query) {
  return request({
    url: '/api/deptDetail/getDeptDetail',
    method: 'get',
    params: query
  })
}

// 关于我们 - 企业概括轮播图   type=1
// 关于我们 - 集团荣誉         type=2
// 加入我们 - 加入我们         type=3 (内容暂时前端写死)
export function getImageList(query) {
  return request({
    url: '/api/image/getImageList',
    method: 'get',
    params: query
  })
}

// 公司发展历程
export function getCycleList(query) {
  return request({
    url: '/api/cycle/getCycleList',
    method: 'get',
    params: query
  })
}

// 联系我们  (内容暂时前端写死)
export function getContactList(query) {
  return request({
    url: '/api/contact/getContactList',
    method: 'get',
    params: query
  })
}

// 新闻中心 - 集团动态     type=1   
export function getNewsList(query) {
  return request({
    url: '/api/news/getNewsList',
    method: 'get',
    params: query
  })
}

// 新闻详情   
export function getNewsDetail(query) {
  return request({
    url: '/api/news/' + query,
    method: 'get',
  })
}

// 新闻中心 - 集团动态
export function getNewsByGroup(query) {
  return request({
    url: '/api/news/getNewsByGroup',
    method: 'get',
    params: query
  })
}
// 新闻中心 - 咨询动态
export function getNewsByConsult(query) {
  return request({
    url: '/api/news/getNewsByConsult',
    method: 'get',
    params: query
  })
}
// 新闻中心 - 行业资讯
export function getNewsByIndustry(query) {
  return request({
    url: '/api/news/getNewsByIndustry',
    method: 'get',
    params: query
  })
}

// 易策故事汇 - 咨询人生
export function getNewsByStory(query) {
  return request({
    url: '/api/news/getNewsByStory',
    method: 'get',
    params: query
  })
}

// 服务网络 - 企业版图 - 获取省份
export function getProvinceList(query) {
  return request({
    url: '/api/area/getProvinceList',
    method: 'get',
    params: query
  })
}

// 服务网络 - 企业版图 - 根据省份id获取当前省份医院列表(数组对象)
export function getServiceList(query) {
  return request({
    url: '/api/service/getServiceList',
    method: 'get',
    params: query
  })
}

// 服务网络 - 企业版图 - 根据省份id获取当前省份医院列表(医院名称拼接)
export function getAreaHospital(query) {
  return request({
    url: '/api/areaHospital/getAreaHospital',
    method: 'get',
    params: query
  })
}

// 加入我们 - 媒体联络 - 联系我们
export function media(data) {
  return request({
    url: '/api/media',
    method: 'post',
    data: data
  })
}

// 加入我们 -招聘信息
export function getJobList(query) {
  return request({
    url: '/api/job/getJobList',
    method: 'get',
    params: query
  })
}

// 加入我们 -招聘信息(新)
export function getJobList2(query) {
  return request({
    url: '/api/job/getJobList',
    method: 'get',
    params: query
  })
}

// 加入我们 -招聘信息详情
export function getJobListDetails(query) {
  return request({
    url: '/api/job/list',
    method: 'get',
    params: query
  })
}

// 账号登录
export function login(data) {
  return request({
    url: '/api/user/login',
    method: 'post',
    data: data
  })
}

// 验证码登陆
export function loginCode(data) {
  return request({
    url: '/api/user/loginCode',
    method: 'post',
    data: data
  })
}

// 获取短信登陆验证码
export function getLoginCode(query) {
  return request({
    url: '/api/user/getLoginCode',
    method: 'get',
    params: query
  })
}

// 获取公司入口数据
export function deptList(query) {
  return request({
    url: '/api/dept/deptList',
    method: 'get',
    params: query
  })
}

// 2：操作文档、3：政策发布
export function getDocumentList(query) {
  return request({
    url: '/api/document/getDocumentList',
    method: 'get',
    params: query
  })
}

// 获取tabs视频列表
export function getVideoList(query) {
  return request({
    url: '/api/video/getVideoList',
    method: 'get',
    params: query
  })
}

// 添加个人收藏，2：操作文档、3：视频
export function addUserCollection(data) {
  return request({
    url: '/api/userCollection',
    method: 'post',
    data: data
  })
}

// 删除个人收藏，2：操作文档、3：视频
export function deleteUserCollection(data) {
  return request({
    url: '/api/userCollection',
    method: 'delete',
    data: data
  })
}

// 获取个人收藏列表(废弃)
export function getUserCollection(userId) {
  return request({
    url: '/api/userCollection/' + userId,
    method: 'get'
  })
}

// 获取个人收藏数据列表-文档
export function getUserDocCollection(userId) {
  return request({
    url: '/api/userCollection/getUserDocCollection/' + userId,
    method: 'get'
  })
}
// 获取个人收藏数据列表-视频
export function getUserVideoCollection(userId) {
  return request({
    url: '/api/userCollection/getUserVideoCollection/' + userId,
    method: 'get'
  })
}

// tabs页搜索
export function getSelectList(data) {
  return request({
    url: '/api/userCollection/getSelectList',
    method: 'post',
    data: data
  })
}

// 获取服务案例
export function getHospitalList(query) {
  return request({
    url: '/api/hospitalDetails/getHospitalList',
    method: 'get',
    params: query
  })
}

// 获取服务案例详情
export function getHospitalDetails(query) {
  return request({
    url: `/api/hospitalDetails/getHospitalDetails/${query.hospitalId}`,
    method: 'get',
    params: query
  })
}
