<template>
  <div>
    <div class="flex textTitle">{{textObj.title}}</div>
    <div class="flex sendTime" v-if="textObj.sendTime">发布时间：{{textObj.sendTime}}</div>
    <div class="flex author" v-if="textObj.author">作者：{{textObj.author}}</div>
    <div class="textCon" v-html="textObj.intro"></div>
  </div>
</template>

<script>
export default {
  name: "textDetails",
  props: ["textObj"],
  data() {
    return {
    };
  },
  methods: {},
};
</script>
<style lang="scss">
.textTitle {
  font-size: 40px;
  color: #007cc3;
  font-weight: bold;
  margin: 30px 0;
}
.textTitle{
  font-size: 32px;
}
.sendTime{
  font-size: 16px;
  color: #999;
  margin-bottom: 30px;
}
.author{
  margin: 10px;
  font-weight: bold;
}
.textCon{
  text-indent: 2em;
  color: #666;
  line-height: 2;
  margin-bottom: 40px;
}
p>img{
  max-width: 100%;
  display:block;
}
</style>