<template>
  <div>
    <el-dialog
      title="联系我们"
      custom-class="modernDia"
      :visible.sync="open"
      width="520px"
      :before-close="handleClose"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <div class="mesTitle">您好，请提交您的需求，我们将尽快与您联系</div>
        <el-form-item label="您的名字" prop="userName">
          <el-input
            v-model="ruleForm.userName"
            size="small"
            placeholder="如何称呼您"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="contactInfo">
          <el-input
            v-model="ruleForm.contactInfo"
            size="small"
            placeholder="我们会尽快联系您"
          ></el-input>
        </el-form-item>
        <el-form-item label="您的单位" prop="organ">
          <el-input
            v-model="ruleForm.organ"
            size="small"
            placeholder="请填写您的单位名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="您的职务" prop="postName">
          <el-input
            v-model="ruleForm.postName"
            size="small"
            placeholder="请填写您的科室名称和职务"
          ></el-input>
        </el-form-item>
        <el-form-item label="需求描述" prop="needsIntro">
          <el-input
            type="textarea"
            v-model="ruleForm.needsIntro"
            :autosize="{ minRows: 3 }"
            placeholder="请输入您的需求描述"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="flex dialog-footer">
        <div class="flex relationBtn" @click="cancel">取消</div>
        <div class="flex relationBtn" type="primary" @click="sub">提交</div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { media } from "@/api/index";
export default {
  props: {
    open: Boolean,
    required: false,
  },
  data() {
    return {
      //   open: false,
      ruleForm: {
        userName: "",
        contactInfo: "",
        organ: "",
        postName: "",
        needsIntro: "",
      },
      rules: {
        userName: [
          { required: true, message: "请输入您的姓名", trigger: "blur" },
        ],
        contactInfo: [
          { required: true, message: "请输入您的联系方式", trigger: "blur" },
        ],
        organ: [
          { required: true, message: "请输入您的单位名称", trigger: "blur" },
        ],
        postName: [
          { required: true, message: "请输入您的职务", trigger: "blur" },
        ],
        needsIntro: [
          { required: true, message: "请输入您的需求描述", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    /** 关闭弹窗 */
    handleClose() {
      this.$emit("openFun", false);
    },
    /** 提交联系信息 */
    sub() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          media(this.ruleForm).then((res) => {
            if (res.code == 200) {
              this.$message({
                message:
                  "提交成功!感谢您的支持和配合，我们将会尽快与您取得联系！",
                type: "success",
              });
              this.handleClose();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /** 取消联系信息 */
    cancel(){
      this.handleClose()
      this.ruleForm = {
        userName: "",
        contactInfo: "",
        organ: "",
        postName: "",
        needsIntro: "",
      }
    }
  },
};
</script>

<style lang="scss">
.modernDia {
  .el-dialog__header {
    border-bottom: 1px solid #e9e9e9;
    padding: 20px 20px 10px; /* no */
    .el-dialog__title {
      width: 100%;
      font-size: 16px; /* no */
      font-weight: bold;
      color: #333333;
    }
  }
  .el-dialog__body {
    padding: 20px 16px 0 16px; /* no */
    .mesTitle {
      margin-bottom: 18px; /* no */
      color: #333333;
      padding-left: 3px; /* no */
    }
  }
  .el-dialog__footer {
    padding-top: 4px;
  }
  .demo-ruleForm {
    .el-form-item {
      min-height: 40px; /* no */
      margin-bottom: 18px; /* no */
    }
    .el-form-item__label {
      font-size: 14px; /* no */
      width: 80px; /* no */
    }
    .el-form-item__content {
      margin-left: 80px; /* no */
    }
    .el-input__inner {
      height: 32px; /* no */
    }
    input {
      font-size: 12px;
      padding: 0 12px; /* no */
    }
    textarea {
      margin-top: 4px;
      font-size: 12px;
      padding: 4px 12px; /* no */
      font-weight: 500;
    }
  }
  .relationBtn {
    width: 69px; /* no */
    height: 33px; /* no */
    border: 1px solid #d6d6d6;
    color: #333;
    font-size: 14px; /* no */
    border-radius: 3px 3px 3px 3px;
    margin: 0 10px; /* no */
    cursor: pointer;
  }
  .relationBtn:last-child {
    border: 1px solid #1e61ff;
    color: #fff;
    background-color: #1e61ff;
  }
}
</style>
