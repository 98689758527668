import Vue from "vue"
import VueX from "vuex"
Vue.use(VueX)

const store = new VueX.Store({
    state:{
        changeActiveName: localStorage.getItem('changeActiveName')
    },
    mutations:{
        set_activeName: (state, changeActiveName) => {
            state.changeActiveName = changeActiveName
            localStorage.setItem('changeActiveName', changeActiveName)
        }
    },
    namespaced: true
})
export default store