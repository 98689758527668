<template>
  <div class="listCon">
    <div class="flex showMes">
      <img class="headImg" :src="showteammes.avatar" />
      <div class="t-mes">
        <div class="flexS flexB title1">
          {{ showteammes.userName }}
        </div>
        <div class="flexS">
          <!-- <div class="school">{{ showteammes.deptName }}</div> -->
          <div class="degree">{{ showteammes.postName }}</div>
        </div>
        <div class="boundary1"></div>
        <div class="results" v-html="showteammes.title"></div>
        <div class="t-story" v-html="showteammes.intro"></div>
      </div>
    </div>
    <div class="teamSlideshow">
      <swiper
        :slides-per-view="3"
        :space-between="50"
        :options="swiperOption"
        ref="mySwiper"
      >
        <swiper-slide v-for="(item, index) in team" :key="index">
          <div class="flex flexSB swiperCon" style="height: 100%">
            <div class="flexS">
              <div class="flexS">
                <div class="t-mes">
                  <div>{{ item.userName }}</div>
                  <div>
                    <!-- {{ item.deptName }}&nbsp;&nbsp;&nbsp; -->
                    {{ item.postName }}
                  </div>
                </div>
              </div>
            </div>

            <img class="img" :src="item.avatar" />
          </div>
        </swiper-slide>
      </swiper>
      <div class="custom-button-prev"></div>
      <div class="custom-button-next"></div>
      <!-- <div class="flex prev">
        <img
          src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/team/prev.png"
        />
      </div> -->
      <!-- <div class="flex next">
        <img
          src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/team/next.png"
        />
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "teamDetails",
  props: ["showteammes", "swiperindex", "team"],
  data() {
    let _this = this;
    return {
      swiperIndex: 0,
      swiperOption: {
        loop: true,
        speed: 1000,
        initialSlide: _this.swiperindex,
        on: {
          slideChangeTransitionEnd: function () {
            _this.$emit("slide", this.realIndex); //通知父组件改变。
            // _this.showteammes = _this.team[this.realIndex];
            // _this.swiperindex = this.realIndex;
          },
        },
        autoplay: false,
        slidesPerView: 2,
        // 自定义翻页按钮配置
        navigation: {
          prevEl: ".custom-button-prev", // 点击上一页
          nextEl: ".custom-button-next", // 点击下一张时
        },
        // 自定义翻页器配置
        pagination: {
          el: "null",
        },
      },
    };
  },
  created() {
    
  },
  methods: {
    init() {
    },
    onSlideChange() {
    },
  },
};
</script>
<style lang="scss">
.listCon {
  flex-wrap: wrap;
  padding: 60px 10px 60px 10px;
  .showMes {
    margin: 0 auto;
    margin-bottom: 50px;
    width: 1200px; /*no*/
    min-height: 474px;
    .headImg {
      width: 329px;
      height: 477px;
      background-color: #ccc;
      margin-top: 12px;
      margin-right: 74px;
    }
    .t-mes {
      width: 540px;
      min-height: 474px;
      .title1 {
        margin: 8px 0;
        font-size: 34px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #212121;
        span {
          font-size: 22px;
          font-family: Arial;
          font-weight: 400;
          color: #666666;
          margin-left: 17px;
        }
      }
      .school {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
      .degree {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        // margin-left: 10px;
      }
      .boundary1 {
        width: 520px;
        height: 1px;
        background-color: #999999;
        margin: 14px 0 32px 0;
      }
      li {
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 28px;
        color: #666666;
      }
      .t-story {
        margin-top: 35px;
        font-size: 15px;
        color: #666666;
      }
    }
    .indicatorDiv {
      width: 49px;
      height: 474px;
      flex-direction: column;
      margin-left: 206px;
      .indicator {
        width: 49px;
        height: 49px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #b2b2b2;
        cursor: pointer;
        // margin-bottom: 60px;
      }
    }
  }
  .teamSlideshow {
    width: 1200px; /*no*/
    height: 100px; /* no */
    background-color: #ddd;
    margin: 0 auto;
    margin-bottom: 60px;
    position: relative;
    .swiper-container {
      height: 100%;
      .swiperCon {
        padding: 0 42px;
        background: #f5f5f5;
        .t-mes {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 30px;
        }
        .img {
          width: 49px;
          height: 73px;
        }
      }
    }
    .prev {
      width: 57px;
      height: 100%;
    }
    .next {
      position: absolute;
      z-index: 20;
      top: 0;
      right: 0;
      width: 42px;
      height: 100%;
      cursor: pointer;
    }
    .custom-button-prev {
      position: absolute;
      z-index: 30;
      top: 0;
      left: 0;
      width: 600px;/* no */
      height: 100px;/* no */
      cursor: pointer;
    }
    .custom-button-next {
      position: absolute;
      z-index: 30;
      top: 0;
      right: 0;
      width: 600px;/* no */
      height: 100px;/* no */
      cursor: pointer;
    }
    .swiper-slide-active {
      .swiperCon {
        background-color: #343434;
        color: #fff;
      }
    }
  }
}
</style>