<template>
  <div style="padding: 0 30px">
    <div class="j-storyExampleTitle">
      <div class="flex" v-if="recruitmentText.length > 0">
        {{ recruitmentText[0].typeId == 2 ? "社会" : "校园" }}招聘
      </div>
    </div>
    <div class="jobMes">
      以下是空缺岗位的具体职责与要求，有意者请详阅，并发简历、
    </div>
    <div class="jobMes">
      相应作品至594678509@qq.com，邮件标题请注明：“应聘部门+职位”
    </div>
    <div v-for="(item, index) in recruitmentText"
        :key="index">
      <li class="j-company">{{item[0].deptName}}</li>
      <div
        class="stCon"
        v-for="(item1, index1) in item"
        :key="index1"
        :class="showdata[index][index1] ? 'showClass' : 'hideClass'"
      >
        <div class="flex flexSB stConTitle">
          <span>医院管理高级咨询顾问</span>
          <img
            class="img"
            :src="showdata[index][index1] ? hide : show"
            @click="changeState(index,index1)"
          />
        </div>
        <div v-html="item1.jobIntro"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "jobText",
  props: ["recruitmentText"],
  data() {
    return {
      showdata: [],
      show: require("../assets/img/show.png"),
      hide: require("../assets/img/hide.png"),
    };
  },
  created() {
    console.log('初始化..............')
    let showdata = [];
    for (let i in this.recruitmentText) {
      let sonArr = []
      for(let j in this.recruitmentText[i]){
        console.log(j)
        sonArr.push(false)
      }
      showdata.push(sonArr)
    }
    this.showdata = showdata;
    
  },
  methods: {
    changeState(index,index1) {
      this.showdata[index][index1] = !this.showdata[index][index1];
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="scss">
.j-storyExampleTitle {
  width: 265px;
  margin: 40px auto;
  font-size: 36px;
  font-weight: bold;
  position: relative;
  flex-wrap: wrap;
}
.j-storyExampleTitle::after {
  position: absolute;
  left: 6px;
  top: 50%;
  transform: translateY(50%);
  z-index: 10;
  content: "";
  width: 30px;
  height: 3px;
  background-color: #000;
}
.j-storyExampleTitle::before {
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(50%);
  z-index: 10;
  content: "";
  width: 30px;
  height: 3px;
  background-color: #000;
}
.j-company {
  margin: 40px 0 20px 0;
  font-size: 25px;
  font-weight: bold;
}
.jobMes {
  line-height: 2;
}
.stCon {
  background-color: #fafafa;
  padding: 24px 34px; /* no */
  margin-bottom: 30px;
  .stConTitle {
    font-size: 18px; /* no */
    font-weight: bold;
    margin-bottom: 24px; /* no */
    position: relative;
    .img {
      cursor: pointer;
    }
  }
  .stConTitle::after {
    position: absolute;
    content: "";
    left: 0;
    bottom: -24px; /* no */
    width: 100%;
    height: 1px; /* no */
    z-index: 10;
    background-color: #999;
  }
}
.hideClass {
  height: 79px; /* no */
  overflow: hidden;
  transition: 2s;
}
.showClass {
  height: auto; /* no */
  overflow: hidden;
  transition: 2s;
}
</style>