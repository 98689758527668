<template>
  <div>
    <div class="m_banner">
      <div class="m_banner_text">服务案例</div>
      <div class="m_banner_i"></div>
      <div class="m_banner_text2">累积服务医院超过800+</div>
    </div>
    <div class="serve_m_box" v-if="!isDetails">
      <div class="index_title">
        <span class="index_title_before"></span>
        <span class="index_title_span">服务案例</span>
        <span class="index_title_after"></span>
      </div>
      <div class="index_subhead">易策医管集团已累计为超过<span style="color: #2D43F2;font-weight: 500">800+</span>家医院提供优质服务</div>
      <div class="serve_m_type">
        <div
          :class="['serve_m_type_item', isType == item.type ? 'serve_m_type_isItem' : '']"
          v-for="(item, index) in hisTypeList"
          :key="index" 
          @click="changeTab(item.type)"
        >
          {{ item.value }}
        </div>
      </div>
      <div class="serve_m_hospital">
        <div class="serve_m_hospital_item" v-for="(item, index) in hisList2" :key="index" @click="getDetails(item.hospitalId)">
          <img class="serve_m_hospital_img" :src="item.hospitalLogo" />
          <div class="serve_m_hospital_name">{{ item.hospitalName }}</div>
        </div>
      </div>
      <div class="index_more" @click="stretch">{{ hisShow ? '收起' : '查看更多' }}</div>
    </div>
    <div class="serve_m_Details_box" v-else>
      <img class="serve_m_Details_img" :src="detailsObj?.hospitalImg" alt="">
      <div class="serve_m_Details_info">
        <img class="serve_m_Details_logo" :src="detailsObj?.hospitalLogo" alt="">
        <div class="serve_m_Details_i">
          <div class="serve_m_Details_hospitalName">{{ detailsObj?.hospitalName }}</div>
          <div class="serve_m_Details_ii">
            <div class="serve_m_Details_hospitalLevel" v-if="detailsObj.hospitalLevel">{{ detailsObj?.hospitalLevel }}</div>
            <div class="serve_m_Details_hospitalType" v-if="detailsObj.hospitalType">{{ detailsObj?.hospitalType }}</div>
            <div class="serve_m_Details_hospitalBed" v-if="detailsObj.hospitalBed">{{ detailsObj?.hospitalBed }}</div>
          </div>
        </div>
      </div>
      <div class="serve_m_Details_hospitalInfo">
        简介: {{ detailsObj?.hospitalInfo }}
      </div>
      <div class="serve_m_relevance">
        <div class="serve_m_relevance_title">相关医院</div>
        <div class="serve_m_relevance_box">
          <div class="serve_m_relevance_list">
            <div class="serve_m_relevance_swiper">
              <swiper :options="swiperOption" ref="mySwiper1">
                <swiper-slide v-for="(item, index) in detailsObj?.relevantList" :key="index">
                  <div class="serve_m_relevance_item" :data-id="item.relevantId">
                    <img class="serve_m_relevance_item_logo" :src="item.hospitalLogo" alt="" :data-id="item.relevantId">
                    <div class="serve_m_relevance_item_name" :data-id="item.relevantId">{{ item.relevantName }}</div>
                  </div>
                </swiper-slide>
              </swiper>
              <div class="swiper-button-next"></div>
              <div class="swiper-button-prev"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getHospitalDetails } from "@/api/index";
export default {
  props: ['hisTypeList', 'hisList2', 'hisShow'],
  data() {
    let _this = this
    return {
      isType: null,
      isDetails: false,
      detailsObj: {},
      swiperOption: {
        //可见图片张数
        slidesPerView: 2,
        // 默认选中中间一张
        centeredSlides: false,
        // spaceBetween: 30,
        //无限循环
        loop: true,
          //小圆点（我此处没使用，列出供参考）
        pagination:{
          el: '.swiper-pagination',
          clickable: true,
          clickableClass : 'my-pagination-clickable',
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        //自动轮播
        autoplay: false,
        on: {
          click: function (event) {
            _this.getDetails(event.target.getAttribute("data-id"))
          },
        },
      }
    }
  },
  created() {
    let query = this.$route.query;
    if (query.activeName == 2) {
      this.getDetails(query.hospitalId)
    }
  },
  methods: {
    stretch() {
      this.$emit('stretch', this.hisShow ? false : true)
    },
    changeTab(type) {
      this.isType = type
      this.$emit('changeTab', type)
    },
    getDetails(hospitalId) {
      this.isDetails = true
      getHospitalDetails({ hospitalId: hospitalId }).then(res => {
        if (res.code == 200) {
          this.detailsObj = res.data
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .m_banner {
    width: 100%;
    height: 515px;
    background-image: url("../assets/img/mobile_bg8.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .m_banner_text {
      font-size: 32px;
      color: #fff;
      text-align: center;
      padding-top: 132px;
    }
    .m_banner_i {
      width: 45px;
      height: 6px;
      background-color: #fff;
      margin: 20px auto 16px;
    }
    .m_banner_text2 {
      font-size: 40px;
      color: #fff;
      text-align: center;
    }
  }
  .index_title {
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    color: #000;
    margin: 0 auto;
    .index_title_span {
      margin: 0 20px;
    }
    .index_title_after {
      display: inline-block;
      width: 107px;
      height: 23px;
      background-image: url("../assets/img/titleIcon1.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transform: rotate(180deg);
    }
    .index_title_before {
      display: inline-block;
      width: 107px;
      height: 23px;
      background-image: url("../assets/img/titleIcon1.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  .index_subhead {
    text-align: center;
    font-size: 26px;
    font-weight: 400;
    color: #333;
    margin: 23px auto;
  }
  .index_more {
    width: 175px;
    height: 50px;
    line-height: 50px;
    border-radius: 4px;
    border: 2px solid #999999;
    font-size: 22px;
    color: #666;
    text-align: center;
    margin: 20px auto 0;
  }
  .serve_m_box {
    background-color: #fff;
    padding: 50px 37px;
    .serve_m_type {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      padding: 0 60px;
      .serve_m_type_item {
        width: fit-content;
        padding: 21px 30px;
        background: #FFFFFF;
        border-radius: 10px;
        border: 1px solid #BFBFBF;
        font-size: 24px;
        font-weight: 400;
        color: #333333;
        margin-bottom: 20px;
      }
      .serve_m_type_isItem {
        color: #fff;
        border: 1px solid #2D43F2;
        background: #2D43F2;
      }
    }
  }
  .serve_m_hospital {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 41px;
    .serve_m_hospital_item {
      width: 48%;
      text-align: center;
      background: #FFFFFF;
      box-shadow: 0px 3px 16px 1px rgba(0,14,52,0.06);
      border-radius: 4px;
      border: 2px solid #E6E6E6;
      margin-bottom: 28px;
      padding: 21px;
      .serve_m_hospital_img {
        width: 138px;
        height: 138px;
      }
      .serve_m_hospital_name {
        font-size: 24px;
        font-weight: 400;
        color: #333333;
        padding-top: 20px;
      }
    }
  }
  .serve_m_Details_box {
    padding: 39px 55px;
    background: #fff;
    .serve_m_Details_img {
      width: 100%;
    }
    .serve_m_Details_info {
      display: flex;
      margin-top: 36px;
      .serve_m_Details_logo {
        width: 111px;
        height: 111px;
        margin-right: 30px;
      }
      .serve_m_Details_i {
        .serve_m_Details_hospitalName {
          font-size: 30px;
          font-weight: bold;
          color: #333333;
          margin-bottom: 15px;
        }
        .serve_m_Details_ii {
          display: flex;
          border-radius: 2px;
          font-size: 18px;
          font-weight: 400;
          color: #FFFFFF;
          .serve_m_Details_hospitalLevel {
            background: #67CDDB;
            padding: 6px 10px;
            margin-right: 10px;
          }
          .serve_m_Details_hospitalType {
            background: #4863C4;
            padding: 6px 10px;
            margin-right: 10px;
          }
          .serve_m_Details_hospitalBed {
            background: #EFEFEF;
            color: #666666;
            padding: 6px 10px;
            margin-right: 10px;
          }
        }
      }
    }
    .serve_m_Details_hospitalInfo {
      font-size: 26px;
      font-weight: 400;
      color: #333333;
      line-height: 39px;
      margin-top: 30px;
    }
    .serve_m_relevance {
      .serve_m_relevance_title {
        font-size: 26px;
        font-weight: bold;
        color: #333333;
        padding: 29px 0 15px 0;
      }
      .serve_m_relevance_box {
        // height: 212px;
        background: #FFFFFF;
        border-radius: 8px;
        border: 1px solid #D2D2D2;
        overflow-x: auto;
        .serve_m_relevance_list {
          position: relative;
          .swiper-button-next {
            position: absolute;
            right: 18px;
            width: 48px;
            height: 48px;
            background-color: #F6F6F6;
            border-radius: 2px;
            &::after {
              font-size: 26px;
              color: #9A9A9D;
            }
          }
          .swiper-button-prev {
            position: absolute;
            left: 18px;
            width: 48px;
            height: 48px;
            background-color: #F6F6F6;
            border-radius: 2px;
            &::after {
              font-size: 26px;
              color: #9A9A9D;
            }
          }
          .serve_m_relevance_swiper {
            width: 500px;
            margin: 0 auto;
          }
          .serve_m_relevance_item {
            text-align: center;
            padding-top: 28px;
            .serve_m_relevance_item_logo {
              width: 111px;
              height: 111px;
            }
            .serve_m_relevance_item_name {
              padding: 25px 6px;
              font-size: 22px;
              font-weight: 400;
              color: #333333;
            }
          }
        }
      }
    }
  }
</style>
