<template>
  <div>
    <div class="pc_team">
      <!-- 首页banner图 -->
      <div class="teamBanner">
        <div>专 家 团 队</div>
      </div>
      <!-- 标签页 -->
      <div class="tabs">
        <div class="flex tabsTitle">
          <span>首页</span> > <span>专家团队</span>
        </div>
        <el-tabs class="tabsMod" v-model="activeName" @tab-click="handleClick">
          <el-tab-pane
            v-for="(item0, index0) in tabsData"
            :key="index0"
            :label="item0.text"
            :name="item0.teamId"
          >
            <div class="flexS flexT flexSB listCon" v-if="!detailsMod">
              <div
                class="flexS list"
                v-for="(item, index) in team"
                :key="index"
                @click="toDetails(index)"
              >
                <img class="avatar" :src="item.avatar" />
                <div class="listText">
                  <div class="title">{{ item.userName }}</div>
                  <div class="post">
                    <!-- {{ item.deptName }}&nbsp;&nbsp;&nbsp; -->
                    {{ item.postName }}
                  </div>
                  <div class="record" v-html="item.title"></div>
                  <div class="flexS t-step" style="color: #78787a">
                    进一步了解
                    <img
                      src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/home/next2.png"
                    />
                  </div>
                </div>
              </div>
              <div
                class="flex"
                v-if="showMore()"
                style="width: 100%"
                @click="getMore(index0 + 1)"
              >
                <div class="flex t-more">查看更多专家</div>
              </div>
            </div>
            <team-Details
              :showteammes="showTeamMes"
              :swiperindex="swiperIndex"
              :team="teamAll"
              @slide="slide"
              v-if="detailsMod && activeName - 1 == index0"
              ref="details"
            />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <mobileTeam
      class="mobile_team"
      ref="teamRef"
      @getList="getList"
      @getMore="getMore"
      @getDetails="getDetails"
      :team="team"
     />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getTeamList } from "@/api/index";
import teamDetails from "@/components/teamDetails";
import mobileTeam from '@/mobileViews/team.vue'
export default {
  name: "",
  metaInfo: {
    title:
      "广州易策医管集团成立于2001年，旗下拥有4家医院管理咨询机构，专注于医院管理咨询、医管学术研究、医院内部管理与素质培训、医院托管与投资、医院精益运营信息技术与大数据服务。",
    meta: [
      {
        name: "keywords",
        content:
          "易策,医管,易策咨询,致和咨询,医院管理,易策健康,绩效管理，高质量发展",
      },
      {
        name: "description",
        content:
          "广州易策医管集团成立于2001年，旗下拥有4家医院管理咨询机构，专注于医院管理咨询、医管学术研究、医院内部管理与素质培训、医院托管与投资、医院精益运营信息技术与大数据服务。已服务全国600多家医院，凭借专业的理论知识、管理经验、信息技术及学科构建等，实现与医院之间的管理知识技术转移，推动中国医院的职业化管理进程，提升医院的职业化管理水平。",
      },
    ],
  },
  components: { teamDetails, mobileTeam },
  computed: {
    ...mapState(["changeActiveName"]),
  },
  watch: {
    changeActiveName(newData, oldData) {
      this.activeName = newData.toString();
      this.getList();
    },
  },
  data() {
    return {
      activeName: "1",
      tabsData: [
        { teamId: "1", text: "医院运营管理专家" },
        { teamId: "2", text: "易策医管研究发展院" },
        // { teamId: "3", text: "高质量门诊能力提升专家" },
        { teamId: "3", text: "医院质量管理与服务建设专家" },
      ],
      swiperIndex: 0, // 详情模式下的轮播图序号
      detailsMod: false, // true:专家个人详情模式    false:专家列表展示模式
      team: [],
      teamAll: [],
      showTeamMes: {},
      queryParams1: {
        teamId: 1,
        pageNum: 1,
        pageSize: 10,
      },
      queryParams2: {
        teamId: 2,
        pageNum: 1,
        pageSize: 8,
      },
      queryParams4: {
        teamId: 4,
        pageNum: 1,
        pageSize: 12,
      },
    };
  },
  created() {
    let query = this.$route.query;
    if (query.activeName) {
      this.activeName = query.activeName;
    }
    this.getList();
    setTimeout(() => {
      if (query.userId) {
        this.getDetails(query.userId);
      }
    }, 100);
  },
  methods: {
    // 获取专家列表数据
    getList(num) {
      let teamId=1
      let activeName = num ? num : this.activeName,
        queryParams1 = this.queryParams1,
        queryParams2 = this.queryParams2,
        queryParams4 = this.queryParams4;
      if(activeName == 3){
        teamId = 4
      }else{
        teamId = activeName
      }
      this.detailsMod = false
      getTeamList({ teamId: teamId }).then((res) => {
        if (res.code == 200) {
          if (activeName == "1") {
            if (queryParams1.pageSize == 10) {
              this.team = res.rows.slice(0, 10);
            } else {
              this.team = res.rows;
            }
          }
          if (activeName == "2") {
            if (queryParams2.pageSize == 8) {
              this.team = res.rows.slice(0, 8);
            } else {
              this.team = res.rows;
            }
          }
          if (activeName == "3") {
            if (queryParams4.pageSize == 12) {
              this.team = res.rows.slice(0, 12);
            } else {
              this.team = res.rows;
            }
          }
          this.teamAll = res.rows;
        }
      });
    },
    // 点击切换tabs
    handleClick() {
      this.detailsMod = false;
      let path = this.$router.history.current.path;
      window.history.pushState(
        null,
        "",
        path + "?" + "activeName=" + this.activeName
      );
      this.getList();
    },
    // 点击查看详情
    toDetails(index) {
      this.swiperIndex = index;
      // 特意再次封装是为了从首页也能跳转进来这里查看详情
      this.getDetails(this.teamAll[index].userId);
    },
    // 获取专家个人详情
    getDetails(userId) {
      let i = this.teamAll.findIndex((item) => {
        return item.userId == userId;
      });
      this.swiperIndex = i;
      getTeamList({ userId: userId }).then((res) => {
        if (res.code == 200) {
          this.showTeamMes = res.rows[0];
          this.$nextTick(() => {
            this.detailsMod = true;
          });
          this.$refs['teamRef'].getDetails(this.showTeamMes)
        }
      });
    },
    // 专家个人详情页面切换
    slide(index) {
      this.swiperIndex = index;
      this.showTeamMes = this.teamAll[index];
      // 动态修改路由参数，方便分享
      let path = this.$router.history.current.path;
      let query = this.$route.query;
      query.userId = this.teamAll[index].userId.toString();
      window.history.pushState(
        null,
        "",
        path +
          "?" +
          "userId=" +
          query.userId +
          "&activeName=" +
          query.activeName
      );
    },
    // 显示各个tabs页面的点击查看更多按钮
    showMore() {
      let activeName = this.activeName;
      if (activeName == "1") {
        if (this.queryParams1.pageSize == 10) {
          return true;
        } else {
          return false;
        }
      }
      if (activeName == "2") {
        if (this.queryParams2.pageSize == 8) {
          return true;
        } else {
          return false;
        }
      }
      if (activeName == "3") {
        return false;
        // 致和咨询人数较少
        // if (this.queryParams4.pageSize == 8) {
        //   return true;
        // } else {
        //   return false;
        // }
      }
    },
    getMore(index) {
      if (index == 1) {
        this.queryParams1.pageSize = 10000;
      }
      if (index == 2) {
        this.queryParams2.pageSize = 10000;
      }
      if (index == 4) {
        this.queryParams4.pageSize = 10000;
      }
      this.team = this.teamAll;
    },
  },
};
</script>
<style lang="scss">
.t-more {
  background-color: #7c7b7b;
  color: #fff;
  width: 172px;
  height: 33px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}
.teamBanner {
  width: 100%;
  height: 400px;
  background-image: url("http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/team/bg5.png");
  background-repeat: no-repeat;
  // background-position: 0 -181px;
  background-size: 100%;
  font-size: 55px;
  font-weight: bold;
  padding: 123px 410px 60px;
  color: #fff;
}
.tabs {
  .tabsMod {
    .el-tabs__content {
      .listCon {
        flex-wrap: wrap;
        padding: 60px 10px 60px 10px;
        .list {
          width: 573px;
          height: 315px;
          padding: 18px;
          box-shadow: rgba(245, 245, 245, 1) 0px 0px 4px 4px;
          margin-bottom: 34px;
          cursor: pointer;
          .avatar {
            margin-right: 20px;
            width: 179px;
            height: 264px;
          }
          .listText {
            width: 338px;
            height: 264px;
            color: #78787a;
            font-size: 14px;
            position: relative;
            .title {
              font-size: 22px;
              margin: 20px 0 0 0;
              padding: 0;
              color: #000;
            }
            .post {
              margin: 12px 0;
              font-size: 16px;
            }
            .record {
              ul {
                padding-left: 18px;
                font-size: 13px;
                li:nth-child(n + 5) {
                  display: none;
                }
              }
            }
            .expList {
              margin-bottom: 10px;
            }
            .t-step {
              position: absolute;
              z-index: 10;
              left: 0;
              bottom: 0;
              font-size: 15px;
              img {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }
    }
  }
}

.mobile_team {
  display: none;
}
@media screen and (max-width: 650px) {
  .mobile_team {
    display: block;
    background-color: #fff;
  }
  .pc_team {
    display: none;
  }
}
</style>
