<template>
    <div>
        <!-- banner图 -->
        <div class="trainBanner">
          <div class="trainBanner-box">
            <div class="trainBanner-title">医管培训</div>
            <div class="trainBanner-text">如有疑问或想了解更多医院管理信息请联系我们，我们将第一时间与您联系
              (涵盖规划、运营、绩效、国考、DRG/DIP、服务、等级评审等)</div>
            <button class="btnBanner" @click="relation">联系我们</button>  
          </div>
        </div>
        <!-- main主体 -->
        <div class="content-box" v-scroll-reveal>
            <div class="bigBox">
                <div class="bigBoxs">
                    <div class="trainMain" v-for="(item,index) in list" :key="index"  @mouseover="handleMouseOver" @mouseout="hideOverlay">
                        <div style="overflow: hidden;">
                            <img class="trainImg" :src="item.img" alt="" >
                            <div class="overlay" v-show="isOverlayVisible">
                                <button class="btnBox">查看详情</button>
                            </div>
                        </div>
                        <div class="trainBox">
                            <div class="titleBox">{{item.title}}</div>
                            <p class="textBox">{{item.main}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        
        <!-- 分页器 -->
        <div class="page" >
            <el-pagination
              background
              layout="prev, pager, next"
              :page-size="6"
              :total="7"
              prev-text="上一页"
              next-text="下一页"
              @current-change="pageChange"
              v-show="data.length > 6">
            </el-pagination>
        </div>
        
        <!-- 联系我们表单 -->
        <relation :open="open" @openFun="openFun" />
    </div>
</template>

<script>
import relation from "../components/relation";
export default{
    components:{
        relation
    },
    data(){
        return{
            open: false,
            isOverlayVisible: false,
            list:[],
            data:[
                {
                    title:'医院窗口服务标准化建设培训',
                    main:'医务礼仪、 6S精益管理、医患沟通、服务流程、晨会管理',
                    img: require("../assets/img/train1.png"),
                },
                {
                    title:'医疗系统行政人员政治素养及履职能力提升',
                    main:'党员政治能力、行政事务处理能力、舆情管理、政务礼仪',
                    img: require("../assets/img/train2.png"),
                },
                {
                    title:'新医改形势下医院精细化财务管理',
                    main:'管理工作重要性、信息系统升级、人才梯队培养',
                    img: require("../assets/img/train3.png"),
                },
                {
                    title:'病案首页规范填写专题培训会',
                    main:'填写规范、常见问题、案例分析、重点解读',
                    img: require("../assets/img/train4.png"),
                },
                {
                    title:'现场6S精益管理培训',
                    main:'走访问题收集、建立合作共识、培养知识技能、推动运作计划',
                    img: require("../assets/img/train5.png"),
                },
                {
                    title:'医院及科室品牌文化建设',
                    main:'品牌效应、落实责任、加强督查、奖罚并行',
                    img: require("../assets/img/train6.png"),
                },
                {
                    title:'科室服务体系建设策略与方法',
                    main:'科技化管理、人性化服务、职责目标、品质管理',
                    img: require("../assets/img/train7.png"),
                },
                {
                    title:'医院绩效及运营管理策略与方法',
                    main:'挖潜力、提效率、调结构、控成本、促增长',
                    img: require("../assets/img/train8.png"),
                },
                {
                    title:'科室精细化运营管理及业务能力提升培训',
                    main:'精细化、信息管理、拓展职能、成本控制、监督考核',
                    img: require("../assets/img/train9.png"),
                },
                // {
                //     title:'DRG/DIP支付下医院精细化管理系列培训',
                //     main:'DRG/DIP权重（分值）精细化管理、DRG/DIP权重（分值）结构精细化管理',
                //     img: require("../assets/img/train10.png"),
                // },
                {
                    title:'DRG/DIP支付下医院精细化管理系列培训',
                    main:'政策解读、病案拟测、入科培训、管理建设、系统搭建、评估优化',
                    img: require("../assets/img/train10.png"),
                },
            ]
        }
    },
    created(){
        this.pageChange(1)
    },
    methods:{
        relation(){
            this.open = true
        },
        /** 提交后关闭 */
        openFun(bo) {
            this.open = bo;
        },
        handleMouseOver(){
            this.isOverlayVisible = true
        },
        hideOverlay(){
            this.isOverlayVisible = false
        },
        pageChange(page){
            const index = (page - 1) * 6;
            this.list = this.data.slice(index, index + 6);
        }
    }
}
</script>

<style lang="scss">
.trainBanner {
    width: 100%;
    height: 400px;
    background-image: url("../assets/img/train.png");
    background-repeat: no-repeat;
    // background-position: 0 -181px;
    background-size: 100%;
    font-size: 52px;
    font-weight: 400;
    color: #fff;
    margin-bottom: 15px;

    .trainBanner-box{
        width: 1200px;
        margin: 0 auto;

        .trainBanner-title{
            padding-top: 80px;
        }

        .trainBanner-text {
            font-size: 16px;
            margin-top: 22px;
            width: 550px
        }
    }
}
.btnBanner{
    width: 120px;
    height: 40px;
    color: #fff;
    background: #2D43F2;
    border-radius: 20px 20px 20px 20px;
    opacity: 1;
    border: 0px;
    margin-top: 44px;
    font-size: 16px;
    cursor:pointer
}
.bigBox{
    .bigBoxs{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .trainMain{
            position: relative;
            width: 380px;
            height: 366px;
            background: #FFFFFF;
            box-shadow: 0px 3px 20px 1px rgba(99,99,99,0.16);
            border-radius: 0px 0px 0px 0px;
            opacity: 1;
            border: 1px solid #E1E1E1;
            overflow: hidden;
            margin-top: 36px;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
                transform: scale(1.02);
                box-shadow: 0px 3px 14px 1px rgba(99, 99, 99, 0.16);
            }
            .trainImg{
                position: relative;
                width: 380px;
                height: 240px;
                background: #FFFFFF;
                border-radius: 0px 0px 0px 0px;
                opacity: 1;
                transition: transform 0.3s ease;
            }
            &:hover .trainImg{
                transform: scale(1.05);
            }
            .trainBox{
                margin: 18px 0 0 20px;

                .titleBox{
                    width: 380px;
                    height: 24px;
                    font-size: 18px;
                    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    line-height: 0px;
                    -webkit-background-clip: text;
                    // -webkit-text-fill-color: transparent;
                }
                .textBox{
                    width: 322px;
                    height: 41px;
                    font-size: 14px;
                    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                    font-weight: 400;
                    color: #949494;
                    line-height: 22px;
                    -webkit-background-clip: text;
                    // -webkit-text-fill-color: transparent;
                    margin: 0;
                }
            }
        }
        .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 380px;
        height: 245px;
        background-color: rgba(0, 0, 0, 0.5); /* 遮罩层的背景颜色和透明度 */
        display: none;
        line-height: 240px;

        .btnBox{
            width: 120px;
            height: 40px;
            background: #3388FF;
            color: #fff;
            border-radius: 20px 20px 20px 20px;
            opacity: 1;
            border: 0px;
            translate: 130px;
            font-size: 16px;
            cursor: pointer;
        }
        }
        .trainMain:hover .overlay {
      display: block; /* 鼠标移入图片时显示遮罩层 */
        }
    }

    
}
.content-box{
    width: 1200px;
    margin: 0 auto ;
}
// .el-pagination.is-background .el-pager li:not(.disabled).active {
//     background-color: #55499c;
//     color: #fff;
//   }
.el-pagination span{
    background-color: #e4e4e4;
    width: 60px;
}
.page{
    margin: 40px 0 46px 0;
    display: flex;
    justify-content: center;
}

</style>