<template>
  <div>
    <img
      class="td2-img"
      v-if="initial"
      src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/college/college21.png"
      align="left"
    />
    <div class="flex resume1" v-if="initial">广州易策医管集团董事长：程江</div>
    <div class="sendWord" v-if="initial">
      <div class="flex">{{ defaultData.title }}</div>
      <div class="text" v-for="(item, index) in defaultData.data" :key="index">
        {{ item }}
      </div>
    </div>
    <div class="td2-imgBg">
      <img class="td2-img2" v-if="!initial" :src="text.header" align="left" />
      <div class="flex resume2" v-if="!initial">
        {{ text.postName }}：{{ text.author }}
      </div>
    </div>

    <div class="sendWord" v-if="!initial">
      <div class="flex">{{ text.title }}</div>
      <div class="text" v-html="text.intro"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "textDetails",
  props: ["initial", "text"],
  data() {
    return {
      defaultData: {
        title: "【易行于策】拼搏新征程，奋进新时代",
        data: [
          '一元复始，万象更新。',
          '在这律回春渐，新元肇启之时，谨向所有广州易策医管科技、易策咨询、易策研究发展院和致和咨询的家人们和伙伴们恭贺新年，向大家道一声：新年快乐，健康平安，阖家幸福！',
          '过去一年，疫情肆虐，我们的工作和生活环境发生了巨大变化。这一年里，疫情没有阻挡住大家前进的脚步，依然披荆斩棘一路前行。今天，走在街头，发现一切美好都在悄然而至，虽然也面临诸多困难与挑战，但正如习近平总书记所说：艰难方显勇毅，磨砺始得玉成。',
          '2022年，易策医管始终践行“提升医院经营能力，惠泽群众健康生活”的使命。发挥专家团队作用，创新发展理念，助力医院高质量发展。开展前瞻性研究，积极探索现代医院管理改革发展的新路径、新方法、新机制。加强精细化管理和信息化建设，踔厉奋发，勇毅前行。研发新咨询项目实现创新和突破，获得了市场赞赏与认同。我们的团队日趋成熟，优质服务深入人心，竞争力持续提升，成为医院值得信赖的管理合作伙伴。',
          '对易策医管来说，价值体现比数字增长要来的更有意义，用真本事和硬实力去帮助我们的医院解决管理和运营的难题，才是易策医管的价值所在。',
          '易策咨询公司2022年为多达百家医院提供了绩效管理咨询服务，易策EHP医院绩效软件系统已在全国百家医院上线，包括成都医学院第一附属医院、广西医科大学附属柳铁中心医院、广州中医药大学第三附属医院、宁波市中医院、北京中医药大学东直门医院等。',
          '原台湾长庚医院品管中心主任陈丽红老师的加盟，更是推动精益管理在医院的实施和应用，“精益化管理助推医院高质量发展——强化巩固国考指标”咨询服务获得服务医院的一致好评。',
          '易策医管研究发展院吸纳医疗行业的学科专家、管理专家、品牌专家等，推动医院及科室发展的新理念，其妇产科运营能力提升项目在广州医科大学附属顺德医院、重庆市渝北区第二人民医院等多家医院取得骄人成绩，医院业务推广及市场辅导项目在武威市肿瘤医院落地，帮助医院及科室的运营数据大幅度提升。',
          '致和咨询公司不断优化和提升等级评审辅导、优质服务能力提升等项目方案，帮助惠城区中医院等通过医院等级评审，推动的南海区第四人民医院樵山灯塔自愿者服务项目获得佛山市创新成果优秀奖，服务过的医院纷纷续约。',
          '易策医管科技公司专注于医院管理信息化建设，吸纳行业内精英，积极推动DIP/DRG精细化管理系统、医保智能审核系统、国考指标管理系统等顺利在定西市第二人民医院、邵东市人民医院、平果市人民医院的落地。',
          '除此之外，易策医管集团积极投身于社会公益活动，易策医管集团公益基金开展了河南郑州特大洪水捐赠活动，参与了龙越慈善基金会发起的抗战老兵贫困帮扶公益计划，力所能及的承担起社会责任。',
          '最后，致敬每一位易策人，感谢你们的奉献，尤其是在一线奔波的咨询老师们，明知前路难行，依然勇往直前；致敬易策医管的每一个合作伙伴，正是有你们的信任和支持，我们才能坚守不变的信念，易策人将不负大家的系托，一起携手推动健康事业发展。',
          '“易”智慧，“策”未来，在新的起点上，全体易策人要积极作为、团结协作、携手共进、为明天继续撸起袖子加油干！'
        ],
      },
    };
  },
  methods: {},
};
</script>
<style lang="scss">
.td2-img {
  width: 365px; 
  height: 547px; 
  margin-right: 40px; 
  margin-bottom: 40px; 
  position: relative;
}
.resume1 {
  position: absolute;
  z-index: 10;
  left: 0; 
  top: 640px; 
  width: 365px; 
  font-size: 16px; 
  font-weight: bold;
}
.td2-imgBg {
  width: 640px; 
  float: left;
  .td2-img2 {
    width: 600px; 
    margin-right: 40px; 
    position: relative;
  }
  .resume2 {
    width: 100%;
    height: 50px; 
    font-size: 16px; 
    font-weight: bold;
  }
}
.td2-imgBg:after {
  clear: both;
  content: ".";
  display: block;
  width: 0;
  height: 0;
  visibility: hidden;
}

.sendWord {
  .flex {
    font-size: 33px; 
    font-weight: bold;
    margin-bottom: 45px; 
  }
}
.text {
  margin-top: 40px;
  text-indent: 2em;
  line-height: 2.2;
  font-size: 17px; 
}
</style>