<template>
  <div class="flex userCenter">
    <div class="entraMenu">
      <div class="flex entTitle">请选择项目公司</div>
      <div class="flex entCon">
        <div
          class="entraMenuList"
          v-for="(item, index) in entData"
          :key="index"
          :class="active == item.deptId ? 'acClass' : ''"
          :style="{
            backgroundImage: 'url(' + item.avatar + ')',
          }"
          @click="select(item.deptId)"
        >
          <div class="flex">{{ item.deptName }}</div>
          <div class="flex" v-if="item.deptName2">{{ item.deptName2 }}</div>
          <img
            class="acDot"
            v-if="active == item.deptId"
            src="../assets/img/acDot.png"
          />
        </div>
      </div>
      <div class="flex">
        <el-button class="entraBtn" type="primary" @click="entraTo"
          >确定进入</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import {
  deptList,
} from "@/api/index";
export default {
  metaInfo: {
    title:"广州易策医管集团成立于2001年，旗下拥有4家医院管理咨询机构，专注于医院管理咨询、医管学术研究、医院内部管理与素质培训、医院托管与投资、医院精益运营信息技术与大数据服务。",
    meta: [
      {
        name: "keywords",
        content:"易策,医管,易策咨询,致和咨询,医院管理,易策健康,绩效管理，高质量发展",
      },
      {
        name: "description",
        content:"广州易策医管集团成立于2001年，旗下拥有4家医院管理咨询机构，专注于医院管理咨询、医管学术研究、医院内部管理与素质培训、医院托管与投资、医院精益运营信息技术与大数据服务。已服务全国600多家医院，凭借专业的理论知识、管理经验、信息技术及学科构建等，实现与医院之间的管理知识技术转移，推动中国医院的职业化管理进程，提升医院的职业化管理水平。",
      },
    ],
  },
  data() {
    return {
      active: 0,
      entData:[]
    };
  },
  created(){
    // 阻止非法进入
    let user = JSON.parse(localStorage.getItem('user')),that=this
    if(user){
      if(!user.userId){
        this.msgError('userId为空')
        setTimeout(()=>{
          this.$router.push('/login');
        },1000)
      }
    }else{
      this.$router.push('/login');
    }
    console.log(user)
    this.getDeptList()
  },
  methods: {
    /** 请求数据 */
    getDeptList(){
      deptList().then(res=>{
        if(res.code==200){
          let rows = res.rows
          for(let i in rows){
            if(rows[i].deptName == '易策医管研究发展院'){
              rows[i].deptName = '易策医管'
              rows[i].deptName2 = '研究发展院'
            }
          }
          this.entData = rows
        }
      })
    },
    /** 选择入口模块 */
    select(id) {
      if (this.active == id) {
        this.active = 0;
      } else {
        this.active = id;
      }
    },
    /** 进入内页 */
    entraTo() {
        let active = this.active
        if(active==0){
            this.msgWarning('请选择入口模块再点击进入')
            return
        }
        let obj = this.entData.filter(item=>{
            return active == item.deptId
        })
        localStorage.setItem('entra',JSON.stringify(obj))
        this.$router.push('/principal');
        
    },
  },
};
</script>
<style lang="scss">
.userCenter {
  width: 100%;
  height: 1000px;
  background-color: #fafcff;
  .entraMenu {
    width: 1200px;
    .entTitle {
      font-size: 36px;
      font-weight: 400;
      color: #192f3e;
      margin-bottom: 35px;
    }
    .entCon {
      width: 1200px;
      flex-wrap: wrap;
      justify-content: space-around;
      margin-bottom: 20px;
      .entraMenuList {
        width: 270px;
        height: 270px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 70px;
        margin-bottom: 40px;
        font-size: 26px;
        font-weight: 400;
        line-height: 40px;
        color: #002e4d;
        cursor: pointer;
        position: relative;
        border-radius: 10px;
        .acDot {
          position: absolute;
          z-index: 5;
          right: 15px;
          top: 15px;
          width: 22px;
          height: 22px;
        }
      }
      .entraMenuList:hover{
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.16);
      }
      .acClass {
        background-size: 102% 102%;
        background-position: -2px -2px;
        padding-top: 66px;
        border: 4px solid #66b2fe;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.16);
      }
    }
    .entraBtn {
      width: 260px;
      height: 60px;
      font-size: 26px;
      font-weight: 400;
      color: #ffffff;
      border-radius: 33px;
    }
  }
}
</style>