<template>
  <div class="flex flexT pl-userPrincipal">
    <div class="plDiv">
      <div class="pl-header">
        <div class="playTitle">今日广州番禺市桥中医院</div>
        <div class="time">发布时间：2021年11月5日</div>
      </div>
      <div class="flex flexSB pl-con">
        <div class="pl-video">
          <video-player
            class="textVideo1"
            :options="playerOptions"
          ></video-player>
        </div>
        <div class="pl-list">
          <div class="flex flexS pl-list-title">
            <div>为您推荐</div>
          </div>
          <div class="hidScrollbar pl-menu">
            <div
              class="cu hidLin1 pl-video-list"
              v-for="(item, index) in recommend"
              :key="index"
              @click="playList(item)"
            >
              {{ item.videoName }}
            </div>
          </div>
        </div>
      </div>
      <div class="cu flex flexS pl-collect" @click="collectChange">
        <img v-if="collect" src="../assets/img/collect2.png" />
        <img v-else src="../assets/img/collect1.png" />
        收藏
      </div>
    </div>
  </div>
</template>
<script>
import {
  getVideoList,
  addUserCollection,
  deleteUserCollection,
} from "@/api/index";
export default {
  metaInfo: {
    title:"广州易策医管集团成立于2001年，旗下拥有4家医院管理咨询机构，专注于医院管理咨询、医管学术研究、医院内部管理与素质培训、医院托管与投资、医院精益运营信息技术与大数据服务。",
    meta: [
      {
        name: "keywords",
        content:"易策,医管,易策咨询,致和咨询,医院管理,易策健康,绩效管理，高质量发展",
      },
      {
        name: "description",
        content:"广州易策医管集团成立于2001年，旗下拥有4家医院管理咨询机构，专注于医院管理咨询、医管学术研究、医院内部管理与素质培训、医院托管与投资、医院精益运营信息技术与大数据服务。已服务全国600多家医院，凭借专业的理论知识、管理经验、信息技术及学科构建等，实现与医院之间的管理知识技术转移，推动中国医院的职业化管理进程，提升医院的职业化管理水平。",
      },
    ],
  },
  data() {
    return {
      queryParams: {
        deptId: null,
      },
      queryParams2: {
        pageNum: 1,
        pageSize: 12,
        deptId: null,
      },
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        controls: true, //控制条
        preload: "none", //视频预加载
        muted: false, //默认情况下将会消除任何音频。
        loop: false, //导致视频一结束就重新开始。
        language: "zh-CN",
        aspectRatio: "29:18", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [],
        poster: "",
        width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
      },
      recommend: [],
      collect: true,
    };
  },
  created() {
    // 阻止非法进入
    let user = JSON.parse(localStorage.getItem("user")),
      that = this;
    if (user) {
      if (!user.userId) {
        this.msgError("userId为空");
        setTimeout(() => {
          this.$router.push("/login");
        }, 1000);
      }
    } else {
      this.$router.push("/login");
    }
    // 阻止非法未选择入口的情况进入详情
    let obj = JSON.parse(localStorage.getItem("entra"))[0];
    if (!obj.deptId) {
      this.msgError("请选择公司入口再进入当前页面");
      setTimeout(() => {
        this.$router.push("/entrance");
      }, 1000);
    }
    this.queryParams.deptId = obj.deptId;
    this.queryParams2.deptId = obj.deptId;
    this.queryParams.dataId = this.$route.query.dataId;
    this.video();
    this.videoList()
  },
  methods: {
    /** 获取当前播放视频 */
    video() {
      getVideoList(this.queryParams).then((res) => {
        if (res.code == 200) {
          if (res.rows == []) {
            this.msgError("当前视频异常，请观看其他视频");
            return;
          }
          let rows = res.rows[0];
          let ii = rows.videoUrl.lastIndexOf(".");
          let length = rows.videoUrl.length;
          this.playerOptions.sources = [
            {
              type: 'video/' + rows.videoUrl.slice(ii + 1, length),
              src: rows.videoUrl, //视频的地址，最好是放在服务器上
            },
          ];
          this.playerOptions.poster = rows.videoCover;
          console.log(rows.videoUrl.slice(ii + 1, length));
        }
      });
    },
    /** 获取推荐视频列表 */
    videoList(){
      getVideoList(this.queryParams2).then((res) => {
        if (res.code == 200) {
          this.recommend = this.wash(res.rows, "vie");
        }
      });
    },
    /** 将各个tabs的数据重新命名规划统一 */
    wash(data, type) {
      let d = [];
      // 视频
      if (type == "vie") {
        for (let i in data) {
          d.push({
            id: data[i].dataId,
            createTime: data[i].createTime,
            videoCover: data[i].videoCover,
            videoName: data[i].videoName,
            size: data[i].videoSize,
            url: data[i].videoUrl,
          });
        }
      }
      return d;
    },
    /** 收藏功能 */
    collectChange() {
      let bo = !this.collect;
      if (bo == false) {
        this.msgSuccess("已取消收藏");
      } else {
        this.msgSuccess("收藏成功");
      }
      this.collect = bo;
    },
    /** 选中播放 */
    playList(item) {
      console.log("选中播放：", item);
    },
  },
};
</script>
<style lang="scss">
.pl-userPrincipal {
  width: 100%;
  background-color: #f9fcff;
  padding: 28px 0;
  .plDiv {
    width: 1200px;
    height: 642px;
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(151, 151, 151, 0.16);
    .pl-header {
      width: 100%;
      height: 60px;
      padding: 10px 20px;
      background-color: #fff;
      .playTitle {
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        margin-bottom: 5px;
        position: relative;
      }
      .playTitle::after {
        position: absolute;
        content: "";
        z-index: 5;
        left: -20px;
        top: 50%;
        transform: translateY(-50%);
        width: 4px;
        height: 18px;
        background: #009ddf;
      }
      .time {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
      }
    }
    .pl-con {
      height: 540px;
      .pl-video {
        width: 870px;
        height: 540px;
        background-color: #222;
        .textVideo1 {
          width: 100%;
          height: 100%;
          .vjs-big-play-button {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      .pl-list {
        width: 314px;
        height: 100%;
        background-color: #222;
        color: #fff;
        padding: 22px 24px;
        .pl-list-title {
          padding: 10px 0;
          border-bottom: 2px solid #efefef;
          margin-bottom: 3px;
          div:nth-child(1) {
            font-size: 16px;
            font-weight: bold;
          }
        }
        .pl-menu {
          width: 100%;
          height: 450px;
          overflow-y: auto;
          .pl-video-list {
            width: 100%;
            padding: 9px 0;
            font-size: 14px;
            font-weight: 400;
          }
          .pl-video-list:hover {
            color: #4a86f7;
          }
        }
      }
    }
    .pl-collect {
      height: 40px;
      font-size: 16px;
      font-weight: 400;
      color: #757575;
      padding-left: 16px;
      img {
        margin-right: 6px;
      }
    }
  }
}
</style>