<template>
  <div>
    <div class="pc_index">
      <!-- 首页banner图 -->
      <div class="banner">
        <div class="bannerText" v-scroll-reveal>
          <div>广州易策医管集团</div>
          <div>提升医院经营能力 惠泽群众健康生活</div>
        </div>
      </div>
      <!-- 介绍 -->
      <div class="flex flexSB flexT introduce" v-scroll-reveal>
        <img class="introduceBg" src="../assets/img/introduceBg.png" />
        <div class="introduce-right">
          <div class="introduce-title">关于易策医管</div>
          <div class="introduce-rod"></div>
          <div class="introduce-text">
            广州易策医管集团（简称“易策医管”）成立于2001年，旗下拥有4家医院管理咨询机构，专注于医院管理咨询、医管学术研究、医院内部管理与素质培训、医院托管与投资、医院精益运营信息技术与数据服务。
          </div>
          <div class="introduce-more" @click="toCollege">查看更多</div>
          <div class="downImgClass" @click="openPDF"></div>
        </div>
      </div>
      <!-- 战绩 -->
      <div class="flex standings">
        <div class="flex flexSB standingsCon">
          <div class="list" v-for="(item, index) in standingsList" :key="index">
            <div class="data">
              <div class="flex flexB">
                <count-to
                  :ref="'example' + index"
                  :class="'example' + index"
                  :autoplay="false"
                  :startVal="0"
                  :endVal="item.data"
                  :duration="4000"
                  separator=""
                ></count-to>
                <div class="addIcon" v-if="item.type == 2">+</div>
              </div>
            </div>
            <div class="flex h-text">{{ item.text }}</div>
          </div>
        </div>
      </div>
      <!-- 公司拥有的软件 -->
      <div class="software">
        <div class="flex title" v-scroll-reveal>为医院提供系统的管理解决方案</div>
        <!-- <div class="mes">医院精细化管理4大模块</div> -->
        <div class="mes" v-scroll-reveal>
          <el-divider>医院精细化管理5大模块</el-divider>
        </div>
        <div class="flex tabBtnList" v-scroll-reveal>
          <div
            v-for="(item, index) in softArr"
            :key="index"
            class="flex tabBtn"
            :class="softIndex == item.deptId ? 'inforPitch' : ''"
            @click="softChangeTab(item.deptId)"
          >
            {{ item.deptName }}
          </div>
        </div>
        <div class="flex flexSB flexT softwareDiv">
          <div class="soft-left" v-scroll-reveal>
            <div class="soft-left-t">{{ softObj.modularName }}</div>
            <!-- <div class="soft-left-m">{{ softObj.modularNote }}</div> -->
            <img class="softIcon" src="../assets/img/softIcon.png" />
            <div class="softMes" v-html="softObj.modularContent"></div>
          </div>
          <img class="soft-right" :src="softObj.imgUrl" v-scroll-reveal />
        </div>
      </div>
      <!-- 专家团队 -->
      <div class="expertItem">
        <div class="flex title" v-scroll-reveal>专家团队介绍</div>
        <div class="mes" v-scroll-reveal>
          <el-divider>汇聚医院管理专家 吸纳各领域精英人才</el-divider>
        </div>
        <div class="swiperDiv" v-scroll-reveal>
          <swiper
            :slides-per-view="3"
            :space-between="50"
            :options="swiperOption"
            ref="mySwiper"
          >
            <swiper-slide v-for="(item, index) in team" :key="index">
              <div class="swiperCon" style="height: 100%" :data-tbbsm="item">
                <img class="img" :src="item.img" />
                <div class="flex expertPresentation">
                  {{ item.name }}
                </div>
                <div class="flex post">
                  <!-- <span>{{ item.unit }}</span> -->
                </div>
                <!-- <div class="flexS step" style="color: #78787a">
                  进一步了解
                  <img
                    src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/home/next2.png"
                  />
                </div> -->
              </div>
            </swiper-slide>
          </swiper>
          <div class="flex custom-button-prev">
            <div class="prev"></div>
          </div>
          <div class="flex custom-button-next">
            <div class="next"></div>
          </div>
        </div>
        <div class="flex expertMore" v-scroll-reveal @click="toExpertItem">
          查看更多
        </div>
      </div>
      <!-- 资讯中心 -->
      <div class="information">
        <div class="flex title" v-scroll-reveal>资讯中心</div>
        <div class="mes" v-scroll-reveal>
          <el-divider>为您提供最新最全面的品牌资讯及行情动态 </el-divider>
        </div>
        <div class="flex tabBtnList" v-scroll-reveal>
          <div
            class="flex tabBtn"
            :class="inforIndex == 1 ? 'inforPitch' : ''"
            @click="inforChangeTab(1)"
          >
            集团新闻
          </div>
          <div
            class="flex tabBtn"
            :class="inforIndex == 2 ? 'inforPitch' : ''"
            @click="inforChangeTab(2)"
          >
            咨询动态
          </div>
          <div
            class="flex tabBtn"
            :class="inforIndex == 3 ? 'inforPitch' : ''"
            @click="inforChangeTab(3)"
          >
            政策与解读
          </div>
        </div>
        <div class="flex flexT flexSB informationDiv">
          <div
            class="informationList"
            v-for="(item, index) in informationData"
            :key="index"
            @click="topNewDetail(item)"
            v-scroll-reveal
          >
            <img class="img" :src="item.header" />
            <div class="hidLin1 title1">{{ item.title }}</div>
            <div class="hidLin1 digest">{{ item.subTitle }}</div>
            <div class="time">{{ item.sendTime }}</div>
          </div>
        </div>
        <div class="flex">
          <div class="flex expertMore" v-scroll-reveal @click="moreNew">
            查看更多新闻
          </div>
        </div>
      </div>
      <!-- 学术活动 -->
      <div class="learning">
        <div class="flex flexT learningDiv">
          <div class="left">
            <div class="leftTitle" v-scroll-reveal>线下学术活动</div>
            <!-- <div class="leftDigest">医改案例解读 易策人的咨询故事</div> -->
            <div class="leftVideo" v-scroll-reveal>
              <video-player
                class="textVideo1"
                :options="playerOptions1"
              ></video-player>
            </div>
            <div class="leftMes" v-scroll-reveal>
              广州易策医管研究发展院联合各地卫健委、标杆医院，分别在全国16个城市（成都、重庆、顺德、武威、长治、咸阳、建德、平果、榆林、葫芦岛、信宜、禹州、宁海、上饶、郴州等地）成功举办17场医院运营及绩效管理公益论坛，600+医院院长、书记带领团队参加学习。
            </div>
          </div>
          <div class="right">
            <div
              class="mod"
              v-for="(item, index) in academicData"
              :key="index"
              @click="toNewWindow(item)"
              v-scroll-reveal
            >
              <div class="flex flexSB">
                <div class="modLabel">{{ item.label }}</div>
                <div style="color: #007cc3">已结束</div>
              </div>
              <div class="modTitle">{{ item.title }}</div>
              <div class="modText">活动时间：{{ item.time }}</div>
              <div class="modText">活动地点：{{ item.address }}</div>
              <div class="modText">特邀讲师：{{ item.lecturer }}</div>
              <div class="flexS modText2">
                进一步了解
                <img
                  src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/home/next2.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 易策故事汇 -->
      <div class="story">
        <div class="flex title" v-scroll-reveal>易策故事汇</div>
        <!-- <div class="mes">医改案例解读 易策人的咨询故事</div> -->
        <div class="mes" v-scroll-reveal>
          <el-divider>医改案例解读 易策人的咨询故事</el-divider>
        </div>
        <div class="flex flexSB storyDiv" v-scroll-reveal>
          <div class="flex flexSB mod">
            <div class="flex flexSB storyTitle">
              <div>服务案例</div>
              <div class="flex" @click="toServe()">
                <img
                  class="img"
                  src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/home/next.png"
                />
                更多案例
              </div>
            </div>
            <div
              class="listData"
              v-for="(item1, index1) in caseData"
              :key="index1"
              @click="toServe(item1)"
            >
              <img class="img" :src="item1.hospitalHeader" />
              <div class="hidLin2 listTitle">
                {{ item1.projectName }}
              </div>
              <div class="flexS listStep">
                进一步了解
                <img
                  src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/home/next2.png"
                />
              </div>
            </div>
          </div>
          <div class="flex flexSB mod">
            <div class="flex flexSB storyTitle">
              <div>咨询人生</div>
              <div class="flex" @click="toStory(1)">
                <img
                  class="img"
                  src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/home/next.png"
                />
                更多故事
              </div>
            </div>
            <div
              class="listData"
              v-for="(item1, index1) in consultLife"
              :key="index1"
              @click="toStory(1, item1)"
            >
              <img class="img" :src="item1.header" />
              <div class="listTitle" v-if="item1.author">
                {{ item1.author }}:{{ item1.title }}
              </div>
              <div class="listTitle" v-else>{{ item1.mes }}</div>
              <div class="flexS listStep">
                进一步了解
                <img
                  src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/home/next2.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-dialog
        class="serveDialog2"
        :visible.sync="dialogVisible2"
        :before-close="handleClose"
      >
        <div
          class="flex flexT flexSB"
          style="width: 100%; height: 100%"
          v-if="showCase"
        >
          <div class="caseMes">
            <div class="flexS">
              <div class="fontWeight s-dia-title">
                服务医院&nbsp;:&nbsp;{{ showCase.hospitalName }}
              </div>
            </div>
            <div class="flexS">
              <div class="fontWeight">医院等级&nbsp;:&nbsp;</div>
              {{ showCase.hosptialLevel }}
              <div class="fontWeight" style="margin-left: 60px">
                医院类别&nbsp;:&nbsp;
              </div>
              综合医院
              <!-- <div class="fontWeight">医院类别&nbsp;:&nbsp;</div>
              {{ showCase.projectName }} -->
            </div>
            <div class="flex s-dia-title2">项目名称</div>
            <div class="flex s-dia-title3">{{ showCase.projectName }}</div>
            <div class="flexS flexT achievement">
              <div style="font-weight: bold; color: #000; width: 80px">
                医院情况：
              </div>
              <div style="width: calc(100% - 80px)">
                {{ showCase.hosSituation }}
              </div>
            </div>
            <div class="achievement">
              <div class="flexS achievementData">
                <div class="achievementDataTitle">项目效果：</div>
                <div class="textCon" v-html="showCase.projectIntro"></div>
              </div>
            </div>
          </div>
          <img class="leftBg" :src="showCase.hospitalHeader" />
        </div>
      </el-dialog>
    </div>
    <mobileIndex
      class="mobile_index"
      :standingsList="standingsList"
      :team="team"
      :softArr="softArr"
      :informationData="informationData"
      :playerOptions="playerOptions1"
      @toCollege="toCollege"
      @toExpertItem="toExpertItem"
      @moreNew="moreNew"
      @topNewDetail="topNewDetail"
      @toTeamDetails="toTeamDetails"
    />
  </div>
  
</template>

<script>
import event from "../components/event.js";
import teamData from "../components/teamData.json";
import mobileIndex from '@/mobileViews/index.vue'
import img1 from '@/assets/img/modernImg1.png'
import img2 from '@/assets/img/modernImg2.png'
import img3 from '@/assets/img/modernImg3.png'
import img4 from '@/assets/img/modernImg4.png'
// import scrollReveal from "scrollreveal";
import {
  getNewsByGroup,
  getNewsByConsult,
  getNewsByIndustry,
  getModularList,
  getNewsByStory,
  getServeList,
} from "@/api/index";
import countTo from "vue-count-to";
export default {
  name: "home",
  // components: { countTo },
  components: { 
    countTo,
    mobileIndex
  },
  metaInfo: {
    title:
      "广州易策医管集团成立于2001年，旗下拥有4家医院管理咨询机构，专注于医院管理咨询、医管学术研究、医院内部管理与素质培训、医院托管与投资、医院精益运营信息技术与大数据服务。",
    meta: [
      {
        name: "keywords",
        content: "易策医管、易策咨询、医院管理咨询、易策健康、致和咨询",
      },
      {
        name: "description",
        content:
          "广州易策医管集团成立于2001年，旗下拥有4家医院管理咨询机构，专注于医院管理咨询、医管学术研究、医院内部管理与素质培训、医院托管与投资、医院精益运营信息技术与大数据服务。已服务全国600多家医院，凭借专业的理论知识、管理经验、信息技术及学科构建等，实现与医院之间的管理知识技术转移，推动中国医院的职业化管理进程，提升医院的职业化管理水平。",
      },
    ],
  },
  data() {
    let _this = this;
    return {
      // type:1是说明
      // type:2是数据，后面带+号
      standingsList: [
        { data: 2001, text: "创立公司", type: 1, icon: img3 },
        { data: 800, text: "服务公立医院", type: 2, icon: img2 },
        { data: 300, text: "覆盖城市", type: 2, icon: img1 },
        { data: 100, text: "医院长期管理顾问", type: 2, icon: img4 },
      ],
      team: teamData.team,
      swiperOption: {
        loop: true,
        speed: 1000,
        on: {
          slideChangeTransitionEnd: function () {
            _this.showTeamMes = _this.team[this.realIndex];
            // _this.swiperIndexChange(this.realIndex)
            _this.swiperIndex = this.realIndex;
          },

          click: function (e) {
            let obj = {};
            // console.log(e.target.src)
            if (e.target.src) {
              obj = _this.team.find((item) => {
                return item.img == e.target.src;
              });
            }
            console.log(obj);
            // let tbbsm = e.target.getAttribute("data-tbbsm"); // 获取自定义属性
            _this.toTeamDetails(obj); // 实现传参
          },
        },
        autoplay: true,
        slidesPerView: 5,
        // 自定义翻页按钮配置
        navigation: {
          prevEl: ".custom-button-prev", // 点击上一页
          nextEl: ".custom-button-next", // 点击下一张时
        },
        // 自定义翻页器配置
        pagination: {
          el: "nyll",
        },
      },
      informationData: [],
      informationData2: [],
      // 学术活动的视频配置
      playerOptions1: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        controls: true, //控制条
        preload: "none", //视频预加载
        muted: false, //默认情况下将会消除任何音频。
        loop: false, //导致视频一结束就重新开始。
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4",
            src: "http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/home/2024-xuanzhuanpian-new.mp4", //视频的地址，最好是放在服务器上
          },
        ],
        poster:
          "http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/home/homeVideo1.png", //视频封面地址（覆盖在视频上面的图片）
        width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
      },
      academicData: [
        {
          label: "公益讲堂",
          title: "疫情常态下的医院运营及绩效管理学术论坛（成都站）",
          time: "2021年9月17号",
          address: "四川.成都",
          lecturer: "程江  朱明汉  隋雪琼",
          url: "https://x.eqxiu.com/s/XQLW6OAy?eqrcode=1&share_level=1&from_user=20210913a129d8ee&from_id=80250898-2&share_time=1631494019634",
        },
        {
          label: "DIP学院",
          title: "DIP医院管理能力系统提升课程班（全国第一期）",
          time: "2021年4月9-10日",
          address: "广东.广州",
          lecturer: "陈亚光 程江等",
          url: "https://mp.weixin.qq.com/s/q4c2fgTXnsKLNPjQM_hyGw",
        },
        {
          label: "精选课程",
          title: "疫情常态下的医院运营及绩效管理学术论坛（上饶站）",
          time: "2021年4月23日",
          address: "江西.上饶",
          lecturer: "程江 朱明汉  王海霞",
          url: "https://mp.weixin.qq.com/s?__biz=MzA5OTk2MDczOA==&mid=2454913398&idx=2&sn=93b9cbfa69b2e7226d13e3088a918e1d&chksm=875df3bbb02a7aadb4b974d272b56cae35011d4e15ecd13c78e91965b120bcf9b058546ba945&token=1227463713&lang=zh_CN#rd",
        },
      ],
      consultLife: [],
      caseData: [],
      academyData: [
        {
          img: "",
          title: "医院管理必修课：现代医院绩效工资解决方案",
          mes: "10万人加入学习",
          period: 3,
          pay: false,
        },
        {
          img: "",
          title: "高质量医院门诊服务能力提升的策略和方法",
          mes: "10万人加入学习",
          period: 3,
          pay: false,
        },
        {
          img: "",
          title: "医院管理必修课：医院绩效管理策略和方法",
          mes: "10万人加入学习",
          period: 3,
          pay: false,
        },
      ],
      // 直播预告的视频配置
      playerOptions2: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        controls: true, //控制条
        preload: "none", //视频预加载
        muted: false, //默认情况下将会消除任何音频。
        loop: false, //导致视频一结束就重新开始。
        language: "zh-CN",
        aspectRatio: "1:1", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4",
            src: "http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/home/textVideo.mp4", //你所放置的视频的地址，最好是放在服务器上
          },
        ],
        poster:
          "http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/home/测试.jpg", //你的封面地址（覆盖在视频上面的图片）
        width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
      },
      liveData: [
        {
          id: 1,
          time: "2021-09-09 19:00-20:00",
          title:
            "基于大数据病种付费DIP应用于基金支付及广州市DIP医保支付机制策略与要点",
          lecturer: "宣建伟",
        },
        {
          id: 2,
          time: "2021-09-19 19:00-20:00",
          title: "高质量门诊医疗延伸服务能力提升——如何提高非医保收入",
          lecturer: "朱明汉",
        },
        {
          id: 3,
          time: "2021-09-29 19:00-20:00",
          title: "后疫情时期医院绩效管理的策略和方法——如何做激励促增长",
          lecturer: "程 江",
        },
      ],
      softIndex: -1, // 分公司切换列表
      softArr: [], // 存储后端一口气全部返回的数据
      softObj: {}, // 显示当前的对象元素
      inforIndex: -1, // 咨询中的tab按钮
      countToStart: false,
      showCase: undefined,
      dialogVisible2: false,
    };
  },
  created() {
    this.getList();
    this.softChangeTab(533);
    this.inforChangeTab(1);
  },
  mounted() {
    let that = this;
    event.$on("countToStart", () => {
      if (that.countToStart) {
        return;
      } else {
        that.countToStart = true;
        // console.log("我接收到了~~~~~~~~~~~~~");
        that.$refs.example0[0].start();
        that.$refs.example1[0].start();
        that.$refs.example2[0].start();
        that.$refs.example3[0].start();
      }
    });
  },
  methods: {
    getList() {
      // 易策故事
      getNewsByStory({ isPush: 1, pageNum: 1, pageSize: 2 }).then((res) => {
        if (res.code == 200) {
          this.consultLife = res.rows.slice(0, 2);
        }
      });
      // 服务案例
      getServeList({
        pageNum: 1,
        pageSize: 2,
      }).then((res) => {
        if (res.code == 200) {
          this.caseData = res.rows.slice(0, 2);
        }
      });
    },
    // 跳转专家页面
    toExpertItem() {
      this.$router.push({
        path: "/team",
        query: { activeName: 1 },
      });
    },
    // 跳转专家详情
    toTeamDetails(item) {
      this.$router.push({
        path: "/team",
        query: { userId: item.userId, activeName: item.activeName },
      });
    },
    // 跳转新闻详情
    topNewDetail(item) {
      this.$router.push({
        path: "/journalism",
        query: { activeName: item.typeId, newsId: item.newsId },
      });
    },
    //首页各个公司简介
    softChangeTab(i) {
      this.softIndex = i;
      if (this.softArr.length == 5) {
        let softObj = this.softArr.filter((item) => {
          return item.deptId == i;
        });
        this.softObj = softObj[0];
      } else {
        getModularList({ modularId: i }).then((res) => {
          if (res.code == 200) {
            this.softArr = res.rows;
            let softObj = res.rows.filter((item) => {
              return item.deptId == i;
            });
            this.softObj = softObj[0];
          }
        });
      }
    },
    // 咨询中心信息切换
    inforChangeTab(i) {
      if (this.inforIndex == i) {
        return;
      }
      if (i == 1) {
        // 集团动态(集团新闻)
        getNewsByGroup({ isPush: 1, pageNum: 1, pageSize: 4 }).then((res) => {
          if (res.code == 200) {
            this.informationData = res.rows.slice(0, 4);
          }
        });
      } else if (i == 2) {
        // 咨询动态(咨询动态)
        getNewsByConsult({ isPush: 1, pageNum: 1, pageSize: 4 }).then((res) => {
          if (res.code == 200) {
            this.informationData = res.rows.slice(0, 4);
          }
        });
      } else {
        // 行业资讯(政策与解读)
        getNewsByIndustry({ isPush: 1, pageNum: 1, pageSize: 4 }).then(
          (res) => {
            if (res.code == 200) {
              this.informationData = res.rows.slice(0, 4);
            }
          }
        );
      }
      this.inforIndex = i;
    },
    // 跳转资讯中心
    moreNew() {
      this.$router.push({ path: "/journalism" });
    },
    // 跳转外链
    toNewWindow(item) {
      if (item.url) {
        window.open(item.url, "_blank");
      }
    },
    // 跳转公司简介
    toCollege() {
      this.$router.push({
        path: "/college",
        query: { activeName: 1 },
      });
    },
    // 跳转易策故事汇
    toStory(i, item) {
      if (item) {
        this.$router.push({
          path: "/story",
          query: { activeName: i, newsId: item.newsId },
        });
      } else {
        this.$router.push({ path: "/story", query: { activeName: i } });
      }
    },
    // 跳转服务案例
    toServe(item) {
      if (item) {
        this.showCase = item;
        this.dialogVisible2 = true;
      } else {
        this.$router.push({ path: "/serve", query: { activeName: 1 } });
      }
    },
    // 关闭
    handleClose() {
      this.dialogVisible2 = false;
    },
    /** 打开简介pdf */
    openPDF() {
      window.open(
        "https://yice-prod.oss-cn-guangzhou.aliyuncs.com/other/%E5%B9%BF%E5%B7%9E%E6%98%93%E7%AD%96%E5%8C%BB%E7%AE%A1%E9%9B%86%E5%9B%A2%E4%BB%8B%E7%BB%8D.pdf"
      );
    },
  },
};
</script>

<style lang="scss">
.title {
  font-size: 28px;
  font-weight: bold;
  padding: 60px 0 10px 0;
}
.mes {
  display: inline-block;
  padding: 10px 12px;
  font-size: 18px;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-bottom: 40px;
  width: 600px;
  .el-divider--horizontal {
    margin: 0;
    .el-divider__text.is-center {
      width: 73%;
      font-size: 20px;
      font-weight: 400;
      color: #999999;
    }
  }
}
.expertMore {
  width: 140px;
  height: 38px;
  margin: 40px auto;
  background: inherit;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  cursor: pointer;
}

.expertMore:hover {
  background: #1b88cc;
  border: 1px solid #1b88cc;
  color: #fff;
}
.tabBtnList {
  margin-bottom: 40px;
  .tabBtn {
    padding: 0 20px;
    height: 40px;
    border-radius: 20px;
    color: #666;
    border: 1px solid #e5e5e5;
    font-size: 16px;
    font-weight: 400;
    margin: 0 15px;
    cursor: pointer;
  }
  .inforPitch {
    background-color: #1b88cc;
    border: 1px solid #1b88cc;
    color: #fff;
  }
}
.banner {
  width: 100%;
  height: 600px;
  // background-image: url("http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/home/bg0.png");
  background-image: url("../assets/img/bg0.png");
  background-size: 100%;
  background-repeat: no-repeat;
  .bannerText {
    width: 1200px;
    margin: 0 auto;
    padding-top: 200px;
    color: #fff;
    div:first-child {
      width: 345px;
      height: 52px;
      font-size: 40px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 13px;
    }
    div:last-child {
      width: 345px;
      height: 26px;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
.introduce {
  width: 1200px;
  height: 430px;
  margin: 55px auto;
  .introduceBg {
    width: 680px;
    height: 430px;
  }
  .introduce-right {
    width: 520px;
    padding: 50px;
    .introduce-title {
      font-size: 24px;
      font-weight: bold;
      color: #dc2c2a;
    }
    .introduce-rod {
      width: 30px;
      height: 2px;
      background: #a1a1a1;
      margin: 24px 0 32px 0;
    }
    .introduce-text {
      font-size: 16px;
      font-weight: 400;
      line-height: 29px;
      color: #666666;
    }
    .introduce-more {
      width: 57px; 
      height: 20px;
      margin-top: 10px;
      font-size: 14px;
      font-weight: 400;
      color: #1082c9;
      cursor: pointer;
      border-bottom: 1px solid #1082c9;
    }
    .downImgClass {
      cursor: pointer;
      width: 77px;
      height: 32px;
      margin-top: 40px;
      background-image: url("http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/dowmImg2.png");
      background-size: 100%;
    }
    .downImgClass:hover {
      background-image: url("http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/downImg.png");
    }
  }
}
.standings {
  width: 100%;
  height: 236px;
  // background-color: #f2f2f2;
  background-image: url("../assets/img/indexNum.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  // indexNum
  .standingsCon {
    width: 1200px;
    height: 100%;
    .list {
      width: 185px;
      color: #fff;
      text-align: center;
      .data {
        display: inline-block;
        font-weight: bold;
        font-size: 60px;
        border-bottom: 3px solid #fff;
        margin-bottom: 14px;
        .addIcon {
          font-size: 30px;
          padding-bottom: 8px;
        }
      }
      .h-text {
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
}
.software {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 60px;
  position: relative;
  .mes {
    .el-divider--horizontal {
      .el-divider__text.is-center {
        width: 44%;
      }
      .el-divider__text {
        background-color: #fff;
      }
    }
  }
  .softwareDiv {
    width: 1200px;
    height: 462px;
    margin: 0 auto;
    .soft-left {
      width: 382px;
      height: 162px;
      .soft-left-t {
        font-size: 24px;
        font-weight: 400;
        height: 31px;
        color: #333333;
        margin-bottom: 26px;
      }
      .soft-left-m {
        font-size: 16px;
        font-weight: 400;
        height: 21px;
        color: #1082c9;
        margin: 12px 0 26px 0;
      }
      .softIcon {
        width: 63px;
        height: 63px;
        display: block;
        margin-bottom: 26px;
      }
      .softMes {
        p {
          padding-left: 10px;
          position: relative;
        }
        p:after {
          position: absolute;
          z-index: 100;
          content: "";
          width: 9px;
          height: 9px;
          background: #007ac6;
          border-radius: 50%;
          top: 50%;
          left: -5px;
          transform: translateY(-50%);
        }
      }
    }
    .soft-right {
      width: 818px;
      height: 462px;
    }
  }
}
.expertItem {
  width: 100%;
  padding-bottom: 10px;
  background-color: #f5fbff;
  .el-divider--horizontal {
    .el-divider__text.is-center {
      width: 67%;
    }
    .el-divider__text {
      background-color: #f5fbff;
    }
  }
  .swiperDiv {
    width: 1200px;
    position: relative;
    margin: 0 auto;
    .swiper-container {
      height: 100%;
      .swiperCon {
        width: 230px;
        background-color: #fff;
        cursor: pointer;
        margin: 0 auto;
        .img {
          width: 230px;
          height: 339px;
          background-color: #fff;
          // border: 1px solid #dddcdb;
          // border-radius: 1px;
        }
        .expertPresentation {
          font-size: 20px;
          font-weight: bold;
          margin: 5px 0;
          .position {
            font-size: 15px;
            margin-left: 20px;
          }
        }
        .post {
          font-size: 16px; 
          padding-top: 5px; 
          padding-bottom: 18px; 
        }
        .step {
          padding: 0 8px; 
          font-size: 14px; 
          img {
            margin-left: 5px; 
          }
        }
      }
      .swiperCon:hover {
        background: #ecf7ff;
      }
    }
    .custom-button-prev {
      position: absolute;
      z-index: 10;
      top: 50%;
      left: -80px;
      transform: translateY(-50%);
      width: 48px;
      height: 48px;
      cursor: pointer;
      .prev {
        display: inline-block;
        height: 30px;
        width: 30px;
        border-width: 3px 3px 0 0;
        border-color: #1b88cc;
        border-style: solid;
        margin-left: 15px;
        -webkit-transform: matrix(-0.71, 0.71, 0.71, 0.71, 0, 0);
        transform: matrix(-0.71, 0.71, 0.71, 0.71, 0, 0);
      }
    }
    .custom-button-prev:hover {
      background-color: #1b88cc;
      .prev {
        border-color: #fff;
      }
    }

    .custom-button-next {
      position: absolute;
      z-index: 10;
      top: 50%;
      transform: translateY(-50%);
      right: -80px;
      width: 48px;
      height: 48px;
      cursor: pointer;
      .next {
        display: inline-block;
        height: 30px;
        width: 30px;
        border-width: 0 0 3px 3px;
        border-color: #1b88cc;
        border-style: solid;
        margin-right: 15px;
        -webkit-transform: matrix(-0.71, 0.71, 0.71, 0.71, 0, 0);
        transform: matrix(-0.71, 0.71, 0.71, 0.71, 0, 0);
      }
    }
    .custom-button-next:hover {
      background-color: #1b88cc;
      .next {
        border-color: #fff;
      }
    }
  }
}
.information {
  width: 100%;
  .el-divider--horizontal {
    .el-divider__text {
      background-color: #fff;
    }
  }
  .informationDiv {
    width: 1200px;
    margin: 0 auto;
    .informationList {
      position: relative;
    }
    div {
      width: 270px; 
      cursor: pointer;
    }
    .img {
      width: 270px; 
      height: 177px; 
      background-color: #f2f2f2;
    }
    .title1 {
      width: 100%;
      margin: 20px 0 5px 0; 
      font-size: 20px; 
      height: 30px; 
      font-weight: bold;
    }
    .digest {
      font-size: 16px; 
      color: #505050;
    }
    .time {
      margin: 10px 0 40px 0; 
      color: #505050;
    }
  }
  .informationDiv {
    .informationList:hover {
      ::after {
        content: "";
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        width: 270px;
        height: 177px;
        background-color: rgba(255, 255, 255, 0.1);
      }
      .title1 {
        color: #1b88cc;
      }
      .digest {
        color: #1b88cc;
      }
      .time {
        color: #1b88cc;
      }
    }
  }
}
.learning {
  width: 100%;
  height: 1300px;
  background-image: url("http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/home/learning.png");
  background-repeat: no-repeat;
  background-size: 100%;
  .learningDiv {
    width: 1200px;
    margin: 0 auto;
    .left {
      width: 600px;
      color: #fff;
      padding-top: 330px;
      .leftTitle {
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .leftDigest {
        margin: 20px 0;
        font-size: 13px;
      }
      .leftVideo {
        width: 460px;
        height: 258px;
        background-color: #fff;
        .textVideo1 {
          width: 100%;
          height: 100%;
          .vjs-big-play-button {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      .leftMes {
        margin: 30px 0;
        width: 530px;
      }
      .leftStep {
        font-size: 14px;
        img {
          margin-left: 6px;
        }
      }
    }
    .right {
      width: 600px;
      padding-top: 243px;
      .mod {
        width: 369px;
        height: 287px;
        background-color: #fff;
        // box-shadow:2px 0px 0px 0px rgba(240, 240, 240, 0.8);
        box-shadow: rgba(240, 240, 240, 0.5) 2px 2px 2px 2px;
        padding: 20px;
        margin-bottom: 50px;
        cursor: pointer;
        .modLabel {
          display: inline-block;
          color: #fff;
          background-color: #0c1442;
          padding: 3px 10px;
          border-radius: 3px;
        }
        .modTitle {
          font-size: 22px;
          font-weight: bold;
          color: #000;
          margin: 15px 0;
        }
        .modText {
          font-size: 13px;
          color: #000;
          margin-bottom: 5px;
        }
        .modText2 {
          font-size: 15px;
          color: #78787a;
          margin-top: 15px;
          img {
            margin-left: 10px;
            width: 16px;
            height: 16px;
          }
        }
      }
      .mod:nth-child(2) {
        margin-left: 200px;
      }
    }
  }
}
.story {
  width: 100%;
  padding-bottom: 120px;
  .el-divider--horizontal {
    .el-divider__text.is-center {
      width: 57%;
    }
    .el-divider__text {
      background-color: #fff;
    }
  }
  .storyDiv {
    width: 1200px;
    margin: 0 auto;
    .mod {
      width: 574px; 
      height: 410px; 
      padding: 25px; 
      flex-wrap: wrap;
      box-shadow: 0 0 10px #ddd;
      .storyTitle {
        width: 100%;
        font-size: 23px; 
        font-weight: bold;
        margin-bottom: 50px; 
        .flex {
          color: #89898a;
          font-size: 14px; 
          cursor: pointer;
          img {
            width: 14px; 
            height: 14px; 
          }
        }
      }
      .storyTitle:hover {
        .flex {
          color: #1b88cc;
        }
      }
      .listData {
        width: 250px; 
        height: 275px; 
        position: relative;
        cursor: pointer;
        .img {
          width: 100%;
          height: 165px; 
          background-color: #d6d4d4;
          margin-bottom: 30px; 
        }
        .listTitle {
          font-size: 17px; 
          height: 50px; 
          font-weight: bold;
        }
        .listStep {
          margin: 10px 0; 
          font-size: 13px; 
          color: #78787a;
          img {
            margin-left: 10px;
            width: 16px;
            height: 16px;
          }
        }
      }
      .listData:hover {
        ::after {
          content: "";
          position: absolute;
          z-index: 10;
          top: 0;
          left: 0;
          width: 100%;
          height: 165px;
          background-color: rgba(255, 255, 255, 0.1);
        }
        .listTitle {
          color: #1b88cc;
        }
        .listStep {
          color: #1b88cc;
        }
      }
    }
  }
}
.academy {
  width: 100%;
  padding-bottom: 50px; 
  .academyDiv {
    width: 1200px;
    margin: 0 auto;
    .list {
      width: 365px; 
      background-color: #f4f1f1;
      margin-bottom: 80px; 
      cursor: pointer;
      .img {
        width: 365px; 
        height: 272px; 
        background-color: skyblue;
      }
    }
    .title1 {
      padding: 20px 10px; 
      font-size: 20px; 
      height: 98px; 
      font-weight: bold;
    }
    .digest {
      font-size: 16px; 
      padding: 0 10px; 
      color: #505050;
    }
    .pay {
      color: #d10303;
      font-size: 22px; 
      font-weight: bold;
      padding: 15px 10px; 
    }
  }
}
.live {
  background-color: #007cc3;
  width: 100%;
  height: 820px;
  .foreshow {
    width: calc(100% - 820px);
    height: 820px;
    .foreshowMes {
      width: 733px;
      height: 100%;
      color: #fff;
      padding: 45px 20px;
      .foreshowTitle {
        margin: 40px 0;
        font-size: 33px;
        font-weight: bold;
      }
      .list {
        padding: 20px;
        margin-bottom: 30px;
        border: 1px solid #fff;
        .time {
          width: 80px; 
          font-size: 15px;
          margin-right: 50px;
          img {
            width: 22px;
            height: 22px;
            margin-bottom: 10px;
          }
        }
        .foreshowCon {
          width: 339px;
          .hidLin2 {
            font-weight: bold;
            margin-bottom: 16px;
          }
        }
        .appointment {
          width: 142px;
          height: 50px;
          border-radius: 25px;
          background-color: #fff;
          color: #007cc3;
          margin-left: 29px;
          cursor: pointer;
        }
      }
    }
  }
  .video2 {
    width: 820px;
    height: 820px;
    .textVideo2 {
      width: 100%;
      height: 100%;
      .vjs-big-play-button {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
.serveDialog2 {
  .el-dialog {
    width: 1200px; 
    height: 600px; 
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      padding: 50px 60px; 
      .leftBg {
        margin-top: 26px; 
        width: 464px; 
        height: 464px; 
      }
      .caseMes {
        width: 570px; 
        font-size: 16px; 
        .topLogo {
          padding: 30px 0; 
          img {
            width: 130px; 
          }
        }
        .fontWeight {
          font-weight: bold;
          color: #000;
          margin: 3px 0;
        }
        .s-dia-title {
          margin: 0;
          font-size: 20px;
          margin-bottom: 30px;
        }
        .s-dia-title2 {
          width: 100%;
          height: 50px;
          background-color: #0083cc;
          color: #fff;
          font-size: 20px;
          font-weight: bold;
          margin-top: 5px;
        }
        .s-dia-title3 {
          width: 100%;
          height: 60px;
          background-color: #f6f6f6;
          color: #606060;
          font-size: 20px;
          margin-bottom: 7px;
        }
        .blackBg {
          background-color: #000;
          width: 100%;
          height: 40px; 
          color: #fff;
          font-size: 20px; 
          font-weight: bold;
          margin-top: 20px; 
        }
        .hosMes {
          width: 100%;
          background-color: #fff;
          height: 104px; 
          position: relative;
          .hosMesList {
            width: 100%;
            text-align: center;
            p {
              font-weight: bold;
              font-size: 40px; 
              margin-bottom: 0;
              color: #000;
            }
            span {
              color: #b4b4b4;
            }
          }
        }
        .hosMes::after {
          position: absolute;
          z-index: 10;
          content: "";
          width: 2px; 
          height: 23px; 
          background-color: #000;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .achievement {
          padding-top: 10px; 
          .achievementData {
            position: relative;
            .achievementDataTitle {
              font-weight: bold;
              position: absolute;
              z-index: 5;
              font-size: 16px;
              left: 0;
              top: 5px;
              color: #000;
            }
            .textCon {
              text-indent: 5.8em;
            }
            p {
              color: #606266;
              margin: 0px;
              font-size: 16px;
            }
            div {
              font-size: 14px; 
              color: #333;
            }
          }
        }
      }
    }
  }
}

.mobile_index {
  display: none;
}
@media screen and (max-width: 650px) {
  .mobile_index {
    display: block;
    background-color: #fff;
  }
  .pc_index {
    display: none;
  }
}
</style>
