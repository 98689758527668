<template>
  <div class="flex flexT flexSB newCon">
    <div class="newList">
      <div
        class="flex flexSB lists emphasis"
        @click="topNewDetail(topNew.newsId)"
      >
        <img class="empImg" :src="topNew.header" />
        <div class="empCon">
          <div class="time">{{ topNew.sendTime }}</div>
          <div class="empTitle">
            {{ topNew.title }}
          </div>
          <div class="abstracts">
            <div>{{ topNew.subTitle }}</div>
          </div>
        </div>
      </div>
      <div class="flexS lists" v-for="(item, index) in newData" :key="index" @click="topNewDetail(item.newsId)">
        <div class="specialTime">
          <div class="flexE">{{ days(item.sendTime) }}</div>
          <div class="flex">{{ months(item.sendTime) }}</div>
        </div>
        <div class="listsCon">
          <div class="hidLin2 newListTitle">{{ item.title }}</div>
          <div class="hidLin2 newListDigest">{{ item.subTitle }}</div>
        </div>
      </div>
    </div>
    <div class="recommend">
      <img class="advertising" src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/journalism/journalismRightTop.png" alt="">
      <div class="moreMes">
        <div class="flex flexSB moreMesTitle">
          <div>精选资讯</div>
          <!-- <span>more ></span> -->
        </div>
        <div
          class="hidLin1 moreList"
          v-for="(item, index) in moreNewData.slice(0,6)"
          :key="index"
          @click="topNewDetail(item.newsId)"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "journalismMod",
  props: ["topNew", "newData", "moreNewData"],
  data() {
    return {};
  },
  methods: {
    days(time) {
      if (time) {
        return time.substring(8, 10);
      }
    },
    months(time) {
      if (time) {
        return time.substring(0, 7);
      }
    },
    topNewDetail(newsId) {
      this.$emit("topNewDetail", newsId);
    },
  },
};
</script>
<style lang="scss">
.newCon {
  width: 100%;
  padding: 40px 0;
  .newList {
    width: 765px;
    .lists {
      height: 185px;
      padding: 41px 0;
      border-bottom: 1px solid #b4b4b4;
      cursor: pointer;
      .specialTime {
        width: 125px;
        height: 102px;
        padding: 5px;
        background-color: #787878;
        color: #fff;
        font-size: 24px;
        font-weight: bold;
        .flexE {
          font-size: 32px;
          margin-top: 10px;
          padding-right: 10px;
        }
      }
      .listsCon {
        width: calc(100% - 125px);
        padding: 0 30px;
        height: 102px;
        .newListTitle {
          width: 100%;
          padding-bottom: 10px;
          font-size: 25px;
          font-weight: bold;
        }
        .newListDigest {
          color: #787878;
        }
      }
    }
    .lists:hover {
      border-bottom: 1px solid #007cc3;
      .specialTime {
        background-color: #007cc3;
      }
    }
    .emphasis {
      padding-top: 0;
      height: 245px;
      .empImg {
        width: 291px;
        height: 203px;
        background-color: skyblue;
      }
      .empCon {
        width: 435px;
        .time {
          font-size: 17px;
          font-weight: bold;
        }
        .empTitle {
          padding: 10px 0;
          font-size: 21px;
          font-weight: bold;
        }
        .abstracts {
          color: #787878;
        }
      }
    }
  }
  .recommend {
    width: 335px;
    .advertising {
      width: 100%;
    }
    .moreMes {
      margin-top: 10px;
      width: 100%;
      height: 357px;
      border: 2px solid #d9d9d9;
      border-radius: 2px;
      padding: 0 20px;
      .moreMesTitle {
        padding: 20px 0;
        border-bottom: 1px solid #d9d9d9;
        div {
          font-weight: bold;
          font-size: 18px;
        }
        span:hover {
          color: #007cc3;
        }
      }
      .moreList {
        width: 100%;
        margin: 23px 0;
        font-size: 16px;
        color: #787878;
        cursor: pointer;
      }
      .moreList:hover {
        color: #007cc3;
      }
    }
  }
}
</style>