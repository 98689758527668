<template>
  <div class="flex userPrincipal">
    <div class="flex flexSB my-content">
      <div class="my-menu">
        <div
          class="cu flex"
          :class="acMenu == 1 ? 'acMenuClass' : ''"
          @click="clickMenu(1)"
        >
          <img v-if="acMenu != 1" src="../assets/img/myTextIcon1.png" />
          <img v-else src="../assets/img/myTextIcon2.png" />
          我的文档
        </div>
        <div
          class="cu flex"
          :class="acMenu == 2 ? 'acMenuClass' : ''"
          @click="clickMenu(2)"
        >
          <img v-if="acMenu != 2" src="../assets/img/myVideoIcon1.png" />
          <img v-else src="../assets/img/myVideoIcon2.png" />
          我的视频
        </div>
        <div
          class="cu flex"
          :class="acMenu == 3 ? 'acMenuClass' : ''"
          @click="clickMenu(3)"
        >
          <img v-if="acMenu != 3" src="../assets/img/myCloudIcon1.png" />
          <img v-else src="../assets/img/myCloudIcon2.png" />
          我的上传
        </div>
      </div>
      <div class="my-con">
        <div class="my-BigTitle">
          {{ acMenu == 1 ? "我的文档" : acMenu == 2 ? "我的视频" : "我的上传" }}
        </div>
        <div class="flex flexSB my-explain" v-if="showExp">
          <div class="flex my-grade">
            <img src="../assets/img/grade.png" />
            我的等级:
            <div style="color: #1484cf">2级</div>
          </div>
          <div class="flex my-gradeExp">
            <div class="hidLin2 my-gradeExpText">
              <span style="font-weight: bold">权限说明：</span>
              {{ text }}
            </div>
            <el-popover placement="bottom-end" width="300" trigger="hover">
              <div>
                <span style="font-weight: bold">权限说明：</span>
                {{ text }}
              </div>
              <img
                class="cu"
                slot="reference"
                src="../assets/img/gradeExp.png"
              />
            </el-popover>
          </div>
        </div>
        <div class="my-conDiv">
          <user-Con2
            @changePattern="changePattern"
            @changePageNum="changePageNum"
            @changeUserModCollect="changeUserModCollect"
            :collectPat="collectPat"
            :queryParams="acMenu == 3 ? queryParams2 : queryParams"
            :sudoku="num"
            :sudokuVideo="videoNum"
            :showHead="showHead"
            :textObj="textObj2"
            :mh="mh"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import userCon2 from "@/components/userCon2";
import {
  getUserDocCollection,
  getUserVideoCollection,
  getDocumentList,
  getVideoList,
} from "@/api/index";
export default {
  metaInfo: {
    title:"广州易策医管集团成立于2001年，旗下拥有4家医院管理咨询机构，专注于医院管理咨询、医管学术研究、医院内部管理与素质培训、医院托管与投资、医院精益运营信息技术与大数据服务。",
    meta: [
      {
        name: "keywords",
        content:"易策,医管,易策咨询,致和咨询,医院管理,易策健康,绩效管理，高质量发展",
      },
      {
        name: "description",
        content:"广州易策医管集团成立于2001年，旗下拥有4家医院管理咨询机构，专注于医院管理咨询、医管学术研究、医院内部管理与素质培训、医院托管与投资、医院精益运营信息技术与大数据服务。已服务全国600多家医院，凭借专业的理论知识、管理经验、信息技术及学科构建等，实现与医院之间的管理知识技术转移，推动中国医院的职业化管理进程，提升医院的职业化管理水平。",
      },
    ],
  },
  components: { userCon2 },
  data() {
    return {
      acMenu: 1, //左边导航切换
      text: "您的账号进行用户登录的时候，我们对登录的设备进行二次认证，保障登录的设备是您授权的设备，我们可能会收集您的登录设备相关信息。您的账号进行用户登录的时候，我们对登录的设备进行二次认证，保障登录的设备是您授权的设备，我们可能会收集您的登录设备相关信息。",
      num: 6, // 非视频模式下的文档宫格布局，数量6
      videoNum: 9, // 视频模式下的布局，数量9
      collectPat: 0, // 我的收藏tabs项/搜索模式，0：初始化   1：文档   2：视频
      showExp: true,
      showHead: true,
      // 我的收藏 文档/视频
      queryParams: {
        createBy: JSON.parse(localStorage.getItem("user")).userId,
        pageNum: 1,
        pageSize: 10,
        deptId: null,
        pattern: 1,
      },
      // 我的上传 文档/视频
      queryParams2: {
        createBy: JSON.parse(localStorage.getItem("user")).userId,
        collectPat: 0, // 我的上传tabs项/搜索模式，0：初始化   1：文档   2：视频
        pageNum: 1,
        pageSize: 10,
        deptId: null,
        pattern: 1,
      },
      mh: 39, // user-Con2组件的高度，单位rem
      textObj2: {
        bgTitle: "文档",
        showBgTitle: true,
        data: [],
        total: 32,
      },
    };
  },
  created() {
    // 阻止非法进入
    let user = JSON.parse(localStorage.getItem('user')),that=this
    if(user){
      if(!user.userId){
        this.msgError('userId为空')
        setTimeout(()=>{
          this.$router.push('/login');
        },1000)
      }
    }else{
      this.$router.push('/login');
    }
    // 阻止非法未选择入口的情况进入详情
    let obj = JSON.parse(localStorage.getItem("entra"))[0];
    if(!obj.deptId){
      this.msgError('请选择公司入口再进入当前页面')
        setTimeout(()=>{
          this.$router.push('/entrance');
        },1000)
    }
    this.queryParams.deptId = obj.deptId;
    this.queryParams2.deptId = obj.deptId;
    this.clickMenu(1);
  },
  methods: {
    /** 左侧菜单栏 */
    clickMenu(e) {
      this.acMenu = e;
      // 展示文档
      if (e == 1) {
        this.collectPat = 0;
        this.queryParams.pageSize = 10;
        this.textObj2.showBgTitle = true;
        this.showHead = true;
        this.showExp = true;
        this.mh = 39;
        this.getColl(1);
      }
      if (e == 2) {
        // 展示视频
        this.collectPat = 2;
        this.queryParams.pageSize = 8;
        this.textObj2.showBgTitle = false;
        this.showHead = false;
        this.showExp = true;
        this.mh = 43;
        this.getColl(2);
      }
      if (e == 3) {
        this.collectPat = 0;
        if (this.queryParams2.collectPat) {
          this.collectPat = this.queryParams2.collectPat;
        } else {
          this.queryParams2.collectPat = this.collectPat;
        }
        this.textObj2.showBgTitle = false;
        this.showHead = true;
        this.showExp = false;
        this.mh = 46;
        if (this.queryParams2.collectPat == 2) {
          // if(this.queryParams2.pattern)
          this.getVid();
        } else {
          this.queryParams2.pageSize = 9;
          this.getDoc();
        }
      }
    },
    /** 我的收藏 */
    getColl(e) {
      let par =
        this.queryParams.createBy +
        "?pageNum=" +
        this.queryParams.pageNum +
        "&pageSize=" +
        this.queryParams.pageSize +
        "&deptId=" +
        this.queryParams.deptId;
      if (e == 1) {
        getUserDocCollection(par).then((res) => {
          this.textObj2.data = this.wash(res.rows, "doc");
          this.textObj2.total = res.total;
        });
      } else {
        getUserVideoCollection(par).then((res) => {
          this.textObj2.data = this.wash(res.rows, "vie");
          this.textObj2.total = res.total;
        });
      }
    },
    /** 文档数据的请求接口 */
    getDoc() {
      getDocumentList(this.queryParams2).then((res) => {
        if (res.code == 200) {
          this.textObj2.data = this.wash(res.rows, "doc");
          this.textObj2.total = res.total;
        }
      });
    },
    /** 视频数据的请求接口 */
    getVid() {
      getVideoList(this.queryParams2).then((res) => {
        if (res.code == 200) {
          this.textObj2.data = this.wash(res.rows, "vie");
          this.textObj2.total = res.total;
        }
      });
    },
    /** 子组件改变展示模式通知父组件变更布局方式 */
    changePattern(e) {
      if (this.acMenu == 3) {
        this.queryParams2.pattern = e;
        this.queryParams2.pageSize = 10;
        this.getDoc()
      } else {
        this.queryParams.pattern = e;
        if (e == 1) {
          this.queryParams.pageSize = 10;
        } else {
          this.queryParams.pageSize = 6;
        }
        this.getColl(this.acMenu)
      }
    },
    /** 子组件改变页码数通知父组件变更请求的参数 */
    changePageNum(e) {
      if (this.acMenu == 3) {
        this.queryParams2.pageNum = e.pageNum;
      } else {
        this.queryParams.pageNum = e.pageNum;
        this.getColl(e);
      }
    },
    /** 我的上传文档/视频切换 */
    changeUserModCollect(e) {
      if (this.acMenu == 3) {
        this.queryParams2.data = []
        this.queryParams2.collectPat = e;
        this.collectPat = e;
        if(e==2){
          this.queryParams2.pageSize == 9
          this.getVid()
        }else{
           this.queryParams2.pageSize == 10
           this.getDoc()
        }
      }
    },
    /** 将各个tabs的数据重新命名规划统一 */
    wash(data, type) {
      let d = [];
      // 文档
      if (type == "doc") {
        for (let i in data) {
          d.push({
            id: data[i].dataId,
            createTime: data[i].createTime,
            fileName: data[i].docName,
            url: data[i].docUrl,
            size: data[i].docSize,
          });
        }
      }
      // 视频
      if (type == "vie") {
        for (let i in data) {
          d.push({
            id: data[i].dataId,
            createTime: data[i].createTime,
            videoCover: data[i].videoCover,
            videoName: data[i].videoName,
            size: data[i].videoSize,
            url: data[i].videoUrl,
          });
        }
      }
      // 搜索-文档
      if (type == "selD") {
        for (let i in data) {
          d.push({
            id: data[i].dataId,
            createTime: data[i].createTime,
            videoCover: data[i].videoCover,
            fileName: data[i].selectFileName,
            size: data[i].selectFileSize,
            url: data[i].selectFileUrl,
          });
        }
      }
      // 搜索-视频
      if (type == "selV") {
        for (let i in data) {
          d.push({
            id: data[i].dataId,
            createTime: data[i].createTime,
            videoCover: data[i].selectFileCover,
            videoName: data[i].selectFileName,
            size: data[i].selectFileSize,
            url: data[i].selectFileUrl,
          });
        }
      }
      return d;
    },
  },
};
</script>
<style lang="scss">
.userPrincipal {
  width: 100%;
  height: 1000px;
  background-color: #f9fcff;
  position: relative;
  .my-content {
    width: 1200px;
    height: 100%;
    .my-menu {
      width: 250px;
      height: 100%;
      padding-top: 54px;
      background-color: #1e3a85;
      .flex {
        height: 54px;
        margin-bottom: 54px;
        font-size: 18px;
        font-weight: 400;
        color: #fff;
        img {
          margin-right: 10px;
        }
      }
      .acMenuClass {
        background-color: #384e8a;
        color: #a7bfff;
      }
      .flex:hover {
        background-color: #384e8a;
      }
    }
    .my-con {
      width: 920px;
      height: 100%;
      padding: 40px 0;
      .my-BigTitle {
        font-size: 26px;
        font-weight: 400;
        color: #666666;
        padding-left: 16px;
        position: relative;
      }
      .my-BigTitle::after {
        position: absolute;
        z-index: 5;
        content: "";
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 6px;
        height: 24px;
        background: #1484cf;
        border-radius: 3px;
      }
      .my-explain {
        width: 100%;
        height: 102px;
        background: #ffffff;
        box-shadow: 0px 3px 10px rgba(149, 149, 149, 0.16);
        border-radius: 6px;
        margin-top: 12px;
        position: relative;
        padding-left: 77px;
        .my-grade {
          //   width: 230px;
          height: 100%;
          font-size: 30px;
          font-weight: 400;
          color: #666666;
          img {
            margin-right: 10px;
          }
        }
        .my-gradeExp {
          height: 100%;
          padding-right: 60px;
          .my-gradeExpText {
            width: 462px;
            height: 40px;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: #999999;
          }
        }
      }
      .my-explain::after {
        position: absolute;
        z-index: 5;
        content: "";
        top: 50%;
        left: 347px;
        transform: translateY(-50%);
        width: 2px;
        height: 39px;
        background: #cecece;
      }
      .my-conDiv {
        margin-top: 20px;
        width: 920px;
        min-height: 740px;
        padding: 0 40px 20px 40px;
        background: #ffffff;
        box-shadow: 0px 3px 6px rgba(149, 149, 149, 0.16);
        border-radius: 6px;
      }
    }
  }
}
</style>