<template>
  <div>
    <div class="pc_college">
      <!-- 首页banner图 -->
      <div class="collegeBanner">
        <div>企 业 概 况</div>
      </div>
      <!-- 标签页 -->
      <div class="tabs">
        <div class="flex tabsTitle">
          <span>首页</span> > <span>关于易策</span>
        </div>
        <el-tabs class="tabsMod" v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="企业概况" name="1">
            <div class="situation">
              <div class="flex situationTitle">广州易策医管集团</div>
              <div class="textCss" v-if="official" v-html="official.intro"></div>
              <div class="carouselBg">
                <div class="carousel">
                  <div class="swiperDiv">
                    <swiper
                      :slides-per-view="3"
                      :space-between="50"
                      :options="swiperOption"
                      ref="mySwiper"
                    >
                      <swiper-slide
                        class="flex"
                        v-for="item in imageList"
                        :key="item.imageId"
                      >
                        <!-- <div class="flex swiperCon">
                          0
                        </div> -->
                        <img class="swiperCon" :src="item.imageUrl" />
                      </swiper-slide>
                    </swiper>
                    <div class="flex custom-button-prev">
                      <img
                        src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/college/prev.png"
                      />
                    </div>
                    <div class="flex custom-button-next">
                      <img
                        src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/college/next.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="mesList">
                <div
                  class="flex flexSB list"
                  v-for="(item, index) in listData"
                  :key="index"
                >
                  <div class="flexS c-listCon">
                    <div class="c-text" v-if="index % 2 == 0">
                      <div class="bigTitle">{{ item.bigTitle }}</div>
                      <div class="miniTitle">{{ item.miniTitle }}</div>
                    </div>
                    <img v-else :src="item.img" />
                  </div>
                  <div class="flexE c-listCon">
                    <img v-if="index % 2 == 0" :src="item.img" />
                    <div class="c-text" v-else>
                      <div class="flexE bigTitle">{{ item.bigTitle }}</div>
                      <div class="flexE miniTitle">{{ item.miniTitle }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="董事长寄语" name="2">
            <div class="situation2">
              <text-Details2 :initial="true" />
            </div>
          </el-tab-pane>
          <el-tab-pane label="发展历程" name="3">
            <div class="situation3">
              <el-timeline>
                <el-timeline-item
                  v-for="(activity, index) in activities"
                  :key="index"
                  :timestamp="activity.createTime"
                  :hide-timestamp="true"
                >
                  <div class="activityCon">
                    <div class="cycleTime">{{ activity.cycleTime }}</div>
                    <div>
                      <div class="cycleTitle">{{ activity.cycleTitle }}</div>
                      <div class="activityText">{{ activity.cycleIntro }}</div>
                    </div>
                  </div>
                </el-timeline-item>
              </el-timeline>
            </div>
          </el-tab-pane>
          <el-tab-pane label="组织架构" name="4">
            <div class="situation4">
              <div class="flex situation4Title">广州易策医管集团组织架构</div>
              <img
                class="img"
                src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/college/college41.png"
              />
            </div>
          </el-tab-pane>
          <!-- <el-tab-pane label="企业社会责任" name="5"> </el-tab-pane> -->
          <el-tab-pane label="集团荣誉" name="6">
            <div class="situation6">
              <div class="flex situation6Title">广州易策医管集团荣誉</div>
              <div class="flex flexSB honorList">
                <div
                  class="honorCredential"
                  v-for="(item, index) in honorList"
                  :key="index"
                >
                  <img class="img" :src="item.imageUrl" />
                  <div class="flex" style="text-align: center">
                    {{ item.imageName }}
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <mobileCollege
      class="mobile_college"
      :official="official"
      :imageList="imageList"
      :listData="listData"
      :activities="activities"
      :honorList="honorList"
      :activeName="activeName"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import textDetails2 from "@/components/textDetails2";
import mobileCollege from '@/mobileViews/college.vue'
import { getGeneralize, getImageList, getCycleList } from "@/api/index";
export default {
  name: "",
  metaInfo: {
    title:"广州易策医管集团成立于2001年，旗下拥有4家医院管理咨询机构，专注于医院管理咨询、医管学术研究、医院内部管理与素质培训、医院托管与投资、医院精益运营信息技术与大数据服务。",
    meta: [
      {
        name: "keywords",
        content:"易策医管、易策咨询、医院管理咨询、易策健康、致和咨询",
      },
      {
        name: "description",
        content:"广州易策医管集团成立于2001年，旗下拥有4家医院管理咨询机构，专注于医院管理咨询、医管学术研究、医院内部管理与素质培训、医院托管与投资、医院精益运营信息技术与大数据服务。已服务全国600多家医院，凭借专业的理论知识、管理经验、信息技术及学科构建等，实现与医院之间的管理知识技术转移，推动中国医院的职业化管理进程，提升医院的职业化管理水平。",
      },
    ],
  },
  components: { textDetails2, mobileCollege },
  computed: {
    ...mapState(["changeActiveName"])
  },
  watch: {
    changeActiveName(newData, oldData) {
      this.activeName = newData.toString()
    },
  },
  data() {
    return {
      activeName: "1",
      official: undefined,
      imageList: [],
      swiperOption: {
        loop: true,
        speed: 1000,
        on: {
          slideChangeTransitionEnd: function () {
            
          },
        },
        autoplay: false,
        initialSlide: 3,
        slidesPerView: 3,
        coverflow: {
          rotate: 0, //slide做3d旋转时Y轴的旋转角度。默认50。
          stretch: 100, //每个slide之间的拉伸值，越大slide靠得越紧。 默认0。
          depth: 150, //slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
          modifier: 1, //depth和rotate和stretch的倍率，相当于depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。默认1。
          slideShadows: false, //开启slide阴影。默认 true。
        },
        // 自定义翻页按钮配置
        navigation: {
          prevEl: ".custom-button-prev", // 点击上一页
          nextEl: ".custom-button-next", // 点击下一张时
        },
        // 自定义翻页器配置
        pagination: {
          el: "nyll",
        },
      },
      activities: [],
      listData: [
        {
          bigTitle: "我们的使命",
          miniTitle: "提升医院经营能力 惠泽群众健康生活",
          img: "http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/college/college11.png",
        },
        {
          bigTitle: "我们的愿景",
          miniTitle: "成为医院值得信赖的管理合作伙伴",
          img: "http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/college/college12.png",
        },
        {
          bigTitle: "我们的理念",
          miniTitle: "专业 ▪ 精细 ▪ 创新 ▪ 共赢",
          img: "http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/college/college13.png",
        },
        {
          bigTitle: "我们的目标",
          miniTitle: "医院管理咨询的引领者和创新者",
          img: "http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/college/college14.png",
        },
      ],
      honorList: [],
    };
  },
  created() {
    this.getList();
    let query = this.$route.query;
    if(query.activeName){
      this.activeName = query.activeName
    }
  },
  methods: {
    getList() {
      // 企业文案
      getGeneralize().then((res) => {
        if (res.code == 200) {
          this.official = res.rows[0];
        }
      });
      // 企业概括轮播图
      getImageList({ type: 1 }).then((res) => {
        if (res.code == 200) {
          this.imageList = res.rows;
        }
      });
      //集团荣誉
      getImageList({ type: 2 }).then((res) => {
        if (res.code == 200) {
          this.honorList = res.rows;
        }
      });
      // 公司发展历程
      getCycleList().then((res) => {
        if (res.code == 200) {
          this.activities = res.rows;
        }
      });
    },
    // 点击切换tabs
    handleClick() {
      // console.log(tab, event);
      this.detailsMod = false;
      let path = this.$router.history.current.path;
      window.history.pushState(
        null,
        "",
        path +
          "?" +
          "activeName=" +
          this.activeName
      );
    },
  },
};
</script>
<style lang="scss">
.collegeBanner {
  width: 100%;
  height: 400px;
  background-image: url("http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/college/bg1.png");
  background-repeat: no-repeat;
  // background-position: 0 -181px;
  background-size: 100%;
  font-size: 55px;
  font-weight: bold;
  padding: 123px 410px 60px;
  color: #fff;
}
.tabs {
  .tabsMod {
    .el-tabs__content {
      .situation {
        width: 100%;
        padding: 50px 0; 
        .situationTitle {
          font-size: 30px; 
          font-weight: bold;
          color: #007cc3;
          margin-bottom: 50px; 
        }
        .textCss {
          p {
            text-indent: 2em;
            margin-bottom: 40px; 
            padding: 0 115px; 
            font-size: 16px; 
          }
        }
        .carouselBg {
          position: relative;
          width: 100%;
          height: 484px;
          .carousel {
            position: absolute;
            z-index: 10;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 1903px;
            height: 454px;
            background-color: skyblue;
            .swiperDiv {
              width: 1320px;
              height: 100%;
              position: relative;
              margin: 0 auto;
              .swiper-container {
                height: 100%;
                .swiper-wrapper {
                  align-items: center;
                }
                .swiper-slide {
                  height: 281px;
                  transition: 1000ms;
                  transform: scale(0.7);
                  cursor: pointer;
                  .swiperCon {
                    width: 100%;
                    height: 100%;
                  }
                }
                .swiper-slide-next {
                  transform: scale(1);
                  .swiperCon{
                    height: auto;
                  }
                }
              }
            }
            .custom-button-prev {
              position: absolute;
              z-index: 10;
              top: 50%;
              left: -60px;
              transform: translateY(-50%);
              width: 48px;
              height: 48px;
              cursor: pointer;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .custom-button-next {
              position: absolute;
              z-index: 10;
              top: 50%;
              transform: translateY(-50%);
              right: -60px;
              width: 48px;
              height: 48px;
              cursor: pointer;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        .mesList {
          width: 1200px; 
          margin: 0 auto;
          .list {
            border-top: 1px solid #999;
            height: 382px;
            .c-listCon {
              width: 50%;
              height: 100%;
              flex-wrap: wrap;
              .c-text {
                .bigTitle {
                  font-weight: bold;
                  font-size: 30px;
                  margin-bottom: 34px;
                }
                .miniTitle {
                  font-size: 20px;
                  line-height: 1.9;
                }
              }
              img {
                width: 550px;
                height: 346px;
                border-radius: 12px;
              }
            }
          }
          .list:first-child {
            border: 0;
            .miniTitle {
              width: 180px;
            }
          }
        }
      }
      .situation2 {
        padding: 60px 0;
        flex-wrap: wrap;
      }
      .situation3 {
        width: 100%;
        padding: 60px 0; 
        // .el-timeline-item__content{
        //   position: relative;
        // }
        .activityCon {
          transform: translateY(-10px); 
          margin-bottom: 50px; 
          display: flex;
          align-items: baseline;
          padding-left: 40px; 
        }
        .cycleTime {
          font-size: 28px; 
          font-weight: bold;
          color: #007cc3;
          min-width: 100px;
        }
        .cycleTitle {
          padding-left: 60px; 
          font-size: 28px; 
          font-weight: bold;
          padding-left: 40px; 
          color: #007cc3;
        }
        .activityText {
          font-size: 16px; 
          margin-top: 20px; 
          margin-left: 40px; 
        }
      }
      .situation4 {
        width: 100%;
        padding-bottom: 130px;
        .situation4Title {
          color: #007cc3;
          font-weight: bold;
          font-size: 22px;
          padding: 40px 0 60px 0;
        }
        .img {
          width: 1115px; 
          height: 475px; 
          margin: 0 auto;
        }
      }
      .situation6 {
        width: 100%;
        padding-bottom: 130px;
        .situation6Title {
          color: #007cc3;
          font-weight: bold;
          font-size: 22px; 
          padding: 40px 0 60px 0;
        }
        .honorList {
          width: 100%;
          flex-wrap: wrap;
          .honorCredential {
            width: 380px; 
            padding: 40px; 
            margin-bottom: 30px; 
            background-color: #e6e6e6;
            .img {
              width: 300px; 
              height: 185px; 
            }
            .flex {
              margin-top: 20px; 
              align-items: flex-start;
              height: 50px; 
            }
          }
        }
      }
    }
  }
}
.mobile_college {
  display: none;
}
@media screen and (max-width: 650px) {
  .mobile_college {
    display: block;
    background-color: #fff;
  }
  .pc_college {
    display: none;
  }
}
</style>
