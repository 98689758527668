<template>
  <div>
    <!-- 首页banner图 -->
    <div class="storyBanner"></div>
    <!-- 标签页 -->
    <div class="tabs">
      <div class="flex tabsTitle">
        <span>首页</span> > <span>易策故事汇</span>
      </div>
      <el-tabs class="tabsMod" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="咨询人生" name="1">
          <div class="s-support" v-if="!showTextCon"></div>
          <div class="flex s-live" v-if="!showTextCon">
            <div class="s-video2">
              <video-player
                class="textVideo2"
                :options="playerOptions2"
              ></video-player>
              <!-- <img class="img" src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/story/gushihui001.jpg"> -->
            </div>
            <div class="flex foreshow">
              <div class="foreshowMes">
                <div class="flexS foreshowTitle">
                  在易策医管，我们所肩负的责任：
                </div>
                <div class="foreshowText">
                  不仅仅是帮助医院设计一个方案，而是让医院相关人员参与进来，通过赋能，传播一种科学合理的管理理念，为医院培养一支带不走的专业管理队伍，帮助和推动医院实现高质量发展，做医院管理者背后的智囊团，推动健康中国，助力全民美好生活。
                </div>
              </div>
            </div>
          </div>
          <div class="storyExample" v-if="!showTextCon">
            <div class="storyExampleTitle">
              <div class="flex">咨询人生</div>
              <div class="flex st-text">讲述易策人的咨询故事</div>
            </div>
            <div class="flexS flexT storyListCon">
              <div
                class="storyList"
                v-for="(item, index) in consultLife"
                :key="index"
                @click="toTextCon(item)"
              >
                <img class="img" :src="item.header" alt="" />
                <div class="hidLin2 storyListTitle">{{ item.title }}</div>
                <div class="hidLin3 storyListText">{{ item.subTitle }}</div>
                <div class="writer">作者：{{ item.author }}&nbsp;&nbsp;</div>
                <!-- 易策咨询&nbsp;&nbsp;咨询师 -->
              </div>
            </div>
          </div>
          <text-Details :textObj="textCon" v-if="showTextCon" />
        </el-tab-pane>
        <!-- <el-tab-pane label="咨询故事" name="2"> 
          <div class="s-support"></div>
          <div class="flex s-live">
            <div class="s-video2">
              <img class="img" src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/story/gushihui001.jpg">
            </div>
            <div class="flex foreshow">
              <div class="foreshowMes">
                <div class="flexS foreshowTitle">在易策医管，我们所肩负的责任：</div>
                <div class="foreshowText">不仅仅是帮助医院设计一个方案，而是让医院相关人员参与进来，通过赋能，传播一种科学合理的管理理念，为医院培养一只带不走的专业管理队伍，帮助和推动医院实现高质量发展，做医院管理者背后的智囊团，推动健康中国，助力全民美好生活。</div>
              </div>
            </div>
          </div>
          <div class="storyExample">
            <div class="storyExampleTitle">
              <div class="flex">咨询人生</div>
              <div class="flex text">讲述易策人的咨询故事</div>
            </div>
            <div class="flexS flexT flexSB storyListCon">
              <div class="storyList" v-for="(item,index) in 3" :key="item">
                <div class="img"></div>
                <div class="storyListTitle">心中的白月光{{index}}</div>
                <div class="storyListText">在我从事医管咨询的日子里，收获最大的其实是具有广度和宽度的“视野”和“格局”</div>
                <div class="writer">作者：周国泉&nbsp;&nbsp;易策咨询&nbsp;&nbsp;咨询师</div>
              </div>
            </div>
          </div>
        </el-tab-pane> -->
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import textDetails from "@/components/textDetails";
import { getNewsByStory, getNewsDetail } from "@/api/index";
export default {
  name: "story",
  metaInfo: {
    title:"广州易策医管集团成立于2001年，旗下拥有4家医院管理咨询机构，专注于医院管理咨询、医管学术研究、医院内部管理与素质培训、医院托管与投资、医院精益运营信息技术与大数据服务。",
    meta: [
      {
        name: "keywords",
        content:"易策,医管,易策咨询,致和咨询,医院管理,易策健康,绩效管理，高质量发展",
      },
      {
        name: "description",
        content:"广州易策医管集团成立于2001年，旗下拥有4家医院管理咨询机构，专注于医院管理咨询、医管学术研究、医院内部管理与素质培训、医院托管与投资、医院精益运营信息技术与大数据服务。已服务全国600多家医院，凭借专业的理论知识、管理经验、信息技术及学科构建等，实现与医院之间的管理知识技术转移，推动中国医院的职业化管理进程，提升医院的职业化管理水平。",
      },
    ],
  },
  components: { textDetails },
  computed: {
    ...mapState(["changeActiveName"])
  },
  watch: {
    changeActiveName(newData, oldData) {
      this.activeName = newData.toString()
    },
  },
  data() {
    return {
      activeName: "1",
      showTextCon: false,
      consultLife: [],
      textCon: {},
      // 直播预告的视频配置
      playerOptions2: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        controls: true, //控制条
        preload: "none", //视频预加载
        muted: false, //默认情况下将会消除任何音频。
        loop: false, //导致视频一结束就重新开始。
        language: "zh-CN",
        aspectRatio: "1178:660", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4",
            src: "http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/story/storyVideo.mp4", //你所放置的视频的地址，最好是放在服务器上
          },
        ],
        poster:
          "http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/story/storyVideoCover.png", //你的封面地址（覆盖在视频上面的图片）
        width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
      },
    };
  },
  created() {
    let query = this.$route.query;
    if (query.activeName) {
      this.activeName = query.activeName;
    }
    this.getList();
    if(query.newsId){
      this.toTextCon({newsId:query.newsId})
    }
    
  },
  methods: {
    getList() {
      getNewsByStory().then((res) => {
        if (res.code == 200) {
          this.consultLife = res.rows;
        }
      });
    },
    handleClick() {
      this.showTextCon = false;
      let path = this.$router.history.current.path;
      window.history.pushState(
        null,
        "",
        path +
          "?" +
          "activeName=" +
          this.activeName
      );
    },
    toTextCon(item) {
      getNewsDetail(item.newsId).then((res) => {
        if (res.code == 200) {
          this.textCon = {
            title:res.data.title,
            author:res.data.author,
            intro:res.data.intro
          };
          this.showTextCon = true;
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        }
      });
    },
  },
};
</script>
<style lang="scss">
.storyBanner {
  width: 100%;
  height: 400px;
  background-image: url("http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/story/bg6.png");
  background-repeat: no-repeat;
  background-position: 0 -123px;
  background-size: 100%;
}
.s-support {
  width: 100%;
  height: 594px;
  margin: 60px 0;
  position: relative;
}
.s-live {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100vw - 17px);
  height: 594px;
  background-color: #d1e7f4;
  .s-video2 {
    width: 1060px;
    height: 594px;
    .img {
      width: 100%;
      height: 100%;
    }
    .textVideo2 {
      width: 100%;
      height: 100%;
      .vjs-big-play-button {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .foreshow {
    width: calc(100% - 1060px);
    height: 594px;
    .foreshowMes {
      width: 100%;
      color: #000;
      padding: 0 170px 0 50px;
      flex-wrap: wrap;
      .foreshowTitle {
        width: 100%;
        font-weight: bold;
        font-size: 26px;
        margin-bottom: 50px;
      }
      .foreshowText {
        width: 100%;
        font-size: 21px;
        line-height: 2;
      }
    }
  }
}
.storyExample {
  width: 1200px;
  .storyExampleTitle {
    width: 265px;
    margin: 0 auto;
    font-size: 36px;
    font-weight: bold;
    position: relative;
    flex-wrap: wrap;
    margin-bottom: 80px;
    div {
      width: 100%;
    }
    .st-text {
      margin-top: 15px;
      font-size: 15px;
    }
  }
  .storyExampleTitle::after {
    position: absolute;
    left: 6px;
    top: 30%;
    transform: translateY(50%);
    z-index: 10;
    content: "";
    width: 30px;
    height: 3px;
    background-color: #000;
  }
  .storyExampleTitle::before {
    position: absolute;
    right: 6px;
    top: 30%;
    transform: translateY(50%);
    z-index: 10;
    content: "";
    width: 30px;
    height: 3px;
    background-color: #000;
  }
  .storyListCon {
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 60px;
    .storyList {
      width: 365px;
      height: 497px;
      margin-bottom: 52.5px;
      background-color: #f7f7f7;
      cursor: pointer;
      .img {
        width: 100%;
        height: 249px;
        background-color: skyblue;
      }
      .storyListTitle {
        width: 100%;
        font-size: 25px;
        font-weight: bold;
        height: 88px;
        padding: 20px 20px;
      }
      .storyListText {
        width: 100%;
        font-size: 15px;
        height: 68px;
        margin-top: 20px;
        padding: 0 20px;
        color: #787878;
      }
      .writer {
        padding: 20px 20px;
        font-size: 16px;
        font-weight: bold;
      }
    }
    .storyList:nth-child(3n+2){
      margin-left: 52px;
      margin-right: 52px;
    }
  }
}
</style>