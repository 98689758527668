<template>
  <div id="containerMap"></div>
</template>

<script>
  export default {
    mounted() {
      //定义地图中心点坐标
      let center = new window.TMap.LatLng(22.978882, 113.374629);
      //定义map变量，调用 TMap.Map() 构造函数创建地图
      let map = new window.TMap.Map(document.getElementById("containerMap"), {
        center: center, //设置地图中心点坐标
        zoom: 16.8, //设置地图缩放级别
        viewMode: "3D",
        disableDefaultUI: true,
      });
      //创建并初始化MultiMarker
      var markerLayer = new window.TMap.MultiMarker({
        map: map, //指定地图容器
        //样式定义
        styles: {
          //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
          myStyle: new window.TMap.MarkerStyle({
            width: 24, // 点标记样式宽度（像素）
            height: 24, // 点标记样式高度（像素）
            src: require("../assets/img/address.png"), //图片路径
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            anchor: { x: 16, y: 16 },
          }),
        },
        //点标记数据数组
        geometries: [
          {
            id: "1", //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
            styleId: "myStyle", //指定样式id
            position: center, //点标记坐标位置
            properties: {
              //自定义属性
              title: "marker1",
            },
          },
        ],
      });
    }
  }
</script>
