<template>
  <div>
    <div class="m_banner">
      <div class="m_banner_text">关于我们</div>
      <div class="m_banner_i"></div>
      <div class="m_banner_text2">专注医管服务20余年</div>
    </div>
    <div class="tabs_m">
      <div :class="['tabs_item', activeName == 1 ? 'is_tabs' : '']" @click="setActiveFun(1)">企业概况</div>
      <div :class="['tabs_item', activeName == 2 ? 'is_tabs' : '']" @click="setActiveFun(2)">董事长寄语</div>
      <div :class="['tabs_item', activeName == 3 ? 'is_tabs' : '']" @click="setActiveFun(3)">发展历程</div>
      <div :class="['tabs_item', activeName == 4 ? 'is_tabs' : '']" @click="setActiveFun(4)">组织架构</div>
      <div :class="['tabs_item', activeName == 6 ? 'is_tabs' : '']" @click="setActiveFun(6)">集团荣誉</div>
    </div>
    <div>
      <div v-if="activeName == 1">
        <div style="padding-top: 10px">
          <div class="index_title">
            <span class="index_title_before"></span>
            <span class="index_title_span">易策简介</span>
            <span class="index_title_after"></span>
          </div>
          <div class="college_m_intro" v-html="official?.intro"></div>
        </div>
        <div class="college_img_box">
          <div class="college_img_item" v-for="(item, index) in imageList" :key="index">
            <img :src="item.imageUrl" alt="">
          </div>
        </div>
        <div class="college_m_sss">
          <div class="college_m_sss_item" v-for="(item, index) in listData" :key="index">
            <img class="college_m_sss_img" :src="item.img" alt="">
            <div class="college_m_sss_title">{{ item.bigTitle }}</div>
            <div class="college_m_sss_text">{{ item.miniTitle }}</div>
          </div>
        </div>
      </div>
      <div v-else-if="activeName == 2">
        <div class="college_m_send_word">
          <img class="college_m_send_word_img" src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/college/college21.png" />
          <div class="college_m_send_word_name">广州易策医管集团董事长：程江</div>
          <div class="college_m_send_word_text" v-for="(item, index) in defaultData.data" :key="index">{{ item }}</div>
        </div>
      </div>
      <div v-else-if="activeName == 3">
        <div class="college_m_evolution">
          <el-timeline>
            <el-timeline-item
              v-for="(activity, index) in activities"
              :key="index"
              :timestamp="activity.createTime"
              :hide-timestamp="true"
              color="#1E61FF"
            >
              <div class="college_m_evolution_activityCon">
                <div class="college_m_evolution_cycleTime">{{ activity.cycleTime }}</div>
                <div class="college_m_evolution_cycleTitle">{{ activity.cycleTitle }}</div>
                <div class="college_m_evolution_activityText">{{ activity.cycleIntro }}</div>
              </div>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
      <div v-else-if="activeName == 4">
        <div class="college_m_organization">
          <img src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/college/college41.png" />
          <div class="college_m_organization_but">
            <span @click="is_big = true">点击查看大图</span>
          </div>
          <div class="college_m_organization_big" v-if="is_big" @click="is_big = false">
            <!-- <span class="college_m_organization_close" @click="is_big = false">×</span> -->
            <img src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/college/college41.png" />
          </div>
        </div>
      </div>
      <div v-else>
        <div class="college_m_honor">
          <div class="college_m_honor_item" v-for="(item, index) in honorList" :key="index">
            <img class="college_m_honor_img" :src="item.imageUrl" />
            <div class="college_m_honor_name">{{ item.imageName }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['official', 'imageList', 'listData', 'activities', 'honorList'],
    data() {
      return {
        activeName: 1,
        defaultData: {
          title: "【易行于策】拼搏新征程，奋进新时代",
          data: [
            '一元复始，万象更新。',
            '在这律回春渐，新元肇启之时，谨向所有广州易策医管科技、易策咨询、易策研究发展院和致和咨询的家人们和伙伴们恭贺新年，向大家道一声：新年快乐，健康平安，阖家幸福！',
            '过去一年，疫情肆虐，我们的工作和生活环境发生了巨大变化。这一年里，疫情没有阻挡住大家前进的脚步，依然披荆斩棘一路前行。今天，走在街头，发现一切美好都在悄然而至，虽然也面临诸多困难与挑战，但正如习近平总书记所说：艰难方显勇毅，磨砺始得玉成。',
            '2022年，易策医管始终践行“提升医院经营能力，惠泽群众健康生活”的使命。发挥专家团队作用，创新发展理念，助力医院高质量发展。开展前瞻性研究，积极探索现代医院管理改革发展的新路径、新方法、新机制。加强精细化管理和信息化建设，踔厉奋发，勇毅前行。研发新咨询项目实现创新和突破，获得了市场赞赏与认同。我们的团队日趋成熟，优质服务深入人心，竞争力持续提升，成为医院值得信赖的管理合作伙伴。',
            '对易策医管来说，价值体现比数字增长要来的更有意义，用真本事和硬实力去帮助我们的医院解决管理和运营的难题，才是易策医管的价值所在。',
            '易策咨询公司2022年为多达百家医院提供了绩效管理咨询服务，易策EHP医院绩效软件系统已在全国百家医院上线，包括成都医学院第一附属医院、广西医科大学附属柳铁中心医院、广州中医药大学第三附属医院、宁波市中医院、北京中医药大学东直门医院等。',
            '原台湾长庚医院品管中心主任陈丽红老师的加盟，更是推动精益管理在医院的实施和应用，“精益化管理助推医院高质量发展——强化巩固国考指标”咨询服务获得服务医院的一致好评。',
            '易策医管研究发展院吸纳医疗行业的学科专家、管理专家、品牌专家等，推动医院及科室发展的新理念，其妇产科运营能力提升项目在广州医科大学附属顺德医院、重庆市渝北区第二人民医院等多家医院取得骄人成绩，医院业务推广及市场辅导项目在武威市肿瘤医院落地，帮助医院及科室的运营数据大幅度提升。',
            '致和咨询公司不断优化和提升等级评审辅导、优质服务能力提升等项目方案，帮助惠城区中医院等通过医院等级评审，推动的南海区第四人民医院樵山灯塔自愿者服务项目获得佛山市创新成果优秀奖，服务过的医院纷纷续约。',
            '易策医管科技公司专注于医院管理信息化建设，吸纳行业内精英，积极推动DIP/DRG精细化管理系统、医保智能审核系统、国考指标管理系统等顺利在定西市第二人民医院、邵东市人民医院、平果市人民医院的落地。',
            '除此之外，易策医管集团积极投身于社会公益活动，易策医管集团公益基金开展了河南郑州特大洪水捐赠活动，参与了龙越慈善基金会发起的抗战老兵贫困帮扶公益计划，力所能及的承担起社会责任。',
            '最后，致敬每一位易策人，感谢你们的奉献，尤其是在一线奔波的咨询老师们，明知前路难行，依然勇往直前；致敬易策医管的每一个合作伙伴，正是有你们的信任和支持，我们才能坚守不变的信念，易策人将不负大家的系托，一起携手推动健康事业发展。',
            '“易”智慧，“策”未来，在新的起点上，全体易策人要积极作为、团结协作、携手共进、为明天继续撸起袖子加油干！'
          ],
        },
        is_big: false
      }
    },
    created() {
      let query = this.$route.query;
      if(query.activeName){
        this.activeName = query.activeName
      }
    },
    methods: {
      setActiveFun(num) {
        this.activeName = num
      }
    }
  }
</script>

<style lang="scss">
  .college_m_intro {
    padding: 37px 50px;
    p {
      font-size: 26px;
      font-weight: 400;
      color: #333333;
      line-height: 45px;
    }
  }
</style>
<style lang="scss" scoped>
  .m_banner {
    width: 100%;
    height: 515px;
    background-image: url("../assets/img/mobile_bg2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .m_banner_text {
      font-size: 32px;
      color: #fff;
      text-align: center;
      padding-top: 132px;
    }
    .m_banner_i {
      width: 45px;
      height: 6px;
      background-color: #fff;
      margin: 20px auto 16px;
    }
    .m_banner_text2 {
      font-size: 40px;
      color: #fff;
      text-align: center;
    }
  }
  .tabs_m {
    display: flex;
    background-color: #F5F6F8;
    padding: 0 37px;
    flex-wrap: wrap;
    .tabs_item {
      width: 33.33%;
      height: 95px;
      line-height: 95px;
      font-size: 28px;
      font-weight: 400;
      text-align: center;
      color: #333333;
      border-bottom: 4px solid transparent;
      &:nth-child(4) {
        width: 50%;
      }
      &:nth-child(5) {
        width: 50%;
      }
    }
    .is_tabs {
      background: #fff;
      border-bottom: 4px solid #1E61FF;
    }
  }

  .college_img_box {
    padding: 0 50px;
    overflow-x: auto;
    height: 360px;
    display: flex;
    .college_img_item {
      width: 546px;
      height: 360px;
      margin-right: 26px;
      img {
        width: 546px;
        height: 360px;
      }
    }
  }

  .college_m_sss {
    padding: 37px;
    .college_m_sss_item {
      text-align: center;
      padding-bottom: 39px;
      .college_m_sss_img {
        width: 305px;
        height: 305px;
        border-radius: 100%;
        overflow: hidden;
      }
      .college_m_sss_title {
        font-size: 36px;
        font-weight: bold;
        color: #1E61FF;
        padding: 36px 0 18px 0;
      }
      .college_m_sss_text {
        font-size: 30px;
        font-weight: 400;
        color: #333333;
      }
    }
  }

  .college_m_send_word {
    padding: 50px;
    .college_m_send_word_img {
      width: 100%;
    }
    .college_m_send_word_name {
      font-size: 26px;
      font-weight: bold;
      color: #333333;
      text-align: center;
      margin: 23px 0;
    }
    .college_m_send_word_text {
      font-size: 26px;
      font-weight: 500;
      color: #333333;
      line-height: 45px;
      padding-bottom: 22px;
    }
  }
  .college_m_evolution {
    padding: 50px 50px 50px 100px;
    ::v-deep(.el-timeline-item__wrapper) {
      position: relative;
    }
    .college_m_evolution_cycleTime {
      position: absolute;
      top: 0;
      left: -100px;
      font-size: 26px;
      font-weight: bold;
      color: #1E61FF;
    }
    .college_m_evolution_cycleTitle {
      font-size: 26px;
      font-weight: bold;
      color: #1E61FF;
    }
    .college_m_evolution_activityText {
      font-size: 26px;
      font-weight: 400;
      color: #333333;
      line-height: 45px;
      margin: 20px 0;
    }
  }
  .college_m_organization {
    padding: 50px;
    img {
      width: 100%;
    }
    .college_m_organization_but {
      font-size: 24px;
      font-weight: 400;
      color: #6D6D6D;
      text-align: center;
      padding-top: 30px;
    }
    .college_m_organization_big {
      background: rgb(0 0 0 / 53%);
      z-index: 200;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;
      img {
        width: 150%;
        margin-top: 40%;
      }
      .college_m_organization_close {
        color: #fff;
        font-size: 50px;
        float: right;
        margin: 20px;
      }
    }
  }
  .college_m_honor {
    padding: 50px 26px;
    .college_m_honor_item {
      text-align: center;
      background: #FFFFFF;
      box-shadow: 0px 3px 16px 1px rgba(228,228,228,0.41);
      border-radius: 4px;
      border: 1px solid #CFCFCF;
      margin-bottom: 30px;
      padding: 48px 0;
      .college_m_honor_img {
        width: 508px;
        height: 336px;
      }
      .college_m_honor_name {
        font-size: 28px;
        font-weight: bold;
        color: #333333;
        margin-top: 49px;
      }
    }
  }
  .index_title {
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    color: #000;
    margin: 0 auto;
    .index_title_span {
      margin: 0 20px;
    }
    .index_title_after {
      display: inline-block;
      width: 107px;
      height: 23px;
      background-image: url("../assets/img/titleIcon1.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transform: rotate(180deg);
    }
    .index_title_before {
      display: inline-block;
      width: 107px;
      height: 23px;
      background-image: url("../assets/img/titleIcon1.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
</style>
