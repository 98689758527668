<template>
  <div>
    <div class="pc_journalism">
      <!-- 首页banner图 -->
      <div class="newsBanner"></div>
      <!-- 标签页 -->
      <div class="tabs">
        <div class="flex tabsTitle">
          <span>首页</span> > <span>新闻中心</span>
        </div>
        <el-tabs class="tabsMod" v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="集团动态" name="1">
            <journalism-Mod
              v-if="!detailsMod"
              :topNew="topNew"
              :newData="newData"
              :moreNewData="moreNewData"
              @topNewDetail="topNewDetail"
            />
            <text-Details :textObj="textObj" v-if="detailsMod" />
            <div class="flex" v-if="!detailsMod && moreNew">
              <div class="flex more" @click="getmoreNew">查看更多新闻</div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="咨询动态" name="2">
            <journalism-Mod
              v-if="!detailsMod"
              :topNew="topNew"
              :newData="newData"
              :moreNewData="moreNewData"
              @topNewDetail="topNewDetail"
            />
            <text-Details :textObj="textObj" v-if="detailsMod" />
            <div class="flex" v-if="!detailsMod && moreNew">
              <div class="flex more" @click="getmoreNew">查看更多新闻</div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="行业资讯" name="4">
            <journalism-Mod
              v-if="!detailsMod"
              :topNew="topNew"
              :newData="newData"
              :moreNewData="moreNewData"
              @topNewDetail="topNewDetail"
            />
            <text-Details :textObj="textObj" v-if="detailsMod" />
            <div class="flex" v-if="!detailsMod && moreNew">
              <div class="flex more" @click="getmoreNew">查看更多新闻</div>
            </div>
          </el-tab-pane>
          <!-- <el-tab-pane label="易策故事汇" name="10">
            
          </el-tab-pane> -->
        </el-tabs>
      </div>
    </div>
    <mobileJournalism
      class="mobile_journalism"
      :mobileData="mobileData"
      @getmoreNew="getmoreNew"
      @getList="getList"
    />
  </div>
  
</template>
<script>
import { mapState } from "vuex";
import journalismMod from "@/components/journalismMod";
import textDetails from "@/components/textDetails";
import mobileJournalism from '@/mobileViews/journalism.vue'
import {
  getNewsDetail,
  getNewsByGroup,
  getNewsByConsult,
  getNewsByIndustry,
} from "@/api/index";
export default {
  name: "journalism",
  metaInfo: {
    title:
      "广州易策医管集团成立于2001年，旗下拥有4家医院管理咨询机构，专注于医院管理咨询、医管学术研究、医院内部管理与素质培训、医院托管与投资、医院精益运营信息技术与大数据服务。",
    meta: [
      {
        name: "keywords",
        content: "绩效、信息化、管理软件、门诊、医院、战略规划、质量管理、成本",
      },
      {
        name: "description",
        content:
          "广州易策医管集团成立于2001年，旗下拥有4家医院管理咨询机构，专注于医院管理咨询、医管学术研究、医院内部管理与素质培训、医院托管与投资、医院精益运营信息技术与大数据服务。已服务全国600多家医院，凭借专业的理论知识、管理经验、信息技术及学科构建等，实现与医院之间的管理知识技术转移，推动中国医院的职业化管理进程，提升医院的职业化管理水平。",
      },
    ],
  },
  components: { journalismMod, textDetails, mobileJournalism },
  computed: {
    ...mapState(["changeActiveName"]),
  },
  watch: {
    changeActiveName(newData, oldData) {
      console.log(newData);
      console.log(oldData);
      this.activeName = newData.toString();
      this.getList({ typeId: newData.toString() });
    },
    deep: true,
  },
  data() {
    return {
      activeName: "1",
      detailsMod: false, // true:新闻详情模式    false:新闻列表展示模式
      textObj: {},
      moreNew: true, // 显示更多新闻的按钮
      topNew: {},
      newData: [],
      moreNewData: [],
      mobileData: [],
      queryParams: {
        pageNum: 1,
        pageSize: 3,
      },
      // 更多more新闻初始六条
      moreQueryParams: {
        pageNum: 1,
        pageSize: 6,
      },
    };
  },
  created() {
    let query = this.$route.query;
    if (query.activeName) {
      this.activeName = query.activeName;
      this.getList();
      if (query.newsId) {
        this.topNewDetail(query.newsId);
      }
    } else {
      this.getList();
    }
  },
  methods: {
    getList(num) {
      let activeName = num ? num : this.activeName,
        par = {},
        moreNew = this.moreNew,
        topNew = {},
        newData = [],
        moreNewData = [];
      // 用户在点击查看更多新闻的时候，moreNew的值才会为false(查看更多新闻)的按钮才会消失掉
      if (moreNew == false) {
        par.isTop = 0;
        par.pageSize = 1000;
        par.pageNum = 1;
      }

      // 集团动态
      if (activeName == "1") {
        getNewsByGroup(par).then((res) => {
          if (res.code == 200) {
            let data = res.rows;
            let topNew = data.find((item) => item.isTop == 1); // 获取第一个符合条件的元素
            console.log(topNew);
            for (let i in data) {
              // if (data[i].isTop == 1) {
              //   topNew = data[i];
              // }
              if (data[i].isTop == 0) {
                newData.push(data[i]);
              }
              if (data[i].isTop == 0) {
                moreNewData.push(data[i]);
              }
            }
            console.log(topNew);
            if (moreNew) {
              this.newData = newData.slice(0, 3);
              this.mobileData = data.slice(0, 3);
              this.moreNewData = moreNewData;
              this.topNew = topNew;
            } else {
              this.newData = newData;
              this.mobileData = data;
            }
          }
        });
      }
      //咨询动态
      if (activeName == "2") {
        getNewsByConsult(par).then((res) => {
          if (res.code == 200) {
            let data = res.rows;
            let topNew = data.find((item) => item.isTop == 1); // 获取第一个符合条件的元素
            for (let i in data) {
              // if (data[i].isTop == 1) {
              //   topNew = data[i];
              // }
              if (data[i].isTop == 0) {
                newData.push(data[i]);
              }
              if (data[i].isTop == 0 && data[i].isHot == 1) {
                moreNewData.push(data[i]);
              }
            }
            if (moreNew) {
              this.newData = newData.slice(0, 3);
              this.mobileData = data.slice(0, 3);
              this.moreNewData = moreNewData;
              this.topNew = topNew;
            } else {
              this.newData = newData;
              this.mobileData = data;
            }
          }
        });
      }
      //行业资讯
      if (activeName == "4") {
        getNewsByIndustry(par).then((res) => {
          if (res.code == 200) {
            let data = res.rows;
            let topNew = data.find((item) => item.isTop == 1); // 获取第一个符合条件的元素
            for (let i in data) {
              // if (data[i].isTop == 1) {
              //   topNew = data[i];
              // }
              if (data[i].isTop == 0) {
                newData.push(data[i]);
              }
              if (data[i].isTop == 0 && data[i].isHot == 1) {
                moreNewData.push(data[i]);
              }
            }
            if (moreNew) {
              this.newData = newData.slice(0, 3);
              this.mobileData = data.slice(0, 3);
              this.moreNewData = moreNewData;
              this.topNew = topNew;
            } else {
              this.newData = newData;
              this.mobileData = data;
            }
          }
        });
      }
      this.moreNew = true;
      this.detailsMod = false;
    },
    getmoreNew(num) {
      this.moreNew = false;
      this.getList(num);
    },
    handleClick() {
      let path = this.$router.history.current.path;
      window.history.pushState(
        null,
        "",
        path + "?" + "activeName=" + this.activeName
      );
      this.getList();
    },
    topNewDetail(newsId) {
      getNewsDetail(newsId).then((res) => {
        this.textObj = {
          title: res.data.title,
          intro: res.data.intro,
          sendTime: res.data.sendTime,
        };
        this.detailsMod = true;
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      });
    },
  },
};
</script>
<style lang="scss">
.newsBanner {
  width: 100%;
  height: 400px;
  background-image: url("http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/journalism/bg3.png");
  background-repeat: no-repeat;
  background-position: 0 -123px;
  background-size: 100%;
}
.more {
  background-color: #7c7b7b;
  color: #fff;
  width: 172px;
  height: 33px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 60px;
}

.mobile_journalism {
  display: none;
}
@media screen and (max-width: 650px) {
  .mobile_journalism {
    display: block;
    background-color: #fff;
  }
  .pc_journalism {
    display: none;
  }
}
</style>