<template>
  <div class="flex loginBg">
    <div class="flex flexSB loginCon">
      <div>
        <img
          class="loginLogo"
          src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/webLogo.png"
        />
        <img class="loginLeftBg" src="../assets/img/loginLeftBg.png" />
      </div>
      <div class="loginForm">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="账号密码登录" name="first">
            <div class="account">
              <el-form :model="form" ref="queryForm">
                <el-form-item prop="userName">
                  <el-input
                    class="accountInp"
                    v-model="form.userName"
                    clearable
                    placeholder="手机号/用户名/邮箱"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <el-input
                    class="accountInp"
                    v-model="form.password"
                    show-passwordword
                    clearable
                    placeholder="请输入密码"
                  ></el-input>
                </el-form-item>
              </el-form>
              <el-button class="accountBtn" type="primary" round @click="login"
                >登录</el-button
              >
            </div>
          </el-tab-pane>
          <el-tab-pane label="短信验证登录" name="second">
            <div class="account">
              <el-form :model="form" ref="queryForm">
                <el-form-item prop="userName">
                  <el-input
                    class="accountInp"
                    v-model="form.userName"
                    clearable
                    placeholder="请输入手机号"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="code">
                  <div class="flex flexT">
                    <el-input
                      class="accountInp codeInp"
                      v-model="form.code"
                      clearable
                      placeholder="请输入验证码"
                    ></el-input>
                    <div
                      class="flex codeBtn"
                      :class="
                        this.form.userName && this.form.userName.length == 11
                          ? 'codeBtnAc'
                          : ''
                      "
                      @click="getCode"
                    >
                      {{ sendAuthCode ? codeMes : auth_time + "秒后重新发送" }}
                    </div>
                  </div>
                </el-form-item>
              </el-form>
              <el-button class="accountBtn" type="primary" round @click="login"
                >登录</el-button
              >
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { login, loginCode, getLoginCode } from "@/api/index";
import event from "../components/event.js";
export default {
  metaInfo: {
    title:"广州易策医管集团成立于2001年，旗下拥有4家医院管理咨询机构，专注于医院管理咨询、医管学术研究、医院内部管理与素质培训、医院托管与投资、医院精益运营信息技术与大数据服务。",
    meta: [
      {
        name: "keywords",
        content:"易策,医管,易策咨询,致和咨询,医院管理,易策健康,绩效管理，高质量发展",
      },
      {
        name: "description",
        content:"广州易策医管集团成立于2001年，旗下拥有4家医院管理咨询机构，专注于医院管理咨询、医管学术研究、医院内部管理与素质培训、医院托管与投资、医院精益运营信息技术与大数据服务。已服务全国600多家医院，凭借专业的理论知识、管理经验、信息技术及学科构建等，实现与医院之间的管理知识技术转移，推动中国医院的职业化管理进程，提升医院的职业化管理水平。",
      },
    ],
  },
  data() {
    return {
      activeName: "first",
      form: {
        userName: "", // 15622108737
        password: "", // 123456
        code: null,
      },
      sendAuthCode: true, // 控制显示‘发送验证码’还是60秒倒计时
      codeMes: "发送验证码",
      auth_time: 0,
      input: "",
      input2: "",
    };
  },
  methods: {
    /** 切换登录方式 */
    handleClick(tab, e) {},
    /** 获取验证码 */
    getCode() {
      if (!this.sendAuthCode) {
        this.msgError(this.auth_time + "秒后才能再次发送");
        return;
      }
      if (this.form.userName && this.form.userName.length == 11) {
        getLoginCode({ mobile: this.form.userName }).then((res) => {
          if (res.code == 200) {
            this.getAuthCode();
          }
        });
      } else {
        this.msgError("请填写完整的手机号码");
      }
    },
    /** 验证码倒计时 */
    getAuthCode() {
      this.sendAuthCode = false;
      this.auth_time = 60;
      let auth_timetimer = setInterval(() => {
        this.auth_time--;
        if (this.auth_time <= 0) {
          this.sendAuthCode = true;
          clearInterval(auth_timetimer);
        }
      }, 1000);
    },
    /** 登录 */
    login() {
      let form = this.form;
      if (this.activeName == "first") {
        if (!form.userName) {
          this.msgError("请填写手机号/用户名/邮箱");
          return;
        }
        if (!form.password) {
          this.msgError("请填写密码");
          return;
        }
        login({ userName: form.userName, password: form.password }).then(
          (res) => {
            if (res.code == 200) {
              localStorage.setItem("user", JSON.stringify(res.data));
              this.$router.push("/entrance");
              event.$emit("Assembly");
            }
          }
        );
      } else {
        if (!form.userName) {
          this.msgError("请输入手机号");
          return;
        }
        if (!form.code) {
          this.msgError("请输入验证码");
          return;
        }
        loginCode({ userName: form.userName, code: form.code }).then((res) => {
          if (res.code == 200) {
            localStorage.setItem("user", JSON.stringify(res.data));
            this.$router.push("/entrance");
            event.$emit("Assembly");
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
.loginBg {
  width: 100vw;
  height: 100vh;
  background-color: #c9e7ff;
  .loginCon {
    width: 1078px;
    height: 700px;
    background: #ffffff;
    box-shadow: 0px 5px 30px rgba(31, 91, 130, 0.17);
    border-radius: 10px;
    padding: 41px 84px 165px 101px;
    .loginLogo {
      width: 166px;
      height: 62px;
      margin-bottom: 113px;
    }
    .loginLeftBg {
      width: 422px;
      height: 319px;
    }
    .loginForm {
      width: 400px;
      height: 340px;
      background: #ffffff;
      border: 0px solid #707070;
      box-shadow: 0px 5px 12px rgba(118, 168, 252, 0.32);
      border-radius: 6px;
      margin-top: 195px;
      padding: 40px 32px;
      .el-tabs__nav {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .el-tabs__active-bar {
          width: 50%;
        }
        .el-tabs__item {
          width: 50%;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
        }
      }
      .account {
        padding-top: 13px;
        .accountInp {
          margin-bottom: 8px;
        }
        .el-input__inner:hover {
          border: 1px solid #dcdfe6;
        }
        .codeInp {
          width: calc(100% - 120px);
          .el-input__inner {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: 0;
          }
          .el-input__inner:hover {
            border-right: 0;
          }
        }
        .codeBtn {
          width: 120px;
          height: 40px;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          border: 1px solid #dcdfe6;
          border-left: 0;
          font-size: 14px;
          font-weight: 400;
          color: #999999;
        }
        .codeBtnAc {
          color: #d94b4b;
          cursor: pointer;
        }
        .el-input__inner:focus {
          border-color: #dcdfe6;
        }
        .accountBtn {
          width: 336px;
          height: 42px;
        }
      }
    }
  }
}
</style>