var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"tabs_m"},[_c('div',{class:['tabs_item', _vm.activeName == 1 ? 'is_tabs' : ''],on:{"click":function($event){return _vm.setActiveFun(1)}}},[_vm._v("联系我们")]),_c('div',{class:['tabs_item', _vm.activeName == 2 ? 'is_tabs' : ''],on:{"click":function($event){return _vm.setActiveFun(2)}}},[_vm._v("媒体联络")]),_c('div',{class:['tabs_item', _vm.activeName == 3 ? 'is_tabs' : ''],on:{"click":function($event){return _vm.setActiveFun(3)}}},[_vm._v("加入我们")])]),(_vm.activeName == 1)?_c('div',{staticClass:"joinUs_m_box"},[_vm._m(1),_vm._m(2),_vm._m(3),_c('div',[(!_vm.showMap)?_c('tenxuMap',{staticClass:"map"}):_vm._e()],1)]):(_vm.activeName == 2)?_c('div',{staticClass:"joinUs_m_box"},[_vm._m(4)]):_c('div',{staticClass:"joinUs_m_box"},[_vm._m(5),_c('div',{staticClass:"joinUs_m_idea"},[_vm._v(" 易策医管集团重视团队建设和人才培养,专业化的业务发展,多元化的人才结构,为更多专注医疗事业的有志之士创造无限成长发展空间。在助力医院高质量发展的过程中,进行专业人才细分,特聘知名大学教授、医院高级管理者、咨询领域人才精英为专家顾问团,通过实战模拟、案例研讨、互动教学等实效性教育手段,让新老员工都能获得对应的培训学习机会，致力于帮助有理想、有目标、有专业技能、有追求的员工综合性发展，成长为行业内极具竞争力的高级复合型咨询人才，坚持使命，提升医院管理经营能力,惠泽群众健康幸福生活。 ")]),_vm._m(6),_c('div',{staticClass:"index_subhead"},[_vm._v("汇聚医院管理专家 吸纳各领域人才精英")]),_vm._m(7),_vm._m(8),_c('div',{staticClass:"index_subhead"},[_vm._v("汇聚医院管理专家 吸纳各领域人才精英")]),_vm._m(9)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m_banner"},[_c('div',{staticClass:"m_banner_text"},[_vm._v("联系我们")]),_c('div',{staticClass:"m_banner_i"}),_c('div',{staticClass:"m_banner_text2"},[_vm._v("专业 ▪ 精细 ▪ 创新 ▪ 共赢")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index_title"},[_c('span',{staticClass:"index_title_before"}),_c('span',{staticClass:"index_title_span"},[_vm._v("联系我们")]),_c('span',{staticClass:"index_title_after"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"joinUs_m_title"},[_vm._v("感谢您对易策医管的关注"),_c('br'),_vm._v("如果您有任何问题或意见请通过以下方式和我们联络：")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"joinUs_m_company"},[_c('div',{staticClass:"joinUs_m_company_name"},[_vm._v("易策医管集团广州总部")]),_c('div',{staticClass:"joinUs_m_company_way"},[_vm._v("地址：广州市番禺区天安科技园总部中心25号楼1005室")]),_c('div',{staticClass:"joinUs_m_company_way"},[_vm._v("电话：020-89637030 / 89637031")]),_c('div',{staticClass:"joinUs_m_company_way"},[_vm._v("邮箱：gzyicezx@163.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index_title"},[_c('span',{staticClass:"index_title_before"}),_c('span',{staticClass:"index_title_span"},[_vm._v("媒体联络")]),_c('span',{staticClass:"index_title_after"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index_title"},[_c('span',{staticClass:"index_title_before"}),_c('span',{staticClass:"index_title_span"},[_vm._v("人才理念")]),_c('span',{staticClass:"index_title_after"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index_title"},[_c('span',{staticClass:"index_title_before"}),_c('span',{staticClass:"index_title_span"},[_vm._v("加入我们")]),_c('span',{staticClass:"index_title_after"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"joinUs_m_img_box"},[_c('img',{attrs:{"src":"http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/joinUs/contacts32.png","alt":""}}),_c('img',{attrs:{"src":"http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/joinUs/contacts33.png","alt":""}}),_c('div',{staticClass:"joinUs_m_i"},[_vm._v("有意者请点击对应招聘模块，了解空缺岗位的具体要求与职责。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index_title"},[_c('span',{staticClass:"index_title_before"}),_c('span',{staticClass:"index_title_span"},[_vm._v("招聘流程")]),_c('span',{staticClass:"index_title_after"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"joinUs_m_flow"},[_c('div',{staticClass:"joinUs_m_flow_item"},[_c('img',{staticClass:"joinUs_m_flow_img",attrs:{"src":require("@/assets/img/flow_icon.png"),"alt":""}}),_c('div',{staticClass:"joinUs_m_flow_index"},[_vm._v("01")]),_c('div',{staticClass:"joinUs_m_flow_name"},[_vm._v("简历投递")])]),_c('div',{staticClass:"joinUs_m_flow_item"},[_c('img',{staticClass:"joinUs_m_flow_img",attrs:{"src":require("@/assets/img/flow_icon1.png"),"alt":""}}),_c('div',{staticClass:"joinUs_m_flow_index"},[_vm._v("02")]),_c('div',{staticClass:"joinUs_m_flow_name"},[_vm._v("笔试/线上笔试")])]),_c('div',{staticClass:"joinUs_m_flow_item"},[_c('img',{staticClass:"joinUs_m_flow_img",attrs:{"src":require("@/assets/img/flow_icon2.png"),"alt":""}}),_c('div',{staticClass:"joinUs_m_flow_index"},[_vm._v("03")]),_c('div',{staticClass:"joinUs_m_flow_name"},[_vm._v("面试/远程面试")])]),_c('div',{staticClass:"joinUs_m_flow_item"},[_c('img',{staticClass:"joinUs_m_flow_img",attrs:{"src":require("@/assets/img/flow_icon3.png"),"alt":""}}),_c('div',{staticClass:"joinUs_m_flow_index"},[_vm._v("04")]),_c('div',{staticClass:"joinUs_m_flow_name"},[_vm._v("入职/电子签约")])])])
}]

export { render, staticRenderFns }