<template>
  <div class="userPrincipal">
    <img class="principalBg" src="../assets/img/principalBg.png" />
    <div class="up-content">
      <div class="up-title">
        {{ title }}
        <div class="search" v-if="activeName != '软件介绍'">
          <el-input
            v-model="selectFileName"
            size="mini"
            placeholder="搜索"
            clearable
            @clear="clear"
            @keyup.enter.native="search"
          >
            <i slot="suffix" class="el-icon-search" @click="search"></i>
          </el-input>
        </div>
      </div>
      <el-tabs
        class="up-tabs"
        v-model="activeName"
        type="border-card"
        @tab-click="handleClick"
      >
        <el-tab-pane label="软件介绍" name="软件介绍"
          ><user-Con1 :textObj="textObj"
        /></el-tab-pane>
        <el-tab-pane label="操作文档" name="操作文档"
          ><user-Con2
            @changePattern="changePattern"
            @changePageNum="changePageNum"
            @changeUserModCollect="changeUserModCollect"
            :collectPat="collectPat"
            :queryParams="queryParams1"
            :sudoku="num"
            :sudokuVideo="videoNum"
            :showHead="true"
            :textObj="textObj2"
            :mh="41"
        /></el-tab-pane>
        <el-tab-pane label="操作视频" name="操作视频"
          ><user-Con2
            @changePattern="changePattern"
            @changePageNum="changePageNum"
            @changeUserModCollect="changeUserModCollect"
            :collectPat="2"
            :queryParams="queryParams2"
            :sudoku="num"
            :sudokuVideo="videoNum"
            :showHead="true"
            :textObj="textObj2"
            :mh="41"
        /></el-tab-pane>
        <el-tab-pane label="政策发布" name="政策发布"
          ><user-Con2
            @changePattern="changePattern"
            @changePageNum="changePageNum"
            @changeUserModCollect="changeUserModCollect"
            :collectPat="collectPat"
            :queryParams="queryParams3"
            :sudoku="num"
            :sudokuVideo="videoNum"
            :showHead="true"
            :textObj="textObj2"
            :mh="41"
        /></el-tab-pane>
        <el-tab-pane label="我的收藏" name="我的收藏"
          ><user-Con2
            @changePattern="changePattern"
            @changePageNum="changePageNum"
            @changeUserModCollect="changeUserModCollect"
            :collectPat="collectPat"
            :queryParams="queryParams4"
            :sudoku="num"
            :sudokuVideo="videoNum"
            :showHead="true"
            :textObj="textObj2"
            :mh="41"
        /></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import userCon1 from "@/components/userCon1";
import userCon2 from "@/components/userCon2";
import {
  getDocumentList,
  getVideoList,
  addUserCollection,
  deleteUserCollection,
  getUserDocCollection,
  getUserVideoCollection,
  getSelectList,
} from "@/api/index";
export default {
  metaInfo: {
    title:"广州易策医管集团成立于2001年，旗下拥有4家医院管理咨询机构，专注于医院管理咨询、医管学术研究、医院内部管理与素质培训、医院托管与投资、医院精益运营信息技术与大数据服务。",
    meta: [
      {
        name: "keywords",
        content:"易策,医管,易策咨询,致和咨询,医院管理,易策健康,绩效管理，高质量发展",
      },
      {
        name: "description",
        content:"广州易策医管集团成立于2001年，旗下拥有4家医院管理咨询机构，专注于医院管理咨询、医管学术研究、医院内部管理与素质培训、医院托管与投资、医院精益运营信息技术与大数据服务。已服务全国600多家医院，凭借专业的理论知识、管理经验、信息技术及学科构建等，实现与医院之间的管理知识技术转移，推动中国医院的职业化管理进程，提升医院的职业化管理水平。",
      },
    ],
  },
  components: { userCon1, userCon2 },
  data() {
    return {
      title: "",
      selectFileName: null,
      activeName: "软件介绍", // 选项卡选中字段
      num: 8, // 非视频模式下的文档宫格布局，数量6
      videoNum: 12, // 视频模式下的布局，数量12
      collectPat: 0, // 我的收藏tabs项/搜索模式，0：初始化   1：文档   2：视频
      // 操作文档
      queryParams1: {
        docType: 2,
        pageNum: 1,
        pageSize: 8,
        deptId: null,
        pattern: 1, // 模式1就是列表模式，2是8宫格模式，3是视频12宫格模式且不能切换
      },
      // 操作视频
      queryParams2: {
        pageNum: 1,
        pageSize: 8,
        deptId: null,
        pattern: 1, // 模式1就是列表模式，2是8宫格模式，3是视频12宫格模式且不能切换
      },
      // 政策发布
      queryParams3: {
        docType: 3,
        pageNum: 1,
        pageSize: 8,
        deptId: null,
        pattern: 1, // 模式1就是列表模式，2是8宫格模式，3是视频12宫格模式且不能切换
      },
      // 我的收藏
      queryParams4: {
        createBy: JSON.parse(localStorage.getItem("user")).userId,
        pageNum: 1,
        pageSize: 8,
        deptId: null,
        collectPat: null, // 存储收藏tabs内部的文档、视频参数
        pattern: 1, // 模式1就是列表模式，2是8宫格模式，3是视频12宫格模式且不能切换
      },
      textObj: [
        "绩效考核管理通常也称为业绩考评或“考绩”，是针对企业中每个职工所承担的工作，应用各种科学的定性和定量的方法，对职工行为的实际效果及其对企业的贡献或价值进行考核和评价。它是企业人事管理的重要内容，更是企业管理强有力的手段之一。业绩考评的目的是通过考核提高每个个体的效率，最终实现企业的目标。",
        "绩效考核管理通常也称为业绩考评或“考绩”，是针对企业中每个职工所承担的工作，应用各种科学的定性和定量的方法，对职工行为的实际效果及其对企业的贡献或价值进行考核和评价。它是企业人事管理的重要内容，更是企业管理强有力的手段之一。业绩考评的目的是通过考核提高每个个体的效率，最终实现企业的目标。",
        "绩效考核管理通常也称为业绩考评或“考绩”，是针对企业中每个职工所承担的工作，应用各种科学的定性和定量的方法，对职工行为的实际效果及其对企业的贡献或价值进行考核和评价。它是企业人事管理的重要内容，更是企业管理强有力的手段之一。业绩考评的目的是通过考核提高每个个体的效率，最终实现企业的目标。",
      ],
      textObj2: {
        bgTitle: "操作文档",
        showBgTitle: true,
        data: [],
        total: 32,
      },
    };
  },
  created() {
    // 阻止非法进入
    let user = JSON.parse(localStorage.getItem('user')),that=this
    if(user){
      if(!user.userId){
        this.msgError('userId为空')
        setTimeout(()=>{
          this.$router.push('/login');
        },1000)
      }
    }else{
      this.$router.push('/login');
    }
    // 阻止非法未选择入口的情况进入详情
    let obj = JSON.parse(localStorage.getItem("entra"))[0];
    if(!obj.deptId){
      this.msgError('请选择公司入口再进入当前页面')
        setTimeout(()=>{
          this.$router.push('/entrance');
        },1000)
    }
    if (obj.deptName2) {
      this.title = obj.deptName + " " + obj.deptName2;
    } else {
      this.title = obj.deptName;
    }
    this.queryParams1.deptId = obj.deptId;
    this.queryParams2.deptId = obj.deptId;
    this.queryParams3.deptId = obj.deptId;
    this.queryParams4.deptId = obj.deptId;
  },
  methods: {
    /** 子组件改变展示模式通知父组件变更请求字段 */
    changePattern(e) {
      console.log(e)
      if (this.activeName == "操作文档") {
        if (this.queryParams1.pattern == e) {
          // 重复点击不予以执行
          return;
        }
        this.queryParams1.pattern = e;
      }
      if (this.activeName == "政策发布") {
        if (this.queryParams3.pattern == e) {
          // 重复点击不予以执行
          return;
        }
        this.queryParams3.pattern = e;
      }
      if (this.activeName == "我的收藏") {
        if (this.queryParams4.pattern == e) {
          // 重复点击不予以执行
          return;
        }
        this.queryParams4.pattern = e;
      }
      // 调用切换tabs的方法来动态调用各自页面的请求，传递changePattern防止重置pageNum为1
      this.handleClick("changePattern");
    },
    /** 特殊处理，我的收藏内逻辑 */
    changeUserModCollect(e) {
      this.collectPat = e;
      this.queryParams4.collectPat = e;
      this.getColl();
    },
    /** 子组件改变页码数通知父组件变更请求的参数 */
    changePageNum(e) {
      if (this.activeName == "操作文档") {
        this.queryParams1.pageNum = e.pageNum;
        this.getDoc();
      }
      if (this.activeName == "操作视频") {
        this.queryParams2.pageNum = e.pageNum;
        this.getVid();
      }
      if (this.activeName == "政策发布") {
        this.queryParams3.pageNum = e.pageNum;
        this.getDoc();
      }
    },
    /** 选项卡切换 */
    handleClick(e) {
      // 初始化了tabs大标题、我的收藏初始显示文档、其他tabs初始显示文档
      this.collectPat = 0;
      this.selectFileName = null
      if (this.activeName == "操作文档") {
        this.getDoc();
      }
      if (this.activeName == "操作视频") {
        this.getVid();
      }
      if (this.activeName == "政策发布") {
        this.getDoc();
      }
      if (this.activeName == "我的收藏") {
        this.queryParams4.createBy = JSON.parse(
          localStorage.getItem("user")
        ).userId;
        this.textObj2.showBgTitle = false;
        if (!this.queryParams4.collectPat) {
          this.queryParams4.collectPat = this.collectPat;
        } else {
          this.collectPat = this.queryParams4.collectPat;
        }
        this.getColl();
      } else {
        this.textObj2.showBgTitle = true;
      }
      this.textObj2.bgTitle = this.activeName;
    },
    /** 搜索 */
    search(e) {
      // 1.操作文档  2.操作视频  3.政策发布  4.文档收藏  5.视频收藏
      let selectType = null,
        qp = {};
      if (this.activeName == "操作文档") {
        selectType = 1;
        qp = this.queryParams1;
      }
      if (this.activeName == "操作视频") {
        selectType = 2;
        qp = this.queryParams2;
      }
      if (this.activeName == "政策发布") {
        selectType = 3;
        qp = this.queryParams3;
      }
      if (this.activeName == "我的收藏") {
        qp = this.queryParams4;
        if (this.collectPat == 2) {
          selectType = 5;
        } else {
          selectType = 4;
        }
      }
      let par = {
        deptId: JSON.parse(localStorage.getItem("entra"))[0].deptId,
        createBy: this.activeName == "我的收藏" ? qp.createBy : null, // 只有收藏需要createBy
        selectType: selectType,
        selectFileName: this.selectFileName,
      };
      getSelectList(par).then((res) => {
        if (res.code == 200) {
          if (this.activeName == "操作视频" || this.collectPat == 2) {
            this.textObj2.data = this.wash(res.rows, "selV");
          } else {
            this.textObj2.data = this.wash(res.rows, "selD");
          }
          this.textObj2.total = res.total;
        }
      });
    },
    /** 清空 */
    clear() {
      console.log("12346");
    },
    /** 文档数据的请求接口 */
    getDoc() {
      let par = {},
        that = this;
      if (this.activeName == "操作文档") {
        // 列表还是宫格布局，不同布局pageSize不一样
        if (this.queryParams1.pattern === 1) {
          this.queryParams1.pageSize = 10;
        } else {
          this.queryParams1.pageSize = 8;
        }
        par = this.queryParams1;
      } else {
        if (this.queryParams3.pattern === 1) {
          this.queryParams3.pageSize = 10;
        } else {
          this.queryParams3.pageSize = 8;
        }
        par = this.queryParams3;
      }
      getDocumentList(par).then((res) => {
        if (res.code == 200) {
          that.textObj2.data = that.wash(res.rows, "doc");
          that.textObj2.total = res.total;
        }
      });
    },
    /** 视频数据的请求接口 */
    getVid() {
      getVideoList(this.queryParams2).then((res) => {
        if (res.code == 200) {
          this.textObj2.data = this.wash(res.rows, "vie");
          this.textObj2.total = res.total;
        }
      });
    },
    /** 我的收藏 */
    getColl() {
      let par =
        this.queryParams4.createBy +
        "?pageNum=" +
        this.queryParams4.pageNum +
        "&pageSize=" +
        this.queryParams4.pageSize +
        "&deptId=" +
        this.queryParams4.deptId;
      if (this.collectPat == 2) {
        getUserVideoCollection(par).then((res) => {
          if (res.code == 200) {
            this.textObj2.data = this.wash(res.rows, "vie");
            this.textObj2.total = res.total;
          }
        });
      } else {
        getUserDocCollection(par).then((res) => {
          if (res.code == 200) {
            this.textObj2.data = this.wash(res.rows, "doc");
            this.textObj2.total = res.total;
          }
        });
      }
    },
    /** 将各个tabs的数据重新命名规划统一 */
    wash(data, type) {
      let d = [];
      // 文档
      if (type == "doc") {
        for (let i in data) {
          d.push({
            id: data[i].dataId,
            createTime: data[i].createTime,
            fileName: data[i].docName,
            url: data[i].docUrl,
            size: data[i].docSize,
          });
        }
      }
      // 视频
      if (type == "vie") {
        for (let i in data) {
          d.push({
            id: data[i].dataId,
            createTime: data[i].createTime,
            videoCover: data[i].videoCover,
            videoName: data[i].videoName,
            size: data[i].videoSize,
            url: data[i].videoUrl,
          });
        }
      }
      // 搜索-文档
      if (type == "selD") {
        for (let i in data) {
          d.push({
            id: data[i].dataId,
            createTime: data[i].createTime,
            videoCover: data[i].videoCover,
            fileName: data[i].selectFileName,
            size: data[i].selectFileSize,
            url: data[i].selectFileUrl,
          });
        }
      }
      // 搜索-视频
      if (type == "selV") {
        for (let i in data) {
          d.push({
            id: data[i].dataId,
            createTime: data[i].createTime,
            videoCover: data[i].selectFileCover,
            videoName: data[i].selectFileName,
            size: data[i].selectFileSize,
            url: data[i].selectFileUrl,
          });
        }
      }
      return d;
    },
  },
};
</script>

<style lang="scss">
.userPrincipal {
  width: 100%;
  height: 1000px;
  background-color: #f9fcff;
  position: relative;
  .principalBg {
    width: 100%;
    height: 216px;
  }
  .up-content {
    position: absolute;
    z-index: 5;
    width: 1200px;
    height: 910px;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    .up-title {
      font-size: 26px;
      font-weight: 400;
      height: 50px;
      color: #ffffff;
      position: relative;
      .search {
        position: absolute;
        z-index: 5;
        right: 0;
        top: 146%;
        width: 200px;
        height: 30px;
        border-radius: 6px;
        transform: translateY(-50%);
        overflow: hidden;
        background-color: #fff;
        font-size: 0;
        .el-input {
          .el-input__inner {
            height: 30px;
            line-height: 30px;
            border: 0;
          }
          .el-input__inner:focus {
            border: 0;
          }
          .el-input__suffix {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
      }
    }
    .up-tabs {
      width: 100%;
      height: 860px;
      background-color: rgba(255, 255, 255, 0);
      border: 0;
      border-top-left-radius: 4px;
      overflow: hidden;
      .el-tabs__header {
        background-color: rgba(255, 255, 255, 0);
        border: 0;
        .el-tabs__nav {
          width: 860px;
          .el-tabs__item {
            height: 50px;
            width: 172px;
            text-align: center;
            font-size: 18px;
            line-height: 50px;
            font-weight: bold;
            color: #ffffff;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
          }
          .is-active {
            color: #0084db;
            background-color: #f9fcff;
          }
        }
      }
      .el-tabs__content {
        background-color: #fff;
        padding: 15px 49px;
        height: 810px;
      }
    }
  }
}
</style>