<template>
  <div>
    <div class="m_banner">
      <div class="m_banner_text">专家团队</div>
      <div class="m_banner_i"></div>
      <div class="m_banner_text2">汇聚医院管理专家<br />吸纳各领域精英人才</div>
    </div>
    <div class="tabs_m">
      <div :class="['tabs_item', activeName == 1 ? 'is_tabs' : '']" @click="setActiveFun(1)">医院运营管理专家</div>
      <div :class="['tabs_item', activeName == 2 ? 'is_tabs' : '']" @click="setActiveFun(2)">易策医管研究发展院</div>
      <div :class="['tabs_item', activeName == 3 ? 'is_tabs' : '']" @click="setActiveFun(3)">医院质量管理与服务建设专家</div>
    </div>
    <div class="team_m_box" v-if="!detailsMod">
      <div class="team_m_item" v-for="(item, index) in team" :key="index" @click="getDetails(item)">
        <div class="team_m_img">
          <img :src="item.avatar" alt="">
        </div>
        <div class="team_m_info">
          <div class="team_m_name">{{ item.userName }}</div>
          <div class="team_m_postName">{{ item.postName }}</div>
          <div class="team_m_title" v-html="item.title"></div>
        </div>
      </div>
      <div class="index_more" v-if="activeName != 3" @click="getMore">查看更多专家</div>
    </div>
    <div v-else class="team_m_user_info">
      <img class="team_m_user_img" :src="showTeamMes?.avatar" alt="">
      <div class="team_m_user_name">{{ showTeamMes?.userName }}</div>
      <div class="team_m_user_postName">{{ showTeamMes?.postName }}</div>
      <div class="team_m_user_title" v-html="showTeamMes?.title"></div>
      <div class="team_m_user_intro" v-html="showTeamMes?.intro"></div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['team'],
    data() {
      return {
        activeName: 1,
        detailsMod: false,
        showTeamMes: {},
      }
    },
    created() {
      let query = this.$route.query;
      if(query.activeName){
        this.activeName = query.activeName
      }
      
    },
    methods: {
      setActiveFun(num) {
        this.activeName = num
        this.detailsMod = false
        this.$router.push({
          path: "/team",
          query: { activeName: num },
        });
        this.$emit('getList', num)
      },
      getMore() {
        this.$emit('getMore', this.activeName)
      },
      getDetails(item) {
        this.showTeamMes = item
        this.detailsMod = true
      }
    }
  }
</script>

<style lang="scss">
  .team_m_title {
    * {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
    ul {
      overflow: hidden; //超出的文本隐藏
      display: -webkit-box;
      -webkit-line-clamp: 2; // 设置从第几行后开始省略
      -webkit-box-orient: vertical;
      li {
        display: inline;
        font-size: 28px;
        font-weight: 400;
        color: #333333;
      }
    }
  }
  .team_m_user_title {
    * {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
    ul {
      li {
        font-size: 26px;
        font-weight: 400;
        color: #333333;
        line-height: 40px;
      }
    }
  }
  .team_m_user_intro {
    font-size: 26px;
    font-weight: 400;
    color: #333333;
    line-height: 40px;
  }
</style>
<style lang="scss" scoped>
  .m_banner {
    width: 100%;
    height: 515px;
    background-image: url("../assets/img/mobile_bg3.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .m_banner_text {
      font-size: 32px;
      color: #fff;
      text-align: center;
      padding-top: 132px;
    }
    .m_banner_i {
      width: 45px;
      height: 6px;
      background-color: #fff;
      margin: 20px auto 16px;
    }
    .m_banner_text2 {
      font-size: 40px;
      color: #fff;
      text-align: center;
    }
  }

  .tabs_m {
    display: flex;
    background-color: #F5F6F8;
    padding: 0 37px;
    flex-wrap: wrap;
    .tabs_item {
      width: 50%;
      height: 95px;
      line-height: 95px;
      font-size: 28px;
      font-weight: 400;
      text-align: center;
      color: #333333;
      border-bottom: 4px solid transparent;
      &:nth-child(3) {
        width: 100%;
      }
    }
    .is_tabs {
      background: #fff;
      border-bottom: 4px solid #1E61FF;
    }
  }
  .index_more {
    width: 175px;
    height: 50px;
    line-height: 50px;
    border-radius: 4px;
    border: 2px solid #999999;
    font-size: 22px;
    color: #666;
    text-align: center;
    margin: 20px auto 0;
  }
  .team_m_box {
    padding: 50px 30px;
    .team_m_item {
      display: flex;
      align-items: end;
      margin-bottom: 48px;
      .team_m_img {
        width: 250px;
        height: 358px;
        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
      .team_m_info {
        width: calc(100% - 250px);
        min-height: 258px;
        background: #F7F9FA;
        padding: 40px;
        .team_m_name {
          font-size: 30px;
          font-weight: bold;
          color: #4965D5;
          margin-bottom: 18px;
        }
        .team_m_postName {
          font-size: 28px;
          font-weight: 400;
          color: #333333;
          margin-bottom: 18px;
        }
      }
    }
  }

  .team_m_user_info {
    padding: 50px 30px;
    .team_m_user_img {
      width: 100%;
    }
    .team_m_user_name {
      font-size: 40px;
      font-weight: bold;
      color: #4965D5;
      margin: 47px 0 14px 0;
    }
    .team_m_user_postName {
      font-size: 28px;
      font-weight: 400;
      color: #333333;
      margin-bottom: 22px;
    }
  }
</style>
