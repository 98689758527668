<template>
  <div>
    <div class="pc_serve">
      <!-- 首页banner图 -->
      <div class="serveBanner" v-if="activeName == '1'">
        <div class="serveBanner-title">服务案例</div>
        <div class="serveBanner-text">合作共赢发展</div>
      </div>
      <div class="serveBanner2" v-else>
        <div class="serveBanner2-title">案例概况</div>
      </div>
      <div v-if="activeName == '1'">
        <!-- 地图 -->
        <div class="mapDiv">
          <div id="mapId" ref="myEchart"></div>

          <!-- <div class="ceshiBG">
            <img class="m1" src="../assets/map1/路径 1.png" alt="">
            <img class="m220" src="../assets/map3/路径 220.svg" alt="">
          </div> -->
          <div class="mapHisData">
            <h3 class="flex mapHisData-title">{{ HisTilter }}</h3>
            <div
              class="hisListDiv flex"
              v-for="(item, index) in hisList"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <!-- 医院类别 -->
        <div class="hisTabDiv">
          <div class="hisTab flex flexS">
            医院类型：
            <div
              class="tabList flex"
              :class="hisType == item.type ? 'acTab' : ''"
              v-for="(item, index) in hisTypeList"
              :key="index"
              @click="changeTab(item.type)"
            >
              {{ item.value }}
            </div>
          </div>
          <div class="logoDiv flex flexS">
            <div
              class="logoMes"
              v-for="(item, index) in hisList2"
              :key="index"
              @click="toDetails(item)"
            >
              <img :src="item.hospitalLogo" />
              <div class="logoName flex">{{ item.hospitalName }}</div>
            </div>
          </div>
          <div v-if="!hisShow" class="flex logoMore" @click="stretch(true)">
            <i class="el-icon-arrow-down"></i>查看更多
          </div>
          <div v-else class="flex logoHide" @click="stretch(false)">
            <i class="el-icon-arrow-up"></i>收起
          </div>
        </div>
      </div>
      <div v-else>
        <div class="hisDetails flex flexSB flexT">
          <img class="t-bg" :src="hisDetails.hospitalImg" />
          <div class="hisDetailsText">
            <div class="hisDetailsTextTitle flex flexS">
              <img :src="hisDetails.hospitalLogo" />
              <div>
                <div class="h-d-t-1">{{ hisDetails.hospitalName }}</div>
                <div class="h-d-t-2 flex flexS">
                  <div class="h-d-tag-1 flex">{{ hisDetails.hospitalLevel }}</div>
                  <div class="h-d-tag-2 flex">{{ hisDetails.hospitalType }}</div>
                  <div class="h-d-tag-3 flex">{{ hisDetails.hospitalBed }}</div>
                </div>
              </div>
            </div>
            <div class="hisDetailsTextContent">
              简介: {{ hisDetails.hospitalInfo }}
            </div>
          </div>
        </div>
        <!-- <div class="s-a-t">服务项目</div>
        <div class="servicesAvailable">
          <swiper
            :slides-per-view="3"
            :space-between="50"
            :options="swiperOption"
            ref="mySwiper"
          >
            <swiper-slide v-for="(item, index) in servicesList" :key="index">
              <div class="swiperCon" :data-tbbsm="item">
                <img class="img" style="height: 97px" :src="item.projectImg" />
                <div class="flex expertPresentation">
                  {{ item.projectName }}
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <img src="../assets/img/left.png" class="custom-button-prev1" />
          <img src="../assets/img/right.png" class="custom-button-next1" />
        </div> -->
        <div class="s-a-t">相关医院</div>
        <div class="servicesAvailable">
          <swiper
            :slides-per-view="3"
            :space-between="50"
            :options="swiperOption2"
            ref="mySwiper"
          >
            <swiper-slide v-for="(item, index) in logoList" :key="index">
              <div class="swiperCon2" :data-tbbsm="item">
                <img class="img" :src="item.hospitalLogo" mode="heightFix" />
                <div class="flex expertPresentation">
                  {{ item.relevantName }}
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <img src="../assets/img/left.png" class="custom-button-prev2" />
          <img src="../assets/img/right.png" class="custom-button-next2" />
        </div>
      </div>
    </div>
    <mobileServe
      class="moblile_serve"
      :hisTypeList="hisTypeList"
      :hisList2="hisList2"
      :hisShow="hisShow"
      @stretch="stretch"
      @changeTab="changeTab"
    />
  </div>
  
</template>
<script>
import * as echarts from "echarts";
import geoJson from "../../public/geoJson.json";
import { mapState } from "vuex";
import mobileServe from '@/mobileViews/serve.vue'
import {
  getHospitalList,
  getHospitalDetails,
  getAreaHospital,
} from "@/api/index";
export default {
  metaInfo: {},
  components: { mobileServe },
  computed: {
    ...mapState(["changeActiveName"]),
  },
  watch: {
    changeActiveName(newData, oldData) {
      this.activeName = newData.toString();
    },
  },
  data() {
    let _this = this;
    return {
      myChart: null,
      HisTilter: "",
      hisList: [],
      // 医院类型Logo
      hisShow: false,
      hisList2: [],
      hisListCopy:[], // 备份
      hisType: 0,
      hisDetails: {},
      hisTypeList: [
        { type: null, value: "全部" },
        { type: "综合医院", value: "综合医院" },
        { type: "中医医院", value: "中医医院" },
        { type: "妇幼保健院", value: "妇幼保健院" },
        { type: "其他类型", value: "其他类型" },
      ],
      type: 2, // 1：显示地图  2：显示详情
      // 服务项目
      servicesList: [],
      swiperOption: {
        loop: false,
        speed: 1000,
        on: {
          slideChangeTransitionEnd: function () {
            _this.showTeamMes = _this.servicesList[this.realIndex];
            // _this.swiperIndexChange(this.realIndex)
            _this.swiperIndex = this.realIndex;
          },

          // click: function (e) {
          //   let obj = {};
          //   // console.log(e.target.src)
          //   if (e.target.src) {
          //     obj = _this.servicesList.find((item) => {
          //       return item.img == e.target.src;
          //     });
          //   }
          //   console.log(obj);
          //   // let tbbsm = e.target.getAttribute("data-tbbsm"); // 获取自定义属性
          //   _this.toServices(obj); // 实现传参
          // },
        },
        autoplay: true,
        slidesPerView: 6,
        // 自定义翻页按钮配置
        navigation: {
          prevEl: ".custom-button-prev1", // 点击上一页
          nextEl: ".custom-button-next1", // 点击下一张时
        },
        // 自定义翻页器配置
        pagination: {
          el: "nyll",
        },
      },
      // 医院logo
      logoList: [],
      swiperOption2: {
        loop: false,
        speed: 1000,
        on: {
          slideChangeTransitionEnd: function () {
            _this.showTeamMes = _this.logoList[this.realIndex];
            // _this.swiperIndexChange(this.realIndex)
            _this.swiperIndex = this.realIndex;
          },

          click: function (e) {
            let obj = {};
            // console.log(e.target.src)
            if (e.target.src) {
              obj = _this.logoList.find((item) => {
                return item.hospitalLogo == e.target.src;
              });
            }
            console.log(obj);
            // let tbbsm = e.target.getAttribute("data-tbbsm"); // 获取自定义属性
            _this.toLogo(obj); // 实现传参
          },
        },
        autoplay: false,
        slidesPerView: 5,
        // 自定义翻页按钮配置
        navigation: {
          prevEl: ".custom-button-prev2", // 点击上一页
          nextEl: ".custom-button-next2", // 点击下一张时
        },
        // 自定义翻页器配置
        pagination: {
          el: "nyll",
        },
      },
    };
  },
  created() {
    let query = this.$route.query;
    if (query.activeName) {
      this.activeName = query.activeName;
      if (this.activeName == "1") {
        setTimeout(() => {
          this.drawing();
          // this.changeTab(null);
        }, 200);
      } else {
        let hospitalId = query.hospitalId;
        this.hospitalDetails(hospitalId);
      }
    }
  },
  methods: {
    /** 初始绘图 */
    drawing() {
      let that = this;
      //注册可用地图
      echarts.registerMap("china", { geoJSON: geoJson });
      //初始化
      const myCharts = echarts.init(document.getElementById("mapId"), null, {
        renderer: "svg",
      });
      const mapName = "china";
      const doImg = require("@/assets/img/bbbbbb.png");
      const doImg2 = require("@/assets/img/cccccc.png");
      const option = {
        series: [
          //区域颜色
          {
            type: "map",
            map: "china",
            // geoIndex: 0,
            roam: false,
            zoom: 1.2,
            label: {
              show: true,
              color: "#fff",
              fontSize: 8,
              emphasis: {
                color: "#fff",
                fontSize: 10,
              },
              select: {
                color: "#fff",
                fontSize: 8,
              },
            },
            itemStyle: {
              normal: {
                areaColor: {
                  type: "pattern",
                  image: doImg, //配置图片
                  repeat: "repeat",
                },
                borderColor: "#87CEEB",
                borderWidth: 1,
              },
              emphasis: {
                areaColor: {
                  type: "pattern",
                  image: doImg2, //配置图片
                  repeat: "repeat",
                },
              },
            },
            select: {
              label: {
                color: "#fff",
                fontSize: 8,
              },
              itemStyle: {
                areaColor: {
                  type: "pattern",
                  image: doImg2, //配置图片
                  repeat: "repeat",
                },
              },
            },
            animation: true,
          },
        ],
      };
      myCharts.setOption(option);
      myCharts.on("click", function (params) {
        that.getList({ cityId: params.dataIndex, cityName: params.name });
      });
      that.getList({ cityId: 11, cityName: "广东" });
    },
    // 获取列表
    getList(obj) {
      this.HisTilter = obj.cityName;
      // this.hisList = [
      //   { hisName: obj.cityName + "东风医院" },
      //   { hisName: obj.cityName + "西风医院" },
      //   { hisName: obj.cityName + "南风医院" },
      //   { hisName: obj.cityName + "北风医院" },
      // ];
      let data = {
        province: obj.cityName,
      };
      getAreaHospital(data).then((res) => {
        if (res.code == 200) {
          let list = res.rows[0] ? res.rows[0].hospitalName.split("、") : [];
          this.hisList = list;
          this.changeTab(null);
        }
      });
    },
    // 选择医院类型
    changeTab(i) {
      this.hisType = i;
      let data = {
        // hospitalProvince: this.HisTilter,
        hospitalType: i,
      };
      getHospitalList(data).then((res) => {
        if (res.code == 200) {
          this.hisList2 = res.rows.slice(0, 10);
          this.hisListCopy = JSON.parse(JSON.stringify(res.rows))
          this.hisShow = false
        }
      });
    },
    // 点击切换tabs
    toDetails(item) {
      // console.log(tab, event);
      let path = this.$router.history.current.path;
      window.open(path + "?activeName=2" + `&hospitalId=${item.hospitalId}`);
      // window.history.pushState(
      //   null,
      //   "",
      //   path + "?activeName=" + this.activeName
      // );
    },
    /** 点击展开或隐藏医院icon */
    stretch(bo){
      this.hisShow = bo
      if(bo == false){
        this.hisList2 = this.hisListCopy.slice(0, 10)
      }else{
        this.hisList2 = this.hisListCopy
      }
    },
    // 点击项目查看详情
    toServices(obj) {},
    // 点击logo切换医院
    toLogo(obj) {
      let path = this.$router.history.current.path;
      window.open(path + "?activeName=2" + `&hospitalId=${obj.relevantId}`);
    },
    //获取详情
    hospitalDetails(hospitalId) {
      getHospitalDetails({ hospitalId }).then((res) => {
        if (res.code == 200) {
          this.servicesList = [];
          this.hisDetails = res.data;
          this.logoList = res.data.relevantList ? res.data.relevantList : [];
          this.servicesList = res.data.projectList ? res.data.projectList : [];
        }
      });
    },
  },
};
</script>
<style lang="scss">
.serveBanner {
  width: 100%;
  height: 400px;
  background-image: url("../assets/img/case.png");
  background-repeat: no-repeat;
  // background-position: 0 -181px;
  background-size: 100%;
  font-size: 52px;
  font-weight: 400;
  padding: 60px 410px;
  color: #fff;
  .serveBanner-title {
    margin-top: 63px;
  }
  .serveBanner-text {
    font-size: 30px;
    margin-top: 23px;
  }
}
.serveBanner2 {
  width: 100%;
  height: 400px;
  background-image: url("../assets/img/survey.png");
  background-repeat: no-repeat;
  // background-position: 0 -181px;
  background-size: 100%;
  font-size: 52px;
  font-weight: 400;
  padding: 60px 410px;
  color: #fff;
  .serveBanner2-title {
    margin-top: 63px;
  }
}
#mapId {
  width: 800px;
  height: 650px;
}
.mapDiv {
  width: 1200px;
  height: 702px;
  margin-bottom: 50px;
  margin-top: 54px;
  margin-left: 50%;
  transform: translateX(-50%);
  display: flex;
  // align-items: center;
  justify-content: space-between;
  .mapHisData {
    width: 284px;
    max-height: 550px;
    overflow-y: auto;
    background: #f5f7fc;
    box-shadow: 0px 3px 6px 1px rgba(104, 104, 104, 0.05);
    border-radius: 9px;
    border: 1px solid #f2f2f2;
    .mapHisData-title {
      margin: 30px 0;
    }
    .hisListDiv {
      font-size: 14px;
      margin-bottom: 25px;
    }

    /*滚动条样式*/
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #c1c1c1;
    }
    &::-webkit-scrollbar-track {
      // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: #f5f7fc;
    }
  }
}
.hisTabDiv {
  .hisTab {
    width: 1200px;
    height: 83px;
    background: #f5f7fc;
    margin-left: 50%;
    transform: translateX(-50%);
    border-radius: 0px 0px 0px 0px;
    border: 1px solid #e7e7e7;
    padding: 0 36px;
    margin-bottom: 30px;
    .tabList {
      width: 106px;
      height: 40px;
      margin-right: 10px;
      cursor: pointer;
    }
    .acTab {
      background: #00225c;
      border-radius: 6px 6px 6px 6px;
      color: #fff;
    }
  }
  .logoDiv {
    width: 1200px;
    padding: 50px 24px 0 24px;
    margin-left: 50%;
    transform: translateX(-50%);
    border: 1px solid #dfdfdf;
    flex-wrap: wrap;
    .logoMes {
      // margin: 0 55px 50px 55px;
      margin-bottom: 50px;
      width: 228px;
      padding: 0 15px;
      cursor: pointer;
      img {
        width: 120px;
        height: 120px;
        margin-left: 50%;
        transform: translateX(-50%);
      }
      .logoName {
        margin-top: 22px;
        font-size: 14px;
        text-align: center;
      }
    }
  }
  .logoMore {
    width: 140px;
    height: 38px;
    color: #666666;
    border-radius: 5px;
    border: 1px solid #c7c7c7;
    margin: 40px auto;
    cursor: pointer;
    i{
      margin-right: 8px;
    }
  }
  .logoMore:hover{
    border: 1px solid #00225c;
    background: #00225c;
    color: #fff;
  }

  .logoHide{
    width: 140px;
    height: 38px;
    color: #666666;
    border-radius: 5px;
    border: 1px solid #c7c7c7;
    margin: 40px auto;
    cursor: pointer;
    i{
      margin-right: 8px;
    }
  }
  .logoHide:hover{
    border: 1px solid #00225c;
    background: #00225c;
    color: #fff;
  }
}
.hisDetails {
  width: 1200px;
  margin-left: 50%;
  margin-bottom: 25px;
  padding-top: 30px;
  transform: translateX(-50%);
  .t-bg {
    width: 410px;
    height: 325px;
  }
  .hisDetailsText {
    width: 725px;
    .hisDetailsTextTitle {
      margin-bottom: 15px;
      img {
        width: 110px;
        height: 110px;
        margin-right: 20px;
      }
      .h-d-t-1 {
        color: #363636;
        font-size: 26px;
        font-weight: bold;
        margin-bottom: 15px;
      }
      .h-d-tag-1 {
        background: #67cddb;
        color: #fff;
        padding: 2px 8px;
        margin-right: 6px;
        font-size: 12px;
      }
      .h-d-tag-2 {
        background: #4863c4;
        color: #fff;
        padding: 2px 8px;
        margin-right: 6px;
        font-size: 12px;
      }
      .h-d-tag-3 {
        background: #efefef;
        color: #828282;
        padding: 2px 8px;
        margin-right: 6px;
        font-size: 12px;
      }
    }
    .hisDetailsTextContent {
      font-size: 14px;
      line-height: 27px;
    }
  }
}
.s-a-t {
  width: 1200px;
  margin-left: 50%;
  transform: translateX(-50%);
  font-size: 18px;
  font-weight: bold;
  color: #363636;
  margin-bottom: 10px;
}
.servicesAvailable {
  width: 1200px;
  padding: 38px 38px 20px 38px;
  margin-left: 50%;
  transform: translateX(-50%);
  border: 1px solid #e7e7e7;
  position: relative;
  margin-bottom: 30px;
  .swiperCon {
    width: 121px;
    img {
      width: 121px;
      height: 97px;
      margin-bottom: 12px;
    }
    .expertPresentation {
      width: 121px;
      text-align: center;
      font-size: 14px;
    }
  }
  .swiperCon2 {
    img {
      height: 110px;
      margin-bottom: 12px;
      display: block;
    }
    .expertPresentation {
      width: 121px;
      text-align: center;
      font-size: 14px;
    }
  }
}
.custom-button-prev1,
.custom-button-prev2 {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: -80px;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  cursor: pointer;
}
.custom-button-next1,
.custom-button-next2 {
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  right: -80px;
  width: 48px;
  height: 48px;
  cursor: pointer;
}

.moblile_serve {
  display: none;
}
@media screen and (max-width: 650px) {
  .moblile_serve {
    display: block;
    background-color: #fff;
  }
  .pc_serve {
    display: none;
  }
}
</style>
