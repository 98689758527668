<template>
  <div>
    <div class="m_banner">
      <div class="m_banner_text">联系我们</div>
      <div class="m_banner_i"></div>
      <div class="m_banner_text2">专业 ▪ 精细 ▪ 创新 ▪ 共赢</div>
    </div>
    <div class="tabs_m">
      <div :class="['tabs_item', activeName == 1 ? 'is_tabs' : '']" @click="setActiveFun(1)">联系我们</div>
      <div :class="['tabs_item', activeName == 2 ? 'is_tabs' : '']" @click="setActiveFun(2)">媒体联络</div>
      <div :class="['tabs_item', activeName == 3 ? 'is_tabs' : '']" @click="setActiveFun(3)">加入我们</div>
    </div>
    <div class="joinUs_m_box" v-if="activeName == 1">
      <div class="index_title">
        <span class="index_title_before"></span>
        <span class="index_title_span">联系我们</span>
        <span class="index_title_after"></span>
      </div>
      <div class="joinUs_m_title">感谢您对易策医管的关注<br />如果您有任何问题或意见请通过以下方式和我们联络：</div>
      <div class="joinUs_m_company">
        <div class="joinUs_m_company_name">易策医管集团广州总部</div>
        <div class="joinUs_m_company_way">地址：广州市番禺区天安科技园总部中心25号楼1005室</div>
        <div class="joinUs_m_company_way">电话：020-89637030 / 89637031</div>
        <div class="joinUs_m_company_way">邮箱：gzyicezx@163.com</div>
      </div>
      <div>
        <tenxuMap class="map" v-if="!showMap" />
      </div>
    </div>
    <div class="joinUs_m_box" v-else-if="activeName == 2">
      <div class="index_title">
        <span class="index_title_before"></span>
        <span class="index_title_span">媒体联络</span>
        <span class="index_title_after"></span>
      </div>
    </div>
    <div class="joinUs_m_box" v-else>
      <div class="index_title">
        <span class="index_title_before"></span>
        <span class="index_title_span">人才理念</span>
        <span class="index_title_after"></span>
      </div>
      <div class="joinUs_m_idea">
        易策医管集团重视团队建设和人才培养,专业化的业务发展,多元化的人才结构,为更多专注医疗事业的有志之士创造无限成长发展空间。在助力医院高质量发展的过程中,进行专业人才细分,特聘知名大学教授、医院高级管理者、咨询领域人才精英为专家顾问团,通过实战模拟、案例研讨、互动教学等实效性教育手段,让新老员工都能获得对应的培训学习机会，致力于帮助有理想、有目标、有专业技能、有追求的员工综合性发展，成长为行业内极具竞争力的高级复合型咨询人才，坚持使命，提升医院管理经营能力,惠泽群众健康幸福生活。
      </div>
      <div class="index_title">
        <span class="index_title_before"></span>
        <span class="index_title_span">加入我们</span>
        <span class="index_title_after"></span>
      </div>
      <div class="index_subhead">汇聚医院管理专家 吸纳各领域人才精英</div>
      <div class="joinUs_m_img_box">
        <img src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/joinUs/contacts32.png" alt="">
        <img src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/joinUs/contacts33.png" alt="">
        <div class="joinUs_m_i">有意者请点击对应招聘模块，了解空缺岗位的具体要求与职责。</div>
      </div>
      <div class="index_title">
        <span class="index_title_before"></span>
        <span class="index_title_span">招聘流程</span>
        <span class="index_title_after"></span>
      </div>
      <div class="index_subhead">汇聚医院管理专家  吸纳各领域人才精英</div>
      <div class="joinUs_m_flow">
        <div class="joinUs_m_flow_item">
          <img class="joinUs_m_flow_img" src="@/assets/img/flow_icon.png" alt="">
          <div class="joinUs_m_flow_index">01</div>
          <div class="joinUs_m_flow_name">简历投递</div>
        </div>
        <div class="joinUs_m_flow_item">
          <img class="joinUs_m_flow_img" src="@/assets/img/flow_icon1.png" alt="">
          <div class="joinUs_m_flow_index">02</div>
          <div class="joinUs_m_flow_name">笔试/线上笔试</div>
        </div>
        <div class="joinUs_m_flow_item">
          <img class="joinUs_m_flow_img" src="@/assets/img/flow_icon2.png" alt="">
          <div class="joinUs_m_flow_index">03</div>
          <div class="joinUs_m_flow_name">面试/远程面试</div>
        </div>
        <div class="joinUs_m_flow_item">
          <img class="joinUs_m_flow_img" src="@/assets/img/flow_icon3.png" alt="">
          <div class="joinUs_m_flow_index">04</div>
          <div class="joinUs_m_flow_name">入职/电子签约</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import tenxuMap from "@/components/tenxuMap";
  export default {
    props: ['showMap'],
    data() {
      return {
        activeName: 1
      }
    },
    components: { tenxuMap },
    created() {
      let query = this.$route.query;
      if (query.activeName) {
        this.activeName = query.activeName;
      }
    },
    methods: {
      setActiveFun(num) {
        this.activeName = num
      }
    }
  }
</script>

<style lang="scss" scoped>
  .m_banner {
    width: 100%;
    height: 515px;
    background-image: url("../assets/img/mobile_bg7.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .m_banner_text {
      font-size: 32px;
      color: #fff;
      text-align: center;
      padding-top: 132px;
    }
    .m_banner_i {
      width: 45px;
      height: 6px;
      background-color: #fff;
      margin: 20px auto 16px;
    }
    .m_banner_text2 {
      font-size: 40px;
      color: #fff;
      text-align: center;
    }
  }
  .tabs_m {
    display: flex;
    background-color: #F5F6F8;
    padding: 0 37px;
    flex-wrap: wrap;
    .tabs_item {
      width: 33.33%;
      height: 95px;
      line-height: 95px;
      font-size: 28px;
      font-weight: 400;
      text-align: center;
      color: #333333;
      border-bottom: 4px solid transparent;
    }
    .is_tabs {
      background: #fff;
      border-bottom: 4px solid #1E61FF;
    }
  }

  .index_title {
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    color: #000;
    margin: 0 auto;
    .index_title_span {
      margin: 0 20px;
    }
    .index_title_after {
      display: inline-block;
      width: 107px;
      height: 23px;
      background-image: url("../assets/img/titleIcon1.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transform: rotate(180deg);
    }
    .index_title_before {
      display: inline-block;
      width: 107px;
      height: 23px;
      background-image: url("../assets/img/titleIcon1.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  .index_subhead {
    text-align: center;
    font-size: 26px;
    font-weight: 400;
    color: #333;
    margin: 23px auto;
  }
  .joinUs_m_box {
    padding: 44px;
    .joinUs_m_title {
      font-size: 26px;
      font-weight: 400;
      color: #333333;
      margin: 47px 0;
    }
    .joinUs_m_company {
      background: #F5F5F5;
      padding: 30px;
      .joinUs_m_company_name {
        font-size: 30px;
        font-weight: bold;
        color: #333333;
        text-align: center;
        margin-bottom: 23px;
      }
      .joinUs_m_company_way {
        font-size: 26px;
        font-weight: 400;
        color: #333333;
        line-height: 39px;
        margin: 8px 0;
      }
    }
    .joinUs_m_idea {
      font-size: 26px;
      font-weight: 400;
      color: #333333;
      line-height: 39px;
      margin: 44px 0;
    }
    .joinUs_m_img_box {
      img {
        width: 100%;
        margin: 20px 0;
      }
      .joinUs_m_i {
        font-size: 22px;
        font-weight: 400;
        color: #666666;
        text-align: center;
        margin-bottom: 52px;
      }
    }
    .joinUs_m_flow {
      display: flex;
      flex-wrap: wrap;
      margin-top: 120px;
      .joinUs_m_flow_item {
        width: 50%;
        text-align: center;
        margin-bottom: 50px;
        .joinUs_m_flow_img {
          width: 124px;
          height: 124px;
        }
        .joinUs_m_flow_index {
          font-size: 26px;
          font-weight: bold;
          color: #1E61FF;
          margin: 10px 0;
        }
        .joinUs_m_flow_name {
          font-size: 26px;
          font-weight: bold;
          color: #333333;
        }
      }
    }
  }
  .map {
    margin-top: 20px;
    width: 100%;
    height: 462px;
  }
</style>