<template>
  <div>
    <div class="pc_joinUs">
      <!-- 首页banner图 -->
      <div class="joinUsBanner"></div>
      <!-- 标签页 -->
      <div class="tabs">
        <div class="flex tabsTitle">
          <span>首页</span> > <span>联系我们</span>
        </div>
        <el-tabs class="tabsMod" v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="联系我们" name="1">
            <div class="j-content1">
              <div>感谢您对易策医管的关注</div>
              <div>如果您有任何问题或意见请通过以下方式和我们联络：</div>
              <div class="flexS flexT relation">
                <div class="flex flexSB address" style="width: 100%">
                  <!-- <div class="map" id="containerMap"></div> -->
                  <tenxuMap class="map" v-if="activeName == 1 && showMap" />
                  <div class="flex mapText">
                    <div>
                      <p>易策医管集团广州总部</p>
                      <div>
                        + 地址：广州市番禺区天安科技园总部中心25号楼1005室
                      </div>
                      <div>+ 电话：020-89637030 / 89637031</div>
                      <div>+ 邮箱：gzyicezx@163.com</div>
                    </div>
                  </div>
                </div>
                <div
                  class="agency"
                  v-for="(item, index) in agencyData"
                  :key="index"
                >
                  <img class="img" :src="item.img" />
                  <div class="agencyText" v-if="item.name != '华南办事处'">
                    <div class="addressName">
                      {{ item.name }}<span>({{ item.address }})</span>
                    </div>
                    <div class="contacts">联系人：{{ item.contacts }}</div>
                    <div class="contacts">+ 电话：{{ item.phone }}</div>
                    <div>+ 邮箱：{{ item.mail }}</div>
                  </div>
                  <div class="agencyText" v-else>
                    <div class="addressName">
                      {{ item.name }}<span>({{ item.address }})</span>
                    </div>
                    <div class="contacts">联系人：杨老师  18923100350（微信同步）</div>
                    <div class="contacts">联系人：黄老师  13927554966（微信同步）</div>
                  </div>
                </div>
                <!-- <div class="agency">
                  <img
                    class="img"
                    src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/joinUs/contacts16.png"
                  />
                </div> -->
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="媒体联络" name="2">
            <div class="j-content2">
              <div class="flex media">
                <img
                  class="img2"
                  src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/joinUs/contacts21.png"
                />
                <div class="mediaConnection">
                  <p>亲爱的媒体朋友，感谢您对易策医管的关注</p>
                  <p>如您有学术论坛合作、专家采访、会议合作、公益活动等需求</p>
                  <p>请填写以下信息，我们会尽快与您取得联系：</p>
                  <el-form ref="contactObj" :model="contactObj">
                    <el-input
                      v-model="contactObj.userName"
                      placeholder="请输入您的姓名"
                    ></el-input>
                    <el-input
                      v-model="contactObj.contactInfo"
                      placeholder="请输入您的联系方式"
                    ></el-input>
                    <el-input
                      v-model="contactObj.organ"
                      placeholder="请输入您的机构名称"
                    ></el-input>
                    <el-input
                      v-model="contactObj.postName"
                      placeholder="请输入您的职位"
                    ></el-input>
                    <el-input
                      v-model="contactObj.needsIntro"
                      placeholder="请输入您的合作需求"
                    ></el-input>
                  </el-form>
                  <img
                    class="btnImg"
                    src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/joinUs/btn.png"
                    @click="sub"
                  />
                  <div class="explain">
                    如有其他问题，请发送至邮箱：gzyicezx@163.com
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="加入我们" name="3">
            <div class="j-content3" v-if="!jobRequire">
              <div class="usBg"></div>
              <img
                class="us"
                src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/joinUs/contacts31.png"
              />
              <div class="joinExampleTitle">
                <div class="flex">加入我们</div>
                <div class="flex j-text">
                  汇聚医院管理专家&nbsp;&nbsp;吸纳各领域人才精英
                </div>
              </div>
              <div class="flex flexSB recruitment">
                <img
                  class="img"
                  src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/joinUs/contacts32.png"
                  @click="toJobText(1)"
                />
                <img
                  class="img"
                  src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/joinUs/contacts33.png"
                  @click="toJobText(2)"
                />
              </div>
              <div class="recruitmentExplain">
                温馨提示：有意者请点击对应招聘模块，了解空缺岗位的具体要求与职责。
              </div>
              <div class="usBg2">
                <img
                  class="flow"
                  src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/joinUs/contacts34.png"
                />
              </div>
            </div>
            <job-Text v-else :recruitmentText="recruitmentText" />
          </el-tab-pane>
          <!-- <el-tab-pane label="合作对接" name="4"> </el-tab-pane> -->
        </el-tabs>
      </div>
    </div>
    <mobilejoinUs
      :showMap="showMap"
      class="mobile_joinUs"
    />
  </div>
  
</template>
<script>
import { mapState } from "vuex";
import jobText from "@/components/jobText";
import tenxuMap from "@/components/tenxuMap";
import { getImageList, media, getJobList,getJobList2 } from "@/api/index";
import mobilejoinUs from '@/mobileViews/joinUs.vue'
export default {
  name: "join",
  metaInfo: {
    title:"广州易策医管集团成立于2001年，旗下拥有4家医院管理咨询机构，专注于医院管理咨询、医管学术研究、医院内部管理与素质培训、医院托管与投资、医院精益运营信息技术与大数据服务。",
    meta: [
      {
        name: "keywords",
        content:"易策医管、易策咨询、医院管理咨询、易策健康、致和咨询",
      },
      {
        name: "description",
        content:"广州易策医管集团成立于2001年，旗下拥有4家医院管理咨询机构，专注于医院管理咨询、医管学术研究、医院内部管理与素质培训、医院托管与投资、医院精益运营信息技术与大数据服务。已服务全国600多家医院，凭借专业的理论知识、管理经验、信息技术及学科构建等，实现与医院之间的管理知识技术转移，推动中国医院的职业化管理进程，提升医院的职业化管理水平。",
      },
    ],
  },
  components: { jobText, mobilejoinUs, tenxuMap },
  computed: {
    ...mapState(["changeActiveName"]),
  },
  watch: {
    changeActiveName(newData, oldData) {
      this.activeName = newData.toString();
    },
  },
  data() {
    return {
      activeName: "1",
      showMap: false, // 预防地图初始化失败
      jobRequire: false, // 招聘要求展开模式，true就展开招聘要求
      agencyData: [
        // {
        //   img: "http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/joinUs/contacts15.png",
        //   name: "华南办事处",
        //   address: "广州",
        //   contacts: "黄老师",
        //   phone: "13927554966（微信同步）",
        //   mail: "65929384@qq.com",
        // },
        // {
        //   img: "http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/joinUs/contacts11.png",
        //   name: "华东办事处",
        //   address: "杭州",
        //   contacts: "杨老师（微信同步）",
        //   phone: "17767058886",
        //   mail: "66765318@qq.com",
        // },
        // {
        //   img: "http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/joinUs/contacts12.png",
        //   name: "西北办事处",
        //   address: "西安",
        //   contacts: "魏老师",
        //   phone: "15829476223（微信同步）",
        //   mail: "379334864@qq.com",
        // },
        
        // {
        //   img: "http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/joinUs/contacts14.png",
        //   name: "西南办事处",
        //   address: "成都",
        //   contacts: "罗老师",
        //   phone: "13981977862（微信同步）",
        //   mail: "386987410@qq.com",
        // },
        // {
        //   img: "http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/joinUs/contacts13.png",
        //   name: "华中办事处",
        //   address: "郑州",
        //   contacts: "任老师",
        //   phone: "15810845089（微信同步）",
        //   mail: "931880457@qq.com",
        // },
        // {
        //   img: "http://yice-prod.oss-cn-guangzhou.aliyuncs.com/contact/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20220223111226.png",
        //   name: "山东办事处",
        //   address: "山东",
        //   contacts: "张老师",
        //   phone: "18660609527（微信同步）",
        //   mail: "18660609527@163.com",
        // },
        
      ],
      recruitmentText: [], // 传递到子组件的值
      contactObj: {
        userName: "",
        contactInfo: "",
        organ: "",
        postName: "",
        needsIntro: "",
      },
    };
  },
  created() {
    let query = this.$route.query;
    if (query.activeName) {
      this.activeName = query.activeName;
    }
    if (document.documentElement.clientWidth >= 650) {
      this.showMap = true
    }
  },
  methods: {
    sub() {
      media(this.contactObj).then((res) => {
        this.$message({
          message: "提交成功!感谢您的支持和配合，我们将会尽快与您取得联系！",
          type: "success",
        });
      });
    },
    handleClick() {
      this.jobRequire = false;
      this.recruitmentText = []
      let path = this.$router.history.current.path;
      window.history.pushState(
        null,
        "",
        path + "?" + "activeName=" + this.activeName
      );
    },
    toJobText(i) {
      if (i == 1) {
        getJobList({ typeId: 1 }).then((res) => {
          this.recruitmentText = res.data;
          this.jobRequire = true;
        });
      } else {
        // getImageList({ type: 3 }).then((res) => {});
        getJobList2({ typeId: 2 }).then((res) => {
          this.recruitmentText = res.data;
          this.jobRequire = true;
        });
      }
      
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>
<style lang="scss">
.joinUsBanner {
  width: 100%;
  height: 400px;
  background-image: url("http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/joinUs/bg7.png");
  background-repeat: no-repeat;
  background-position: 0 -123px;
  background-size: 100%;
}
.j-content1 {
  width: 100%;
  padding: 60px 0;
  .relation {
    flex-wrap: wrap;
    .address {
      margin: 20px 0;
      .map {
        width: 590px;
        height: 270px;
        background-color: #ddd;
        .rotate-circle {
          display: none;
        }
        .tmap-zoom-control {
          display: none;
        }
      }
      .mapText {
        width: 590px;
        height: 270px;
        background-color: #d9ebf6;
        p {
          font-size: 28px;
          font-weight: bold;
        }
        div {
          div {
            font-size: 17px;
            margin-bottom: 10px;
          }
        }
      }
    }
    .agency {
      width: 386px;
      margin-bottom: 20px;
      .img {
        width: 386px;
        margin-bottom: 10px;
      }
      .agencyText {
        background-color: #d9ebf6;
        font-size: 16px;
        height: 175px;
        padding: 30px 20px;
        div {
          margin-bottom: 5px;
        }
        .addressName {
          font-size: 20px;
          font-weight: bold;
          span {
            font-weight: 300;
          }
        }
        .contacts {
          font-size: 15px;
          font-weight: bold;
        }
      }
    }
    .agency:nth-child(3n) {
      margin: 0 20px;
    }
  }
}
.j-content2 {
  width: 100%;
  height: 690px;
  margin: 60px 0;
  position: relative;
  overflow: visible;
  .media {
    position: absolute;
    z-index: 10;
    width: calc(100vw - 17px);
    height: 690px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    .img2 {
      width: 960px;
      height: 690px;
    }
    .mediaConnection {
      width: 960px;
      height: 690px;
      padding: 40px;
      padding-top: 20px;
      background-color: #d9ebf6;
      position: relative;
      p {
        font-size: 16px;
        color: #000;
      }
      .el-form {
        margin-top: 40px;
        .el-input {
          margin-bottom: 13px;
          font-size: 17px;
          .el-input__inner {
            background-color: #d9ebf6;
            border: 0;
            border-radius: 0;
            height: 50px;
            border-bottom: 1px solid #000;
            &::-webkit-input-placeholder {
              color: #969696;
            }
            &::-moz-input-placeholder {
              color: #969696;
            }
            &::-ms-input-placeholder {
              color: #969696;
            }
          }
        }
      }

      .btnImg {
        width: 197px;
        cursor: pointer;
        position: absolute;
        z-index: 10;
        left: 40px;
        bottom: 70px;
      }

      .explain {
        display: inline-block;
        color: #888;
        font-size: 14px;
        position: absolute;
        z-index: 10;
        left: 40px;
        bottom: 20px;
      }
    }
  }
}
.j-content3 {
  width: 100%;
  padding: 60px 0;
  .usBg {
    width: 100%;
    height: 561px;
    position: relative;
  }
  .us {
    position: absolute;
    z-index: 10;
    width: calc(100vw - 17px);
    height: 561px;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
  }
  .joinExampleTitle {
    width: 400px;
    margin: 80px auto;
    font-size: 36px;
    font-weight: bold;
    position: relative;
    flex-wrap: wrap;
    color: #040f58;
    div {
      width: 100%;
    }
    .j-text {
      margin-top: 15px;
      font-size: 15px;
    }
  }
  .joinExampleTitle::after {
    position: absolute;
    left: 80px;
    top: 30%;
    transform: translateY(50%);
    z-index: 10;
    content: "";
    width: 30px;
    height: 3px;
    background-color: #040f58;
  }
  .joinExampleTitle::before {
    position: absolute;
    right: 80px;
    top: 30%;
    transform: translateY(50%);
    z-index: 10;
    content: "";
    width: 30px;
    height: 3px;
    background-color: #040f58;
  }
  .recruitment {
    width: 100%;
    img {
      width: 590px;
      cursor: pointer;
    }
  }
  .recruitmentExplain {
    color: #787878;
    font-size: 14px;
    margin-top: 25px;
  }
  .usBg2 {
    margin-top: 100px;
    width: 100%;
    height: 496px;
    position: relative;
  }
  .flow {
    position: absolute;
    z-index: 10;
    width: calc(100vw - 17px);
    height: 496px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.mobile_joinUs {
  display: none;
}
@media screen and (max-width: 650px) {
  .mobile_joinUs {
    display: block;
    background-color: #fff;
  }
  .pc_joinUs {
    display: none;
  }
}
</style>