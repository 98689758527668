import Vue from 'vue'
import Router from 'vue-router'
import index from '@/views/index' // 首页
import joinUs from '@/views/joinUs' // 关于我们
import train from '@/views/train' // 医管培训
import journalism from '@/views/journalism'; // 新闻中心
import serve from '@/views/serve' // 服务网络
import team from '@/views/team' // 专家团队
import story from '@/views/story' // 易策故事汇
import college from '@/views/college' // 易策医管学院

import login from '@/user/login' // 用户中心-登录
import entrance from '@/user/entrance' // 用户中心-选择入口
import principal from '@/user/principal' // 用户中心-内容
import my from '@/user/my' // 用户中心-我的
import play from '@/user/play' // 用户中心-播放页
import document from '@/user/document' // 用户中心-文档页


Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default new Router({
  routes: [{
      path: '/',
      name: 'index',
      component: index
    },
    {
      path: '/index',
      name: 'index',
      component: index
    },
    {
      // 针对360搜索加的.html
      path: '/index.html',
      name: 'index',
      component: index
    },
    {
      path: '/joinUs',
      name: 'joinUs',
      component: joinUs
    },
    {
      path: '/train',
      name: 'train',
      component: train
    },
    {
      path: '/journalism',
      name: 'journalism',
      component: journalism
    },
    {
      path: '/serve',
      name: 'serve',
      component: serve
    },
    {
      path: '/team',
      name: 'team',
      component: team
    },
    {
      path: '/story',
      name: 'story',
      component: story
    },
    {
      path: '/college',
      name: 'college',
      component: college,
    },
    {
      path: '/login',
      name: 'login',
      component: login,
    },
    {
      path: '/entrance',
      name: 'entrance',
      component: entrance,
    },
    {
      path: '/principal',
      name: 'principal',
      component: principal,
    },
    {
      path: '/my',
      name: 'my',
      component: my,
    },
    {
      path: '/play',
      name: 'play',
      component: play,
    },
    {
      path: '/document',
      name: 'document',
      component: document,
    },
    {
      path: '/aggregate',
      name: 'aggregate',
      component: () => import('@/views/aggregate'),
    },
    {
      path: '/plan',
      name: 'plan',
      component: () => import('@/views/plan'),
    },
    {
      path: '/review',
      name: 'review',
      component: () => import('@/views/review'),
    },
    {
      path: '/modern',
      name: 'modern',
      component: () => import('@/views/modern'),
    },
    {
      path: '/government',
      name: 'government',
      component: () => import('@/views/government'),
    },
    {
      path: '/ehp',
      name: 'ehp',
      component: () => import('@/views/ehp'),
    },
    {
      path: '/development',
      name: 'development',
      component: () => import('@/views/development'),
    },
  ],
  mode: 'history'
})