<template>
  <div>
    <div class="mobile_header">
      <div class="mobile_logo">
        <img class="mobile_logo_img" src="@/assets/img/newLogo.png" alt="">
        <img class="mobile_menu_icon" @click="isOpen = true" src="@/assets/img/menu_icon2.png" alt="">
      </div>
      <div class="mobile_menu" :style="{'display': isOpen ? 'block' : 'none'}" @click="isOpen = false">
        <div class="mobile_menu_box">
          <div
            v-for="(item, index) in links" 
            :key="index" 
            @click="toMenu(item)"
            :class="['mobile_menu_item', $route.path == item.route ? 'is_menu' : '']" 
            v-show="item.route != '/train' && item.route != '/login'"
          >
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>
    <router-view></router-view>
    <div class="mobile_footer">
      <div class="footer_consult">
        <div class="footer_title">留言咨询</div>
        <div class="footer_from">
          <el-row :gutter="10">
            <el-col :span="12" class="footer_col">
              <el-input v-model="from.userName" placeholder="您的姓名" size="large" />
            </el-col>
            <el-col :span="12" class="footer_col">
              <el-input v-model="from.contactInfo" placeholder="电话(最长11位)" size="large" />
            </el-col>
            <el-col :span="12" class="footer_col">
              <el-input v-model="from.organ" placeholder="您的单位" size="large" />
            </el-col>
            <el-col :span="12" class="footer_col">
              <el-input v-model="from.postName" placeholder="您的职位" size="large" />
            </el-col>
            <el-col :span="24" class="footer_col">
              <el-input v-model="from.needsIntro" placeholder="请输入您的需求描述" size="large" />
            </el-col>
          </el-row>
          <div class="footer_from_but" @click="sub">点击提交</div>
        </div>
      </div>
      <div class="footer_contact">
        <div class="footer_title">联系我们</div>
        <div class="contact_way">
          <span class="contact_way_t">电话热线：</span>
          <span class="contact_way_v">020-89637030/89637031</span>
        </div>
        <div class="contact_way">
          <span class="contact_way_t">邮箱地址：</span>
          <span class="contact_way_v">gzyicezx@163.com</span>
        </div>
        <div class="QR_code">
          <div class="QR_code_item" v-for="(item, index) in codeList" :key="index">
            <img :src="item.img" alt="">
            <div class="QR_code_text">{{ item.text }}</div>
          </div>
        </div>
      </div>
      <div class="footer_navigation">
        <div class="footer_title">快速导航</div>
        <div class="navigation_box">
          <div
            class="navigation_item"
            v-for="(item, index) in quickLinks"
            :key="index"
            @click="toMenu(item)"
          >
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="weChat_m">
        <div class="weChat-i">
          <img class="weChat-img" src="@/assets/img/QR_code.png" alt="">
          <div class="weChat-text">扫一扫 添加微信</div>
        </div>
      </div>
      <div class="phone_m">
        <div class="phone-i">咨询专线：136 0276 7901</div>
      </div>
      <div class="top" v-show="isTop" @click="toTop"></div>
    </div>
  </div>
</template>

<script>
  import { media } from "@/api/index";
  export default {
    props: ['links', 'codeList', 'quickLinks'],
    data() {
      return {
        isOpen: false,
        isTop: false,
        from: {
          userName: "",
          contactInfo: "",
          organ: "",
          postName: "",
          needsIntro: "",
        }
      }
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll)
    },
    methods: {
      toMenu(item) {
        this.$emit('toMenu', item)
      },
      sub() {
        media(this.from).then((res) => {
          this.$message({
            message: "提交成功!感谢您的支持和配合，我们将会尽快与您取得联系！",
            type: "success",
          });
        });
      },
      handleScroll () {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        if (scrollTop > 400) {
          this.isTop = true;
        } else {
          this.isTop = false;
        }
      },
      toTop() {
        let Top = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        // 设置计时器，实现滚动效果
        const timeTop = setInterval(() => {
          document.body.scrollTop = document.documentElement.scrollTop = Top -= 100
          if (Top <= 10) {
            clearInterval(timeTop)
          }
        }, 20)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .mobile_header {
    height: 110px;
    padding: 0 36px;
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 999;
    box-shadow: 0px 11px 10px 1px rgba(0,0,0,0.11);
    .mobile_logo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 110px;
      .mobile_logo_img {
        // width: 138px;
        height: 60px;
      }
      .mobile_menu_icon {
        width: 30px;
        height: 25px;
      }
    }
    .mobile_menu {
      transition: all 0.5;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
      width: 100vw;
      height: 100%;
      background-color: rgb(0 0 0 / 53%);
      .mobile_menu_box {
        width: 299px;
        height: 100%;
        background-color: #fff;
        float: right;
        padding-top: 22px;
        .mobile_menu_item {
          padding: 22px 49px;
          color: #333;
          font-size: 30px;
        }
        .is_menu {
          color: #fff;
          background: linear-gradient(to left, #003BC5, #2D67EF );
        }
      }
    }
  }
  .mobile_footer {
    padding: 37px;
    .footer_title {
      font-size: 30px;
      font-weight: bold;
      color: #333;
      margin-bottom: 30px;
    }
    .footer_from {
      padding-bottom: 50px;
      .footer_col {
        margin-bottom: 20px;
        ::v-deep(.el-input) {
          .el-input__inner {
            height: 66px;
            line-height: 66px;
            border: none;
            font-size: 24px;
            color: #666;
          }
        }
      }
      .footer_from_but {
        height: 74px;
        line-height: 74px;
        text-align: center;
        background: #2D43F2;
        font-size: 29px;
        color: #fff;
      }
    }
    .footer_contact {
      padding-bottom: 50px;
      .contact_way {
        font-size: 26px;
        color: #666666;
        margin-bottom: 16px;
        // .contact_way_t {
          
        // }
        .contact_way_v {
          font-weight: 600;
        }
      }
      .QR_code {
        display: flex;
        .QR_code_item {
          margin-right: 50px;
          margin-top: 10px;
          text-align: center;
          img {
            width: 140px;
          }
          .QR_code_text {
            text-align: center;
            font-size: 22px;
            color: #666;
          }
        }
      }
    }
    .footer_navigation {
      .navigation_box {
        display: flex;
        flex-wrap: wrap;
        .navigation_item {
          width: 33.33%;
          font-size: 24px;
          color: #666;
          margin-bottom: 29px;
        }
      }
    }
  }

  .weChat_m {
    position: fixed;
    right: 0px;
    bottom: 258px;
    width: 120px;
    height: 120px;
    border-radius: 100%;
    background-image: url('../assets/img/weChat_m.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: 100;
    .weChat-i {
      width: 224px;
      height: 260px;
      background: #fff;
      position: absolute;
      right: 120px;
      padding: 10px 12px;
      box-shadow: 0px 3px 6px 1px rgba(181,181,181,0.16);
      border: 1px solid #F1F1F1;
      border-radius: 5px;
      display: none;
      .weChat-img {
        width: 200px;
        height: 200px;
      }
      .weChat-text {
        font-size: 24px;
        color: #00225C;
        font-weight: bold;
        text-align: center;
      }
    }
    &:hover > .weChat-i {
      display: block;
    }
  }

  .phone_m {
    position: fixed;
    right: 0px;
    bottom: 170px; 
    width: 120px; 
    height: 120px; 
    border-radius: 100%;
    background-image: url('../assets/img/dianhua_m.png');
    background-repeat: no-repeat;
    background-size: 100% 100%; 
    background-position: center;
    z-index: 100;
    .phone-i {
      width: 360px; 
      height: 60px; 
      line-height: 60px; 
      background: #FFFFFF;
      box-shadow: 0px 3px 6px 1px rgba(181,181,181,0.16);
      border: 1px solid #F1F1F1;
      position: absolute;
      right: 120px; 
      border-radius: 5px; 
      font-size: 28px; 
      font-weight: bold;
      color: #00225C;
      text-align: center;
      display: none;
    }
    &:hover > .phone-i {
      display: block;
    }
  }

  .top {
    position: fixed;
    right: 0px;
    bottom: 80px; 
    width: 120px; 
    height: 120px; 
    border-radius: 100%;
    background-image: url('../assets/img/top_icon.png');
    background-repeat: no-repeat;
    background-size: 100% 100%; 
    background-position: center;
    z-index: 100;
    a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
</style>