<template>
  <div>
    <div v-if="!isMobile" class="appClass" @scroll.passive="scrollEvent">
      <div class="head_sticky">
        <div class="flex flexSB app_head" :class="bg" v-if="!showLogin && index < 8">
          <img v-if="bg == 'bg0'" src="./assets/img/newLogo.png" />
          <img
            v-else
            src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/webLogoWhite.png"
          />
          <div class="flex menu">
            <div class="flex menuBtn menuBtn2" v-for="(item, index) in links" :key="index">
              <span @click="toMenu(item)">
                {{ item.text }}
                <i v-show="item.options" class="el-icon-caret-bottom"></i>
              </span>
              <div v-if="item.options" class="menuOptions">
                <div class="menuOptions-left">
                  <div class="menuOptions-left-text">
                    <img src="./assets/img/menu_icon.png" alt="">
                    {{ item.text }}
                  </div>
                </div>
                <div class="menuOptions-right">
                  <div class="menuOptions-right-title">{{ item.title }}</div>
                  <div class="menuOptions-right-item-box">
                    <div
                      v-for="(options, index2) in item.options"
                      :key="index2"
                      class="menuOptions-right-item"
                      @click="optionsClick(item, options)"
                      :style="{'color': (optionsIndex == options.value && idx == item.index) ? '#2D43F2' : ''}"
                    >
                      {{ options.label }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="app_head_m">
          <div class="app_head_m_but">
            <div class="app_head_m_spanBox">
              <span class="app_head_m_span"></span>
              <span class="app_head_m_span"></span>
              <span class="app_head_m_span"></span>
            </div>
            <div class="app_head_m_menu">
              <div class="app_head_m_menu_item" v-for="(item, index) in links" :key="index" @click="toMenu(item)">
                {{ item.text }}
              </div>
            </div>
          </div>
        </div> -->
      </div>
      
      <!-- 用户中心模块 -->
      <div class="app_head2Bg" v-if="!showLogin && index == 8">
        <div class="flex app_head2">
          <div class="flex flexSB navigation_head">
            <img
              class="logo_head"
              src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/webLogo.png"
            />
            <div class="flex adminMes">
              <div class="home" @click="toMenu(links[0])">官网首页</div>
              <div v-if="showCompany" class="home" @click="toCompany">
                选择公司
              </div>
              <img src="./assets/img/adminHead.png" />
              <el-popover
                popper-class="popClass"
                placement="bottom"
                trigger="click"
                v-model="visible"
              >
                <div class="popMenu1">
                  <div
                    class="cu flex"
                    style="margin-bottom: 10px"
                    @click="userAdminTo"
                  >
                    用户中心
                  </div>
                  <div class="cu flex" @click="loginOut">退出登录</div>
                </div>
                <div slot="reference" class="cu admin">{{ userName }}</div>
                <!-- <el-button slot="reference">click 激活</el-button> -->
              </el-popover>
            </div>
          </div>
        </div>
      </div>

      <router-view></router-view>
      <div>
        <div class="weChat">
          <div class="weChat-i">
            <img class="weChat-img" src="./assets/img/QR_code.png" alt="">
            <div class="weChat-text">扫一扫 添加微信</div>
          </div>
        </div>
        <div class="phone">
          <div class="phone-i">咨询专线：136 0276 7901</div>
        </div>
        <el-backtop class="flex" :right="64" :bottom="150">
          <div>
            <div class="jiantou">
              <i class="el-icon-arrow-up"></i>
            </div>
            <div class="flex d-text">TOP</div>
          </div>
        </el-backtop>
      </div>
      
      <div class="app_foot" v-if="!showLogin">
        <div class="flex navigation">
          快速导航 ：
          <div
            class="flex menuBtn"
            v-for="(item, index) in quickLinks"
            :key="index"
            @click="toMenu(item)"
          >
            {{ item.text }}
          </div>
        </div>
        <div class="flexS flexT companyDiv">
          <div class="qrCodeList" v-for="(item, index) in codeList" :key="index">
            <img class="code" :src="item.img" />
            <div class="flex">{{ item.text }}</div>
            <div class="flex">{{ item.text2 }}</div>
          </div>
          <div class="bottomMes">
            <div class="bottomTitle">联系我们</div>
            <img
              class="logo"
              src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/webLogo.png"
            />
            <div class="flexS">
              <img
                class="icon"
                src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/home/address.png"
              />&nbsp;&nbsp;广州市番禺区天安科技园总部中心25号楼1005室
            </div>
            <div class="flexS">
              <img
                class="icon"
                src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/home/message.png"
              />&nbsp;&nbsp;gzyicezx@163.com
            </div>
            <div class="flexS">
              <img
                class="icon"
                src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/home/phone.png"
              />&nbsp;&nbsp;020-89637030/89637031
            </div>
          </div>
        </div>
        <div class="flex copyright">
          ©&nbsp;Copyright广州易策企业管理咨询有限公司&nbsp;&nbsp;&nbsp;备案号:<a
            href="https://beian.miit.gov.cn/"
            target="_blank"
            style="color: #0c82ff"
            >粤ICP备18130343号-1</a
          >
        </div>
      </div>
    </div>
    <mobileApp
      v-else
      class="appMobile"
      :links="links"
      :codeList="codeList"
      :quickLinks="quickLinks"
      @toMenu="toMenu"
    />
  </div>
  
</template>

<script>
import event from "./components/event.js";
import mobileApp from '@/mobileViews/App.vue'
export default {
  name: "app",
  metaInfo: {
    title:
      "广州易策医管集团成立于2001年，旗下拥有4家医院管理咨询机构，专注于医院管理咨询、医管学术研究、医院内部管理与素质培训、医院托管与投资、医院精益运营信息技术与大数据服务。",
    meta: [
      {
        name: "keywords",
        content:
          "易策,医管,易策咨询,致和咨询,医院管理,易策健康,绩效管理,高质量发展",
      },
      {
        name: "description",
        content:
          "广州易策医管集团成立于2001年，旗下拥有4家医院管理咨询机构，专注于医院管理咨询、医管学术研究、医院内部管理与素质培训、医院托管与投资、医院精益运营信息技术与大数据服务。已服务全国600多家医院，凭借专业的理论知识、管理经验、信息技术及学科构建等，实现与医院之间的管理知识技术转移，推动中国医院的职业化管理进程，提升医院的职业化管理水平。",
      },
    ],
  },
  watch: {
    "$route.path": function (newVal, oldVal) {
      this.topBg();
    },
  },
  components: { mobileApp },
  data() {
    return {
      idx: null,
      optionsIndex: null,
      // index是顶部导航的背景图序号
      links: [
        {
          text: "首页",
          route: "/index",
          index: 0,
        },
        {
          text: "关于我们",
          route: "/college",
          title: '关于我们',
          index: 1,
          options: [
            {
              value: 1,
              label: "企业概况",
            },
            {
              value: 2,
              label: "董事长寄语",
            },
            {
              value: 3,
              label: "发展历程",
            },
            {
              value: 4,
              label: "组织架构",
            },
            {
              value: 6,
              label: "集团荣誉",
            },
          ],
        },
        // {
        //   text: "产品与服务",
        //   route: "/product",
        //   index: 2,
        // },
        {
          text: "医管培训",
          route: "/train",
          index: 2,
        },
        {
          text: "咨询方案",
          route: "/aggregate",
          title: '专业解决方案',
          index: 6,
          options: [
            {
              value: 1,
              route: "/modern",
              label: "现代医院绩效管理体系建设",
            },
            {
              value: 2,
              route: "/government",
              label: "组织结构治理与定岗定编规划",
            },
            {
              value: 3,
              route: "/development",
              label: "医院战略发展规划",
            },
            {
              value: 4,
              route: "/ehp",
              label: "易策EHP医院绩效软件系统",
            },
            {
              value: 5,
              route: "/plan",
              label: "医院集团化县域医共体建设规划",
            },
            {
              value: 6,
              route: "/review",
              label: "医院等级评审辅导咨询项目",
            },
          ],
        },
        {
          text: "服务网络",
          route: "/serve?activeName=1",
          index: 4,
          // options: [
          //   {
          //     value: 1,
          //     label: "企业版图",
          //   },
          //   {
          //     value: 2,
          //     label: "服务案例",
          //   },
          // ],
        },
        {
          text: "专家团队",
          route: "/team",
          title: '专家团队',
          index: 5,
          options: [
            {
              value: 1,
              label: "医院运营管理专家",
            },
            {
              value: 2,
              label: "易策医管研究发展院",
            },
            // {
            //   value: 3,
            //   label: "高质量门诊能力提升专家",
            // },
            {
              value: 3,
              label: "医院质量管理与服务建设专家",
            },
          ],
        },
        {
          text: "新闻中心",
          route: "/journalism",
          title: '新闻中心',
          index: 3,
          options: [
            {
              value: 1,
              label: "集团动态",
            },
            {
              value: 2,
              label: "咨询动态",
            },
            {
              value: 4,
              label: "行业资讯",
            },
            // {
            //   value: 10,
            //   label: "易策故事汇",
            // },
          ],
        },
        {
          text: "加入我们",
          route: "/joinUs",
          title: '加入我们',
          index: 7,
          options: [
            {
              value: 1,
              label: "联系我们",
            },
            {
              value: 2,
              label: "媒体联络",
            },
            {
              value: 3,
              label: "加入我们",
            },
            // {
            //   value: 4,
            //   label: "合作对接",
            // },
          ],
        },
        {
          text: "用户中心",
          route: "/login",
          index: 8,
        },
      ],
      // 快速导航
      quickLinks: [
        {
          text: "关于我们",
          route: "/college",
          index: 7,
        },
        {
          text: "咨询方案",
          route: "/aggregate",
        },
        {
          text: "服务网络",
          route: "/serve?activeName=1",
          index: 4,
        },
        {
          text: "专家团队",
          route: "/team",
          index: 5,
        },
        {
          text: "新闻中心",
          route: "/journalism",
          index: 3,
        },
        {
          text: "加入我们",
          route: "/joinUs",
          index: 7,
        }
      ],
      bg: "bg0", // 动态背景class
      index: 0,
      codeList: [
        {
          img: require("./assets/img/fuwu.jpg"),
          text: "易策医管",
          text2: "",
        },
        {
          img: require("./assets/img/fazhanyuan.jpg"),
          text: "易策医管研究发展院",
        },
        {
          img: require("./assets/img/xueyuan.jpg"),
          text: "易策医管学院",
          text2: "",
        },
      ],
      showLogin: false, // 用户点击跳转到登录页值就会更改为true
      userName: "请登录", // 用户登录后才变更为用户账号
      visible: false,
      showCompany: false, // 显示返回公司进行选择的入口页面的按钮
      isMobile: false
    };
  },
  created() {
    this.topBg();
    if (document.documentElement.clientWidth < 650) {
      this.isMobile = true
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrollEvent);
    // 用$on事件来接收参数
    let that = this;
    event.$on("Assembly", () => {
      that.showLogin = false;
      that.userName = JSON.parse(localStorage.getItem("user")).userName;
    });
  },
  methods: {
    scrollEvent(e) {
      if (document.documentElement.scrollTop >= 450) {
        event.$emit("countToStart");
      }
    },
    // 根据路由修改顶部导航背景图
    topBg() {
      let path = this.$route.path,
        routeArr2 = ["/entrance", "/principal", "/my", "/play", "/document"], // 用户中心模块的路由
        comArr = ["/principal", "/my", "/play", "/document"]; // 显示返回公司选择入口的页面

      let obj = this.links.filter((item) => {
        // 针对360搜索加的，初始化的/index会被加上.html
        return item.route == path || path.indexOf(item.route) > -1;
      });
      if (!!obj.length && obj[0].route == "/login") {
        this.showLogin = true;
      } else {
        this.showLogin = false;
      }
      if (obj.length > 0) {
        // if (obj[0].index == 4) {
        //   this.bg = "bg0"
        // } else {
        //   this.bg = "bg" + obj[0].index;
        // }
        this.bg = "bg0"
        this.index = obj[0].index;
      } else {
        if (routeArr2.indexOf(path) == -1) {
          // 不在首页导航也不在用户中心模块
          this.bg = "bg0";
        } else {
          this.index = 8;
          this.userName = JSON.parse(localStorage.getItem("user")).userName;
        }
      }

      if (comArr.indexOf(path) > -1) {
        this.showCompany = true;
      } else {
        this.showCompany = false;
      }
    },
    // 导航跳转
    toMenu(item) {
      if (item.text == "用户中心") {
        window.open(item.route);
      } else {
        // this.bg = "bg" + item.index;
        this.bg = "bg0";
        this.$router.push(item.route);
      }
    },
    optionsClick(item, options) {
      this.optionsIndex = options.value
      this.idx = item.index
      this.$store.commit("set_activeName", options.value);
      let path = options.route ? options.route : item.route
      this.$router.push({
        path: path,
        query: { activeName: options.value },
      });
    },
    /** 返回入口页面 */
    toCompany() {
      this.$router.push("/entrance");
    },
    superior(item) {
      this.$router.push({
        path: item.route,
        query: { activeName: item.options[0].value },
      })
    },
    handleCommand(command) {
      let query = this.$route.query;
      this.$store.commit("set_activeName", command[1].value);
      // this.bg = "bg" + command[0].index;
      this.bg = "bg0"
      let path = command[1].route ? command[1].route : command[0].route
      this.$router.push({
        path: path,
        query: { activeName: command[1].value },
      });
    },
    /** 跳转到用户中心-我的 */
    userAdminTo() {
      this.visible = false;
      // window.open("/my")
      this.$router.push("/my");
    },
    /** 退出登录 */
    loginOut() {
      // 那也要清楚缓存在本地的登录数据
      let to = {
        text: "用户中心",
        route: "/login",
        index: 8,
      };
      this.visible = false;
      localStorage.removeItem("userInfo");
      localStorage.removeItem("entra");
      this.toMenu(to);
    },
  },
};
</script>

<style lang="scss">
.appClass {
  width: 100%;
  // overflow-x: hidden;
}
.appMobile {
  width: 100vw;
  background-color: #F5F6F8;
}
.head_sticky {
  position: sticky;
  top: 0;
  z-index: 1001;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 3px 6px 1px rgb(185 185 185 / 16%);
}
.app_head {
  // position: relative;
  width: 100%;
  padding: 63px 200px;
  // background-color: rgba(200,200,200, 0.8);

  background-repeat: no-repeat;
  background-position: 0 0;
  img {
    width: 150px;
    height: 55px;
    display: inline-block;
  }
  .menu {
    height: 55px;
    // padding: 0 15px;
    .menuBtn {
      font-size: 16px;
      cursor: pointer;
      margin: 0 24px;
      // color: #fff;
      font-weight: 700;
      .el-dropdown {
        font-size: 16px;
        // color: #fff;
        font-weight: 700;
      }
    }
    .menuBtn:last-child{
      margin-right: 0;
    }

    .menuBtn2 {
      // position: relative;
      
      &:hover > .menuOptions {
          // opacity: 1;
          display: flex;
      }
      span {
        z-index: 1;
      }
    }

    .menuOptions {
      width: 1200px;
      height: 333px;
      padding-top: 95px;
      position: absolute;
      top: 0;
      left: calc(50% - 600px);
      // box-shadow: 0px 3px 6px 1px rgb(185 185 185 / 16%);
      border-radius: 5px;
      color: #333;
      // background-color: #fff;
      transition: all 0.5s;
      // opacity: 0;
      display: none;
      .menuOptions-left {
        width: 328px;
        height: 333px;
        background: #F6F8FD;
        background-image: url('./assets/img/menu_bg.png');
        background-size: 273px 198px;
        background-position: right bottom;
        background-repeat: no-repeat;
        .menuOptions-left-text {
          text-align: center;
          height: 80px;
          line-height: 80px;
          color: #2D43F2;
          font-size: 16px;
          font-weight: 400;
          background-color: #DEE3FC;
          border-right: 3px solid #2D43F2;
          img {
            width: 21px;
            height: 12px;
          }
        }
      }
      .menuOptions-right {
        width: 872px;
        height: 333px;
        padding: 27px 49px;
        background-color: #fff;
        opacity: 0.95;
        .menuOptions-right-title {
          height: 24px;
          font-size: 18px;
          font-weight: 400;
          color: #333333;
          padding-left: 11px;
          position: relative;
          &::before {
            content: '';
            width: 2px;
            height: 16px;
            position: absolute;
            top: 5px;
            left: 0;
            background-color: #2D43F2;
          }
        }
        .menuOptions-right-item-box {
          display: flex;
          flex-wrap: wrap;
          .menuOptions-right-item {
            width: 258px;
            padding: 29px 20px 0 0;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.app_head2Bg {
  width: 100%;
  height: 80px;
  position: relative;
  .app_head2 {
    position: absolute;
    z-index: 5;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin-bottom: 10px;
    .navigation_head {
      width: 1200px;
      height: 100%;
      .logo_head {
        width: 140px;
        height: 52px;
      }
      .adminMes {
        .home {
          font-size: 16px;
          font-weight: 400;
          color: #666666;
          margin-right: 26px;
          cursor: pointer;
        }
        .home:hover {
          color: #4a86f7;
        }
        .admin {
          margin-left: 6px;
          font-size: 16px;
          color: #4a86f7;
        }
      }
    }
  }
}
.bg0 {
  // background-image: url("http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/home/bg0.png");
  // background-size: 100%;
  // color: #333;
  width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
  background-color: #fff;
  img{
    width: 106px;
    height: 39px;
  }
  .menu {
    .menuBtn {
      color: #333;
      .el-dropdown {
        color: #333;
      }
    }
  }
}
.bg1 {
  background-image: url("http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/college/bg1.png");
  background-size: 100%;
  .menu {
    .menuBtn {
      color: #fff;
      .el-dropdown {
        color: #fff;
      }
    }
  }
}
.bg3 {
  background-image: url("http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/journalism/bg3.png");
  background-size: 100%;
  .menu {
    .menuBtn {
      color: #fff;
      .el-dropdown {
        color: #fff;
      }
    }
  }
}
.bg4 {
  background-image: url("http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/serve/bg4.png");
  background-size: 100%;
  .menu {
    .menuBtn {
      color: #fff;
      .el-dropdown {
        color: #fff;
      }
    }
  }
}
.bg5 {
  background-image: url("http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/team/bg5.png");
  background-size: 100%;
  .menu {
    .menuBtn {
      color: #fff;
      .el-dropdown {
        color: #fff;
      }
    }
  }
}
.bg6 {
  background-image: url("http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/story/bg6.png");
  background-size: 100%;
  .menu {
    .menuBtn {
      color: #fff;
      .el-dropdown {
        color: #fff;
      }
    }
  }
}
.bg7 {
  background-image: url("http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/joinUs/bg7.png");
  background-size: 100%;
  .menu {
    .menuBtn {
      color: #fff;
      .el-dropdown {
        color: #fff;
      }
    }
  }
}
.popClass {
  min-width: 90px !important;
  width: 90px;
  .popMenu1 {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    .cu:hover {
      color: #4a86f7;
    }
  }
}
.el-dropdown-menu {
  .dropdownList {
    text-align: center;
  }
}
.el-backtop {
  display: block;
  width: 48px !important;
  height: 48px !important;
  font-size: 12px !important;
  z-index: 50 !important;
  border-radius: 4px !important;
  .el-icon-caret-top {
    display: none;
  }
  .jiantou {
    // width: 0;
    // height: 0;
    // margin: 0 auto;
    // border-right: 13px solid transparent;
    // border-left: 13px solid transparent;
    // border-bottom: 13px solid #007ac6;
    color: #666;
    text-align: center;
    i {
      font-size: 20px;
    }
  }
  .d-text {
    margin-top: -2px;
    color: #666666;
  }
}
.app_foot {
  width: 100%;
  .navigation {
    width: 100%;
    height: 72px;
    background-color: #7c7b7b;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    .menuBtn {
      cursor: pointer;
      position: relative;
      margin-right: 20px;
    }
    .menuBtn::after {
      position: absolute;
      z-index: 10;
      content: "";
      top: 15%;
      right: -11px;
      bottom: 15%;
      width: 2px;
      background-color: #fff;
    }
    .menuBtn:hover {
      color: #1082c9;
    }
    .menuBtn:last-child {
      margin-right: 0;
    }
    .menuBtn:last-child::after {
      display: none;
    }
  }
  .companyDiv {
    width: 1200px;
    margin: 0 auto;
    padding: 44px 0 50px 0;
    .qrCodeList {
      width: 226px;
      text-align: center;
      padding: 0 20px;
      .code {
        width: 146px;
        height: 146px;
        margin-bottom: 20px;
        background-color: #e5e5e5;
      }
    }
    .bottomMes {
      width: 420px;
      min-height: 200px;
      margin-left: 162px;
      color: #1d1d1b;
      font-weight: 400;
      font-size: 13px;
      .bottomTitle {
        font-size: 19px;
        color: #007cc3;
        font-weight: 700;
      }
      .logo {
        width: 109px;
        height: 40px;
        margin: 20px 0;
      }
      .icon {
        width: 20px;
        height: 20px;
      }
      .flexS {
        margin-bottom: 6px;
      }
    }
  }
  .copyright {
    padding: 24px 0;
    color: #505050;
    font-size: 13px;
    border-top: 1px solid #e5e5e5;
  }
}
.weChat {
  position: fixed;
  right: 64px;
  bottom: 258px;
  width: 48px;
  height: 48px;
  border-radius: 4px;
  background-color: #2E336B;
  background-image: url('./assets/img/weChat.png');
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: center;
  z-index: 100;
  .weChat-i {
    width: 120px;
    height: 133px;
    background: #fff;
    position: absolute;
    right: 65px;
    padding: 10px 12px;
    box-shadow: 0px 3px 6px 1px rgba(181,181,181,0.16);
    border: 1px solid #F1F1F1;
    border-radius: 5px;
    display: none;
    .weChat-img {
      width: 97px;
      height: 97px;
    }
    .weChat-text {
      font-size: 12px;
      color: #00225C;
      font-weight: bold;
      text-align: center;
    }
  }
  &:hover > .weChat-i {
    display: block;
  }
}

.phone {
  position: fixed;
  right: 64px;
  bottom: 204px; 
  width: 48px; 
  height: 48px; 
  border-radius: 4px; 
  background-color: #2E336B;
  background-image: url('./assets/img/dianhua.png');
  background-repeat: no-repeat;
  background-size: 30px; 
  background-position: center;
  z-index: 100;
  .phone-i {
    width: 230px; 
    height: 50px; 
    line-height: 50px; 
    background: #FFFFFF;
    box-shadow: 0px 3px 6px 1px rgba(181,181,181,0.16);
    border: 1px solid #F1F1F1;
    position: absolute;
    right: 65px; 
    border-radius: 5px; 
    font-size: 16px; 
    font-weight: bold;
    color: #00225C;
    text-align: center;
    display: none;
  }
  &:hover > .phone-i {
    display: block;
  }
}
</style>
