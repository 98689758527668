import Vue from 'vue'
import App from './App.vue'
import store from './store/store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import $ from 'jquery';
import rem from './util/rem.js'
import '@/styles/index.scss' // global css
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import Pagination from "@/components/Pagination";
import VideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import Meta from "vue-meta"
import VueScrollReveal from 'vue-scroll-reveal';

router.afterEach((to, from, next) => {
  window,
  scrollTo(0, 0)
})

Vue.use(Meta);
Vue.use(ElementUI)
Vue.use(VueAwesomeSwiper);
Vue.component('Pagination', Pagination)
Vue.use(VideoPlayer)
Vue.use(VueScrollReveal,{
  class: 'v-scroll-reveal',  
  // 动画的时长
  duration: 1000,
  // 延迟时间
  delay: 200,
  // 动画开始的位置，'bottom', 'left', 'top', 'right'
  origin: "bottom",
  // 回滚的时候是否再次触发动画
  reset: true,
  // 在移动端是否使用动画
  mobile: false,
  // 滚动的距离，单位可以用%，rem等
  distance: "50px",
  // 其他可用的动画效果
  opacity: 0.001,
  viewFactor: 0,
  easing: "linear",
  scale: 1,
});
Vue.config.productionTip = false
// 路由跳转
Vue.prototype.$goRoute = function (index) {
  this.$router.push(index)
}

// 成功的提醒
Vue.prototype.msgSuccess = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: "success"
  });
}

// 错误的提醒
Vue.prototype.msgError = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: "error"
  });
}

// 警告的提醒
Vue.prototype.msgWarning = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: "warning"
  });
}
new Vue({
  ElementUI,
  VueAwesomeSwiper,
  VideoPlayer,
  router,
  store,
  render: h => h(App),
}).$mount('#app')