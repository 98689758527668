<template>
  <div>
    <div class="flex flexSB userModTitle2" v-if="showHead">
      <div v-if="!textObj.showBgTitle" class="flex userModCollect">
        <div
          class="flex minorTitle"
          :class="collectPat != 2 ? 'userModCollectAc' : ''"
          @click="changeUserModCollect(1)"
        >
          文档
        </div>
        <div
          class="flex minorTitle"
          :class="collectPat == 2 ? 'userModCollectAc' : ''"
          @click="changeUserModCollect(2)"
        >
          视频
        </div>
        <div
          class="userModCollectLine"
          :class="collectPat == 1 ? 'toLeft' : collectPat == 2 ? 'toRight' : ''"
        ></div>
      </div>
      <span v-else>{{ textObj.bgTitle }}</span>
      <div class="flex patternCon" v-if="collectPat != 2">
        <img
          v-if="queryParams.pattern == 1"
          style="margin-right: 16px"
          src="../assets/img/patternList2.png"
        />
        <img
          v-if="queryParams.pattern == 1"
          class="cu"
          @click="changePattern(2)"
          src="../assets/img/patternGrid1.png"
        />

        <img
          v-if="queryParams.pattern == 2"
          class="cu"
          @click="changePattern(1)"
          style="margin-right: 16px"
          src="../assets/img/patternList1.png"
        />
        <img
          v-if="queryParams.pattern == 2"
          src="../assets/img/patternGrid2.png"
        />
      </div>
    </div>
    <div
      class="userModCon2-1"
      v-if="queryParams.pattern == 1 && collectPat != 2"
      :style="{ height: mh + 'rem' }"
    >
      <div
        class="flex flexSB cu userModList"
        v-for="(item, index) in textObj.data"
        :key="index"
        @click="toDocument(item)"
      >
        <div class="userModTextName">{{ item.fileName }}</div>
        <div class="flex userModTextMes">
          <div>{{ item.pages }}页</div>
          <div class="volume">{{ item.volume }}</div>
          <div>{{ conversion(item.createTime) }}上传</div>
        </div>
      </div>
    </div>
    <div
      class="flex flexS flexT userModCon2-2"
      v-if="queryParams.pattern == 2 && collectPat != 2"
      :style="{ height: mh + 'rem' }"
    >
      <div
        class="cu userModList"
        :class="
          sudoku == 6 ? 'userModList6' : sudoku == 8 ? 'userModList8' : ''
        "
        v-for="(item, index) in textObj.data"
        :key="index"
        @click="toDocument(item)"
      >
        <img v-if="item.img" :src="item.img" />
        <img v-else src="../assets/img/defaultDoc.png" />
        <div class="userModText">
          <div class="userModTime">{{ conversion(item.createTime) }}上传</div>
          <div class="userModTextName">{{ item.fileName }}</div>
        </div>
      </div>
    </div>
    <div
      class="flex flexS flexT userModCon2-3"
      v-if="collectPat == 2"
      :style="{ height: mh + 'rem' }"
    >
      <div
        class="cu userModList"
        :class="
          sudokuVideo == 9
            ? 'userModList9'
            : sudokuVideo == 12
            ? 'userModList12'
            : ''
        "
        v-for="(item, index) in textObj.data"
        :key="index"
        @click="toPlay(item)"
      >
        <img :src="item.videoCover" />
        <div class="userModText">{{ item.videoName }}</div>
      </div>
    </div>
    <div class="flex flexE">
      <pagination
        :total="textObj.total"
        :page.sync="queryParams.pageNum"
        :current-page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        layout="total, prev, pager, next, jumper"
        @pagination="getList"
      />
    </div>
  </div>
</template>

<script>
import { formatDate2 } from "../util/index";
export default {
  name: "userCon2",
  props: [
    "collectPat",
    "queryParams",
    "textObj",
    "sudoku",
    "sudokuVideo",
    "showHead",
    "mh",
  ], // 分页和数据
  data() {
    return {};
  },
  methods: {
    conversion(time) {
      return formatDate2(time);
    },
    /** 改变页码请求最新分段数据 */
    getList() {
      this.$emit("changePageNum", this.queryParams);
    },
    /** 切换九宫格、列表布局方式 */
    changePattern(i) {
      this.$emit("changePattern", i);
    },
    /** 针对我的收藏，切换文档和视频 */
    changeUserModCollect(e) {
      this.$emit("changeUserModCollect", e);
    },
    /** 跳转文档 */
    toDocument(item) {
      let u = "https://view.officeapps.live.com/op/view.aspx?src=" + item.url;
      window.open(u);
    },
    /** 跳转播放页 */
    toPlay(item) {
      console.log(item); // { path: "/play", query: { dataId: item.dataId }}
      window.open("/play?dataId=" + item.id);
      // this.$router.push("/play");
    },
  },
};
</script>
<style lang="scss">
.userModTitle2 {
  width: 100%;
  padding: 20px 0;
  font-size: 18px;
  font-weight: bold;
  color: #666666;
  border-bottom: 2px solid #e5e5e5;
  .userModCollect {
    font-size: 18px;
    font-weight: 400;
    color: #666666;
    position: relative;
    .minorTitle {
      width: 40px;
      margin-right: 30px;
      cursor: pointer;
    }
    .userModCollectAc {
      font-size: 18px;
      font-weight: bold;
      color: #0084db;
    }
    .userModCollectLine {
      position: absolute;
      z-index: 5;
      left: 0;
      bottom: -22px;
      width: 40px;
      height: 4px;
      background-color: #127cc2;
    }
    .toLeft {
      animation-name: rotationLeft;
      animation-fill-mode: forwards;
      animation-duration: 0.5s;
    }
    .toRight {
      animation-name: rotationRight;
      animation-fill-mode: forwards;
      animation-duration: 0.5s;
    }
    @-webkit-keyframes rotationLeft {
      0% {
        transform: translateX(70px);
      }
      100% {
        transform: translateX(0);
      }
    }
    @-webkit-keyframes rotationRight {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(70px);
      }
    }
  }
  .patternCon {
    img {
      cursor: pointer;
    }
  }
}
.userModCon2-1 {
  .userModList {
    height: 62px;
    border-bottom: 1px solid #f5f5f5;
    .userModTextName {
      font-size: 16px;
      font-weight: 400;
      color: #666666;
    }
    .userModTextName:hover {
      color: #4a86f7;
    }
    .userModTextMes {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      .volume {
        border-left: 1px solid #d2d2d2;
        border-right: 1px solid #d2d2d2;
        margin: 0 6px;
        padding: 0 6px;
      }
    }
  }
}
.userModCon2-2 {
  flex-wrap: wrap;
  padding-top: 20px;
  .userModList {
    width: 220px;
    height: 280px;
    margin-bottom: 38px;
    border: 1px solid #e2e2e2;
    border-radius: 6px;
    img {
      width: 218px;
      height: 178px;
    }
    .userModText {
      padding: 12px;
      .userModTime {
        margin-bottom: 10px;
        font-size: 10px;
        font-weight: 400;
        color: #999999;
        zoom: 0.8;
      }
      .userModTextName {
        font-size: 14px;
        color: #666;
        font-weight: 400;
      }
    }
  }
  .userModList:hover {
    box-shadow: 0px 2px 10px rgba(194, 194, 194, 0.66);
  }
  .userModList6 {
    margin-right: 89px;
  }
  .userModList8 {
    margin-right: 74px;
  }
  .userModList6:nth-child(3n) {
    margin-right: 0;
  }
  .userModList8:nth-child(4n) {
    margin-right: 0;
  }
}
.userModCon2-3 {
  flex-wrap: wrap;
  padding: 30px 0 20px 0;
  .userModList {
    width: 248px;
    height: 190px;
    margin-bottom: 15px;
    img {
      width: 248px;
      height: 150px;
      border: 1px solid #999;
      border-radius: 6px;
    }
    .userModText {
      padding: 10px 0;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
    }
  }
  .userModList9 {
    margin-right: 46px;
  }
  .userModList9:nth-child(3n) {
    margin-right: 0;
  }
  .userModList12 {
    margin-right: 36px;
  }
  .userModList12:nth-child(4n) {
    margin-right: 0;
  }
}
</style>